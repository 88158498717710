import createSequence from "utils/create-sequence";

type MarkdownType = "text/plain" | "text/amz-markdown-sim";
type HeaderType = 1 | 2 | 3 | 4 | 5 | 6;
type ImpactLevel = 1 | 2 | 3 | 4 | 5;
interface CustomField {
  id: string;
  type: "string";
  value: string | number;
}

/**
 * SIMTemplateBuilder is an abstract class built using the
 * design builder pattern.
 *
 * It allows you to build complex objects step by step.
 */
abstract class SIMTemplateBuilder {
  protected readonly MAX_URL_LENGTH = 2048;
  protected readonly simBaseUrl = "https://issues.amazon.com";
  protected readonly simIssuesPath = "/issues/create";

  protected title: string = "";
  protected description: string = "";
  protected assignedUser: string = "";
  protected impactLevel: ImpactLevel | string = 5;
  protected markdownType: MarkdownType | string = "text/plain";
  protected customFields: string[] = [];

  constructor(protected readonly issueFolder: string) {
    this.issueFolder = issueFolder;
  }

  public addTitle(title: string) {
    this.title = encodeURIComponent(title);
    return this;
  }

  public addDescription(description: string) {
    this.description = encodeURIComponent(description);
    return this;
  }

  public addAssignedUser(assignedUser: string) {
    this.assignedUser = encodeURIComponent(
      `kerberos:${assignedUser}@ANT.AMAZON.COM`
    );
    return this;
  }

  public addImpactLevel(impactLevel: 1 | 2 | 3 | 4 | 5) {
    this.impactLevel = `${encodeURIComponent(
      `extensions[tt][impact]`
    )}=${impactLevel}`;
    return this;
  }

  public addCustomField({ id, type, value }: CustomField) {
    const customFieldIndex = this.customFields.length;
    const encodedFieldId = encodeURIComponent(
      `customFields[${type}][${customFieldIndex}][id]`
    );
    const encodedFieldValue = encodeURIComponent(
      `customFields[${type}][${customFieldIndex}][value]`
    );

    const customField = `${encodedFieldId}=${id}&${encodedFieldValue}=${value}`;
    this.customFields = [...this.customFields, customField];
    return this;
  }

  /**
   * Max URL Lengths, as defined by different browsers.
   *
   * Security recommendation is to use 2048 as a cutoff.
   *
   * Microsoft Internet Explorer: 2,083 characters
   * Microsoft Edge: 2,083 characters
   * Google Chrome: 32,779 characters
   * Mozilla Firefox: more than 64,000 characters
   * Apple Safari: more than 64,000 characters
   * Google Android: 8,192 characters
   */
  public validateIssueTemplateLength(issueTemplate: string): boolean {
    return (
      this.isString(issueTemplate) &&
      issueTemplate.length <= this.MAX_URL_LENGTH
    );
  }

  /**
   * Opens the given issue template in a new window, with security
   * recommendations set
   */
  public open(issueTemplate: string) {
    window.open(issueTemplate, "_blank", "noopener,noreferrer");
  }

  protected getCustomFields(): string {
    return this.customFields.join(encodeURIComponent("&"));
  }

  protected createSimIssueUrl(): string {
    return `${this.simBaseUrl}${this.simIssuesPath}`;
  }

  protected setMarkdownType(markdownType: MarkdownType) {
    this.markdownType = encodeURIComponent(markdownType);
  }

  protected createTextBlock({
    title,
    text,
    headerType = 3,
  }: {
    title: string;
    text: string;
    headerType?: HeaderType;
  }) {
    const blockTitle = this.createHeader(headerType, title);
    return `${blockTitle}\n${text}`;
  }

  protected createHeader(type: HeaderType, content: string): string {
    const header = createSequence(type)
      .map((_) => "#")
      .join("");
    return `${header} ${content}`;
  }

  protected italicizeText(text: string) {
    return `_${text}_`;
  }

  private isString(val: unknown): val is string {
    return typeof val === "string";
  }

  /**
   * All classes that inherit the abstract class must have
   * a mechanism to create an issue template
   */
  protected abstract createIssueTemplate(): string;
}

export default SIMTemplateBuilder;
