import {
  AxiosError,
  AxiosInterceptorManager,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";

export type ApiError = AxiosError;

export type RequestInterceptor = AxiosInterceptorManager<AxiosRequestConfig>;
export type ResponseInterceptor = AxiosInterceptorManager<AxiosResponse>;
export interface Client {
  interceptors: {
    request: AxiosInterceptorManager<AxiosRequestConfig>;
    response: AxiosInterceptorManager<AxiosResponse>;
  };
  defaults: AxiosRequestConfig;
  get<T = any, R = AxiosResponse<T>>(
    url: string,
    options?: AxiosRequestConfig
  ): Promise<R>;
  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R>;
  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R>;
  delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R>;
}

class BaseApiClient implements Client {
  interceptors: {
    request: AxiosInterceptorManager<AxiosRequestConfig>;
    response: AxiosInterceptorManager<AxiosResponse>;
  };
  defaults: AxiosRequestConfig;
  constructor(private clientName: string, private client: Client) {
    this.clientName = clientName;
    this.client = client;
    this.interceptors = client.interceptors;
    this.defaults = client.defaults;
  }
  getClientName = () => {
    return this.clientName;
  };
  setBaseUrl = (baseUrl: string) => {
    this.client.defaults.baseURL = baseUrl;
  };
  get = <T = any, R = AxiosResponse<T>>(
    url: string,
    options?: AxiosRequestConfig
  ): Promise<R> => {
    return this.client.get(url, options || {});
  };
  post = <T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    options?: AxiosRequestConfig
  ): Promise<R> => {
    return this.client.post(url, data, options);
  };
  put = <T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    options?: AxiosRequestConfig
  ): Promise<R> => {
    return this.client.put(url, data, options);
  };
  delete = <T = any, R = AxiosResponse<T>>(
    url: string,
    options?: AxiosRequestConfig
  ): Promise<R> => {
    return this.client.delete(url, options);
  };
}

export default BaseApiClient;
