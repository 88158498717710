class PerformanceTimer {
  private timerMap: Map<string, number>;
  constructor() {
    this.timerMap = new Map<string, number>();
  }
  public startTimer = (timerName: string, group: string = "") => {
    this.timerMap.set(this.buildTimer(timerName, group), performance.now());
  };
  public endTimer = (
    timerName: string,
    group: string = ""
  ): number | undefined => {
    const startTime: number | undefined = this.timerMap.get(
      this.buildTimer(timerName, group)
    );

    if (typeof startTime !== "number") {
      return;
    }

    const endTime: number = performance.now();
    const totalTime: number = endTime - startTime;

    this.timerMap.delete(this.buildTimer(timerName, group));

    return totalTime;
  };
  private buildTimer = (timerName: string, group: string) => {
    return `${timerName}_${group}`;
  };
}

export default PerformanceTimer;
