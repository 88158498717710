import { IconProps } from "@amzn/awsui-components-react/polaris/icon";

import { StepIconProps } from "./StepIcon.interface";

export const STATUS_ICON_MAP: Record<
  NonNullable<StepIconProps["status"]>,
  IconProps
> = {
  error: {
    variant: "error",
    name: "status-negative",
  },
  warning: {
    variant: "warning",
    name: "status-warning",
  },
  success: {
    variant: "success",
    name: "status-positive",
  },
  info: {
    variant: "link",
    name: "status-info",
  },
  stopped: {
    variant: "subtle",
    name: "status-stopped",
  },
  pending: {
    variant: "subtle",
    name: "status-pending",
  },
  "in-progress": {
    variant: "subtle",
    name: "status-in-progress",
  },
  loading: {
    variant: "subtle",
    name: "status-stopped",
  },
};
