import { useCallback, useEffect, useRef } from "react";

export interface UseScrollPaddingOptions {
  selectors?: string[];
}

export const DEBOUNCE_TIME = 100;

const useScrollPadding = ({
  selectors = ["#app-header", "#app-notifications"],
}: UseScrollPaddingOptions = {}) => {
  const debounceTimeout = useRef<number>();
  const setScrollPadding = () => {
    const totalHeight = Array.from(
      document.querySelectorAll(selectors.join(","))
    )
      .map((el) => (el instanceof HTMLElement ? el.offsetHeight : 0))
      .reduce((acc, h) => acc + h, 0);
    // @ts-ignore: scrollPaddingTop is added in typescript 4.4 libdom
    document.documentElement.style.scrollPaddingTop = `${totalHeight}px`;
  };
  const updateScrollPadding = useCallback(() => {
    if (debounceTimeout.current) {
      return;
    }

    debounceTimeout.current = window.setTimeout(() => {
      setScrollPadding();
      window.clearTimeout(debounceTimeout.current);
      debounceTimeout.current = undefined;
    }, DEBOUNCE_TIME);
  }, []);

  useEffect(() => {
    updateScrollPadding();
    window.addEventListener("resize", updateScrollPadding);

    return () => {
      window.removeEventListener("resize", updateScrollPadding);
      document.documentElement.style.removeProperty("scroll-padding-top");
    };
  }, []);

  return {
    updateScrollPadding,
  };
};

export default useScrollPadding;
