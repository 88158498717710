import * as Yup from "yup";

export type BaseAttributeType = {
  type?: string;
  name?: string;
  required?: boolean;
  description?: string;
  choiceOptions?: string[];
  yupValidator?: Yup.AnySchema;
  dynamicFormName?: string;
};

const stringTypeValues: string[] = ["string", "single_choice"];
const numberTypeValues: string[] = ["number"];
const multiChoiceValues: string[] = ["multi_choice"];

export function mergeSchemas(
  ...schemas: Yup.ObjectSchema<any>[]
): Yup.ObjectSchema<any> {
  const [first, ...rest] = schemas;

  const merged = rest.reduce(
    (mergedSchemas, schema) => mergedSchemas.concat(schema),
    first
  );

  return merged;
}

const baseStringValidator = Yup.string().trim();

export function createYupSchema<S extends BaseAttributeType>(
  schema: Yup.ObjectSchema<any>,
  config: S
) {
  const { required, name, type, yupValidator } = config;

  const formName = name as string;

  if (yupValidator) {
    schema.fields[formName] = yupValidator;
    return schema.concat(schema);
  }

  if (stringTypeValues.some((t) => t === type)) {
    const validator = required
      ? baseStringValidator.required("Required")
      : baseStringValidator;

    schema.fields[formName] = validator;
  } else if (multiChoiceValues.some((t) => t === type)) {
    // An empty array is truthy, so if we want to ensure there is at least an object
    // that matches the object() schema, set min to 1
    const baseArray = required ? Yup.array().min(1, "Required") : Yup.array();

    let validator = baseArray.of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      })
    );
    validator = required ? validator.required("Required") : validator;

    schema.fields[formName] = validator;
  } else if (numberTypeValues.some((t) => t === type)) {
    let validator = Yup.number();
    validator = required ? validator.required("Required") : validator;

    schema.fields[formName] = validator;
  }

  return schema.concat(schema);
}
