import { PropertyFilterProps } from "@amzn/awsui-components-react/polaris";

import { ExtendedFilteringProperty, MatchOptions } from "./types";

class ClientFilter<Item extends object> {
  /**
   * List of primitive values to perform matches on. Values will
   * be converted to into string when comparing against the query
   */
  private readonly comparablePrimitiveTypes = ["boolean", "number", "string"];
  /**
   * Default filter comparison operator
   */
  private readonly defaultOperator: PropertyFilterProps.ComparisonOperator =
    ":";
  /**
   * A list of all properties that should be filtered on. If no
   * properties are specified, all properties will be checked
   */
  private readonly filteringPropertyKeys: string[];
  /**
   * @param {ExtendedFilteringProperty[]} filteringProperties
   * @param {boolean} caseSensitive
   */
  constructor(
    filteringProperties: ExtendedFilteringProperty[],
    /**
     * If true, a case sensitive string match will be performed
     */
    private readonly caseSensitive = false
  ) {
    this.filteringPropertyKeys = (filteringProperties || []).map(
      (property) => property.key
    );
    this.caseSensitive = caseSensitive;
  }

  /**
   * Returns an array of records that match the specified query
   * @param {Item[]} items
   * @param {PropertyFilterProps.Query} query
   * @returns {Item[]}
   */
  filter(items: Item[], query: PropertyFilterProps.Query): Item[] {
    if (!query.tokens.length) {
      return items;
    }

    return items.filter((item: Record<string, any>) => {
      let match = false;
      for (let i = 0, len = query.tokens.length; i < len; i++) {
        const token = query.tokens[i];
        const queryString = token.value.trim();

        // Specified query without picking a filter property
        if (!token.propertyKey) {
          match = this.matchObject(queryString, item);
        } else {
          match = this.matchUnknown(queryString, item[token.propertyKey], {
            caseSensitive: this.caseSensitive,
            operator: token.operator,
          });
        }

        // Invert match
        if (token.operator.startsWith("!")) {
          match = !match;
        }

        // There are 2 cases where we can short circuit and save
        // iterations for property matching:
        // - "or" operation with the first match
        // - "and" operation with the first negative match
        if (
          (query.operation === "and" && !match) ||
          (query.operation === "or" && match)
        ) {
          break;
        }
      }

      return match;
    });
  }

  /**
   * Checks if a value is a number
   * @param {*} n
   * @returns {boolean}
   */
  private isNumeric(n: any) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  /**
   * Checks if a key/value pair within an object matches the specified query
   * @param {string} query
   * @param {Object.<string, *>} value
   * @param {MatchOptions=} options
   * @returns {boolean}
   */
  private matchObject(
    query: string,
    value: Record<string, unknown>,
    options?: MatchOptions
  ) {
    if (Array.isArray(value) || typeof value !== "object") {
      return false;
    }
    const keys = this.filteringPropertyKeys || Object.keys(value);
    for (let i = 0, len = keys.length; i < len; i++) {
      if (this.matchUnknown(query, value[keys[i]], options)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Checks if a value within an array matches the specified query
   * @param {string} query
   * @param {Array.<*>} value
   * @param {MatchOptions=} options
   * @returns {boolean}
   */
  private matchArray(
    query: string,
    value: unknown[],
    options?: MatchOptions
  ): boolean {
    if (!value.length) {
      return false;
    }
    return value.findIndex((v) => this.matchUnknown(query, v, options)) > -1;
  }

  /**
   * Checks if a value of unknown type matches the specified query
   * @param {string} query
   * @param {*} value
   * @param {MatchOptions=} options
   * @returns {boolean}
   */
  private matchUnknown(query: string, value: unknown, options?: MatchOptions) {
    if (Array.isArray(value)) {
      return this.matchArray(query, value, options);
    } else if (typeof value === "object") {
      return this.matchObject(query, value as Record<string, unknown>, options);
    } else {
      return this.matchPrimitive(query, value, options);
    }
  }

  /**
   * Performs a string match on a primitive value
   * @param {string} query
   * @param {*} value
   * @param {MatchOptions=} options
   * @returns {boolean}
   */
  private matchPrimitive(
    query: string,
    value: unknown,
    options?: MatchOptions
  ) {
    if (!this.comparablePrimitiveTypes.includes(typeof value)) {
      return false;
    }

    value = String(value);

    if (!options?.caseSensitive) {
      query = query.toLowerCase();
      value = (value as string).toLowerCase();
    }

    const operator: PropertyFilterProps.ComparisonOperator =
      options?.operator || this.defaultOperator;

    // Try and convert the value into a number to get expected results for
    // gt/lt comparisons. "4" > "200", which doesn't make much sense outside
    // of the programming world.
    if (
      (operator.startsWith(">") || operator.startsWith("<")) &&
      this.isNumeric(value)
    ) {
      value = parseFloat(value as string);
    }

    if (operator.endsWith(":")) {
      // contains
      return (value as string).includes(query);
    } else if (operator.startsWith(">")) {
      // greater than
      return operator.endsWith("=")
        ? (value as string | number) >= query
        : (value as string | number) > query;
    } else if (operator.startsWith("<")) {
      // less than
      return operator.endsWith("=")
        ? (value as string | number) <= query
        : (value as string | number) < query;
    } else {
      // equals or anything else
      return value === query;
    }
  }
}

export default ClientFilter;
