import { Permissions, PermissionTableItems } from "../types";

const processData = (
  permissions: Permissions,
  permissionMapping: { [key in string]: string }
) => {
  const res: PermissionTableItems = [];
  permissions.forEach((item) => {
    const email = item.principal;
    const roles = [...item.roles];
    const rolesDisplay = roles
      .map((role) => permissionMapping[role])
      .sort((a, b) => a.localeCompare(b))
      .join(", ");

    res.push({ email, roles, rolesDisplay });
  });
  return res;
};

export default processData;
