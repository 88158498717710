import { FirehoseClient } from "clients";
import React, { createContext, PropsWithChildren, ReactNode } from "react";

interface UnauthenticatedClientProviderContextType {
  firehoseClient: FirehoseClient | undefined;
}

export const UnauthenticatedClientProviderContext = createContext<UnauthenticatedClientProviderContextType>(
  {
    firehoseClient: undefined,
  }
);

interface UnauthenticatedClientProviderProps
  extends UnauthenticatedClientProviderContextType {
  children: PropsWithChildren<ReactNode>;
}

const UnauthenticatedClientProvider = ({
  firehoseClient,
  children,
}: UnauthenticatedClientProviderProps) => (
  <UnauthenticatedClientProviderContext.Provider
    value={{
      firehoseClient,
    }}
  >
    {children}
  </UnauthenticatedClientProviderContext.Provider>
);

export default UnauthenticatedClientProvider;
