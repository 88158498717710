import { InputLogEvent } from "@aws-sdk/client-cloudwatch-logs";
import Client from "clients/CloudWatchLogsClient";
import { ILogEntry, ILogSink, LogLevel } from "lib/Logger/Logger.interface";
import isErrorInExclusionList, {
  handleErrorObject,
} from "utils/exclusion-error-filter/exclusion-error-filter";

import SinkType from "../SinkType";

export default class CloudWatchSink implements ILogSink {
  constructor(private readonly cloudWatchLogsClient: Client) {}
  public getSinkName(): string {
    return SinkType.CloudWatchLogSink;
  }
  public writeEntry(logEntry: ILogEntry) {
    this.sendEntry(logEntry);
  }
  private serializeObject(inputObject: any) {
    return JSON.stringify(inputObject, handleErrorObject);
  }
  private sendEntry = async (logEntry: ILogEntry): Promise<void> => {
    const inputLogEvent = this.createInputLogEvent(logEntry);

    inputLogEvent.length &&
      this.cloudWatchLogsClient.enqueuePutLogEvent(inputLogEvent);
  };
  private createInputLogEvent = (logEntry: ILogEntry): InputLogEvent[] => {
    const {
      msg,
      logLevel,
      loggerName,
      ts,
      loggerMetadata,
      providerMetadata,
    } = logEntry;
    const accumulatedMessages = msg.reduce<{
      messages: string[];
      containsExclusionList: boolean;
    }>(
      (acc, message) => {
        const serializedMessage = this.serializeObject(message);

        const isExcluded =
          acc.containsExclusionList ||
          isErrorInExclusionList(serializedMessage);

        return {
          messages: acc.messages.concat(serializedMessage),
          containsExclusionList: isExcluded,
        };
      },
      {
        messages: [],
        containsExclusionList: false,
      }
    );

    /**
     * If any of the messages of the grouped LogEntry contain a message
     * contained in the exclusion list, do not send it to CloudWatch.
     */
    if (accumulatedMessages.containsExclusionList) {
      return [];
    }

    const logMessage = {
      logLevel,
      readableLogLevel: LogLevel[logLevel],
      loggerName,
      serializedMessages: accumulatedMessages.messages,
      ...(loggerMetadata && { loggerMetadata }),
      ...(providerMetadata && { providerMetadata }),
    };

    return [
      {
        timestamp: ts,
        message: JSON.stringify(logMessage),
      },
    ];
  };
}
