import { Browser } from "utils";

export interface UseBrowserOutput {
  getOSType: ReturnType<typeof Browser["getBrowserType"]>;
}

const useBrowser = () => {
  return {
    getOSType: Browser.getOSType.bind(Browser),
  };
};

export default useBrowser;
