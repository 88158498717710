import Box, { BoxProps } from "@amzn/awsui-components-react/polaris/box";
import Spinner, {
  SpinnerProps,
} from "@amzn/awsui-components-react/polaris/spinner";
import React from "react";

import css from "./LoadingComponent.module.scss";

export interface LoadingComponentProps {
  containerProps?: BoxProps;
  spinnerWithLabelProps?: SpinnerWithLabelProps;
}

interface SpinnerWithLabelProps extends SpinnerProps {
  boxVariant?: BoxProps.Variant;
  children?: React.ReactNode;
  textVariant?: BoxProps.Variant;
}

export const SpinnerWithLabel = (props: SpinnerWithLabelProps) => (
  <div className={css.styledContainer}>
    <Box variant={props.boxVariant}>
      <Spinner variant={props.variant} size={props.size} />
    </Box>
    {props.children && (
      <Box margin={{ left: "s" }} variant={props.textVariant}>
        {props.children}
      </Box>
    )}
  </div>
);

const LoadingComponent = ({
  containerProps = { margin: "l" },
  spinnerWithLabelProps = {
    boxVariant: "h1",
    size: "big",
    children: "Loading...",
    textVariant: "h5",
  },
}: LoadingComponentProps) => (
  <Box {...containerProps}>
    <SpinnerWithLabel {...spinnerWithLabelProps} />
  </Box>
);

export default LoadingComponent;
