import { useCallback } from "react";

export interface UseCopyToClipboardProps {
  elementId: string;
}

const useCopyToClipboard = ({ elementId }: UseCopyToClipboardProps) => {
  const copyToClipboard = useCallback(() => {
    const selection = window.getSelection();
    const range = document.createRange();
    const el = document.getElementById(elementId);

    el && range.selectNodeContents(el);

    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand("copy");
      selection.removeAllRanges();
    }
  }, [elementId]);

  const copyContentToClipboard = useCallback(async (content: string) => {
    if (navigator.clipboard) {
      // Only available in a secure context (https) or localhost
      return await navigator.clipboard.writeText(content);
    }

    // Legacy execCommand method (deprecated)
    const container = document.createElement("textarea");
    container.value = content;
    container.style.position = "absolute";
    container.style.left = "-9999px";
    document.body.appendChild(container);
    container.select();
    document.execCommand("copy");
    document.body.removeChild(container);
  }, []);

  return {
    copyToClipboard,
    copyContentToClipboard,
  };
};

export default useCopyToClipboard;
