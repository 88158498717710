import { getAppVersion } from "utils";

import { ILogProvider } from "./../Logger.interface";

const AppVersionProvider: ILogProvider<string | null> = {
  cache: undefined,
  getProviderName() {
    return "AppVersionProvider";
  },
  getData() {
    /**
     * Return from local cache if it has been requested before. This data
     * does not change within the same session.
     */
    if (AppVersionProvider.cache) {
      return AppVersionProvider.cache;
    }
    return (AppVersionProvider.cache = getAppVersion());
  },
};

export default AppVersionProvider;
