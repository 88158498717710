const BUILD_VERSION_ATTRIBUTE_NAME = "build-version";

const getAppVersion = (): string | null => {
  const buildVersionElement = document.querySelector(
    `meta[${BUILD_VERSION_ATTRIBUTE_NAME}]`
  );

  if (buildVersionElement) {
    const buildVersion = buildVersionElement.getAttribute(
      BUILD_VERSION_ATTRIBUTE_NAME
    );
    if (buildVersion) {
      return buildVersion;
    }
  }
  return null;
};

export default getAppVersion;
