import isEmpty from "lodash.isempty";

import { FormikFieldProps } from "./FormControls.interface";

interface Props<S> extends FormikFieldProps<S> {
  name: string;
  didSubmit?: boolean;
}

/**
 * Utility function to determine whether or not a component
 * should re-render based off of Formik hook values.
 */
const memoizedFormikPropCheck = <S>(
  prevProps: Props<S>,
  nextProps: Props<S>
) => {
  if (prevProps?.didSubmit !== nextProps?.didSubmit) {
    return false;
  }

  if (prevProps.meta.error !== nextProps.meta.error) {
    return false;
  }

  if (prevProps.meta.touched !== nextProps.meta.touched) {
    return false;
  }

  if (prevProps.field.value !== nextProps.field.value) {
    return false;
  }

  return true;
};

/**
 * The field is invalid if it has been touched or submitted,
 * and there is a validation error.
 */
export const isFieldInvalid = <S>(
  { meta }: Pick<FormikFieldProps<S>, "meta">,
  didSubmit?: boolean
) => {
  return (meta.touched || didSubmit) && !isEmpty(meta.error);
};

export default memoizedFormikPropCheck;
