import { getSourceContent } from "components/MarkdownRenderer/utility";

import {
  DirectiveNode,
  DirectiveNodeProcessor,
  ProcessOptions,
} from "../types";

class CodeBlockDirective extends DirectiveNodeProcessor {
  readonly hName = "pre";

  process(node: DirectiveNode, options: ProcessOptions): void {
    super.process(node, options);

    this.appendNodeProperties(node, {
      value: getSourceContent(node, options.sourceContent),
    });
  }
}

export default CodeBlockDirective;
