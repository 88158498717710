import { i18nStrings } from "utils/constants";

import errorPagesGraphic from "../../assets/images/error_pages_illustration.png";
import { ErrorPageContent, ErrorTypes } from "./types";

const content: Record<ErrorTypes, ErrorPageContent> = {
  "404": {
    imgSrc: errorPagesGraphic,
    title: i18nStrings.errors.notFound.title,
    message: i18nStrings.errors.notFound.message,
  },
};

export default content;
