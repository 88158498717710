import { Dimension } from "@aws-sdk/client-cloudwatch";
import { MetricsClient } from "clients";
import { ClientProviderContext } from "context";
import { useContext } from "react";

interface UseMetricOutput {
  addCounterMetric: MetricsClient["addCounterMetric"];
}

/**
 * useMetric is a re-usable hook that utilizes the Metrics
 * client at its core to send CloudWatch metrics.
 */
const useMetric = (): UseMetricOutput => {
  const context = useContext(ClientProviderContext);

  const addCounterMetric = (
    metricName: string,
    dimensions?: Dimension[],
    metricCount?: number
  ) => {
    context.metricsClient?.addCounterMetric(
      metricName,
      dimensions,
      metricCount || 1
    );
  };

  return {
    addCounterMetric,
  };
};

export default useMetric;
