import { NonNullableFields } from "types";

import {
  AllEventStateValue,
  EditableEventStates,
  EventAccountSource,
  EventField,
  EventStateInfrastructure,
  EventStateRuntime,
  EventType,
} from "./types";

const EVENT_NOT_PROVISIONED_STATES: EditableEventStates = {
  runtime: ["scheduled", "not_started"],
  infrastructure: ["not_applicable", "not_provisioned"],
};

const EVENT_ACTIVE_STATES: EditableEventStates = {
  runtime: [
    "scheduled",
    "not_started",
    "start_in_progress",
    "start_failed",
    "start_success",
    "pause_in_progress",
    "pause_failed",
    "pause_success",
  ],
  infrastructure: null,
};

export const EVENT_STATE_ENABLED_FIELD_MAP: Record<
  EventField,
  EditableEventStates | AllEventStateValue
> = {
  eventStartDateTime: EVENT_NOT_PROVISIONED_STATES,
  eventTimeZone: EVENT_NOT_PROVISIONED_STATES,
  duration: EVENT_ACTIVE_STATES,
  attendeeForecast: EVENT_NOT_PROVISIONED_STATES,
  teamSize: EVENT_NOT_PROVISIONED_STATES,
  reservedQuota: EVENT_ACTIVE_STATES,
  regions: EVENT_NOT_PROVISIONED_STATES,
  permissions: null,
  title: null,
  description: null,
  eventModality: null,
  attributeGeo: null,
  attributeEngagementType: null,
  attributeOpportunityId: null,
};

export const ACCOUNT_SOURCE_ENABLED_FIELD_MAP: Record<
  EventAccountSource,
  Record<EventType, EventField[] | AllEventStateValue>
> = Object.freeze({
  workshop_studio: {
    test: [
      "eventStartDateTime",
      "eventTimeZone",
      "duration",
      "attendeeForecast",
      "teamSize",
      "reservedQuota",
      "regions",
      "permissions",
      "title",
      "description",
    ],
    production: null,
  },
  customer_provided: {
    test: [
      "eventStartDateTime",
      "eventTimeZone",
      "duration",
      "attendeeForecast",
      "teamSize",
      "permissions",
      "title",
      "description",
    ],
    production: [
      "eventStartDateTime",
      "eventTimeZone",
      "duration",
      "attendeeForecast",
      "teamSize",
      "permissions",
      "title",
      "description",
      "eventModality",
      "attributeGeo",
      "attributeEngagementType",
      "attributeOpportunityId",
    ],
  },
});

export const DISCRETE_UPDATE_APIS: EventField[] = [
  "eventStartDateTime",
  "eventTimeZone",
  "duration",
  "attendeeForecast",
  "teamSize",
  "reservedQuota",
  "regions",
  "permissions",
];

const EVENT_STATE_RUNTIME_ORDER: EventStateRuntime[] = [
  "scheduled",
  "canceled",
  "not_started",
  "start_in_progress",
  "start_failed",
  "start_success",
  "pause_in_progress",
  "pause_failed",
  "pause_success",
  "terminate_in_progress",
  "terminate_failed",
  "terminate_success",
];

const EVENT_STATE_INFRASTRUCTURE_ORDER: EventStateInfrastructure[] = [
  "not_applicable",
  "not_provisioned",
  "provision_in_progress",
  "provision_failed",
  "deployment_central_in_progress",
  "deployment_central_failed",
  "deployment_in_progress",
  "deployment_halted",
  "deployment_failed",
  "deployment_success",
  "terminate_in_progress",
  "terminate_failed",
  "terminate_success",
];

export const EVENT_STATE_ORDER: NonNullableFields<EditableEventStates> = {
  runtime: EVENT_STATE_RUNTIME_ORDER,
  infrastructure: EVENT_STATE_INFRASTRUCTURE_ORDER,
};
