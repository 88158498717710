import { Options } from "react-markdown";

import { MarkdownRendererProps } from "../../types";
import evaluateAttributeValues from "../../utility/evaluate-attribute-values";

declare global {
  namespace JSX {
    // this merges with the existing intrinsic elements, adding 'my-custom-tag' and its props
    interface IntrinsicElements {
      expand: any;
      tab: any;
      tabs: any;
      alert: any;
      children: any;
      parameter: any;
      asseturl: any;
      error: any;
      pic: any;
    }
  }
}

export enum DirectiveType {
  TEXT = "textDirective",
  LEAF = "leafDirective",
  CONTAINER = "containerDirective",
}

export enum DirectiveName {
  CODE = "code",
  LINK = "link",
  BUTTON = "button",
  PARAM = "param",
  ASSET_URL = "assetUrl",
  ALERT = "alert",
  EXPAND = "expand",
  CHILDREN = "children",
  VIDEO = "video",
  TABS = "tabs",
  TAB = "tab",
  IMAGE = "image",
}

export type MarkdownConfig = MarkdownRendererProps["config"];
type Components = NonNullable<Options["components"]>;

export interface ProcessOptions {
  sourceContent: string;
  config?: MarkdownConfig;
}

interface DirectiveNodePositionData {
  line: number;
  column: number;
  offset: number;
}

interface DirectiveNodePosition {
  start: DirectiveNodePositionData;
  end: DirectiveNodePositionData;
}

interface DirectiveNodeBase {
  attributes: any;
  children?: DirectiveNode[];
  data: any;
  name: DirectiveName;
  position?: DirectiveNodePosition;
  type: DirectiveType;
}

interface DirectiveNodeValue extends DirectiveNodeBase {
  value?: string;
}

interface DirectiveNodeImage extends DirectiveNodeBase {
  alt?: string;
}

export type DirectiveNode = DirectiveNodeValue & DirectiveNodeImage;

export interface DirectiveProperties {
  type: DirectiveType;
  name: DirectiveName;
}
export type DirectiveComponent<T> = {
  directive?: DirectiveProperties;
} & T;

export abstract class DirectiveNodeProcessor {
  /**
   * Name of HTML node to map to
   */
  abstract readonly hName: keyof Components;
  /**
   * Processor method to transform directive node data.
   * Call super.process() in overriding method to ensure
   * base node transformations are performed.
   *
   * @param {DirectiveNode} node
   * @param {ProcessOptions} options
   */
  process(node: DirectiveNode, options: ProcessOptions): void;
  process(node: DirectiveNode) {
    const data = node.data || (node.data = {});

    data.hName = this.hName;
    this.appendNodeProperties(node, {
      ...evaluateAttributeValues(node.attributes),
      directive: {
        type: node.type,
        name: node.name,
      },
    });
  }

  appendNodeProperties(node: DirectiveNode, value: Object) {
    const data = node.data || (node.data = {});

    data.hProperties = {
      ...data.hProperties,
      ...value,
    };
  }
}
