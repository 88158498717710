import parse from "url-parse";

const useUrlParse = (url: string) => {
  const parsedUrl = parse(url, true);

  return {
    protocol: parsedUrl.protocol,
    host: parsedUrl.host,
    hostname: parsedUrl.hostname,
    port: parsedUrl.port,
    pathname: parsedUrl.pathname,
    query: parsedUrl.query,
    hash: parsedUrl.hash,
    href: parsedUrl.href,
    origin: parsedUrl.origin,
  };
};

export default useUrlParse;
