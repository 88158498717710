export type ErrorTypes = "404";

export interface ErrorPageContent {
  imgSrc: string;
  title: string;
  message: string;
}

export interface ErrorPageActon {
  text: string;
  href: string;
  external?: boolean;
}

export interface ErrorPageProps extends Partial<ErrorPageContent> {
  type?: ErrorTypes;
  actions?: ErrorPageActon[];
}

export enum TestId {
  Image = "error-page-image",
  Title = "error-page-title",
  Message = "error-page-message",
  Actions = "error-page-actions",
}
