import {
  CloudWatchClient as Client,
  MetricDatum,
  PutMetricDataCommand,
  PutMetricDataInput,
} from "@aws-sdk/client-cloudwatch";
import { MetadataBearer } from "@aws-sdk/types";
import { Credentials } from "aws-sdk";

/**
 * CloudWatchClient is a wrapper around @aws-sdk/client-cloudwatch
 */
class CloudWatchClient {
  private static instance: CloudWatchClient | undefined;

  public static getInstance(
    credentials: Credentials,
    region: string,
    namespace: string
  ): CloudWatchClient {
    if (!CloudWatchClient.instance) {
      CloudWatchClient.instance = new CloudWatchClient(
        credentials,
        region,
        namespace
      );
    }

    return CloudWatchClient.instance;
  }

  private cloudWatchClient: Client;
  constructor(
    credentials: Credentials,
    region: string,
    private readonly namespace: string
  ) {
    this.cloudWatchClient = new Client({
      maxAttempts: 0,
      region,
      credentials,
    });

    return this;
  }
  executePutMetricDataCommand = async (
    cloudWatchMetrics: MetricDatum[]
  ): Promise<MetadataBearer | void> => {
    if (cloudWatchMetrics.length === 0) {
      return Promise.resolve();
    }

    const input: PutMetricDataInput = {
      MetricData: cloudWatchMetrics,
      Namespace: this.namespace,
    };

    const putEventCommand: PutMetricDataCommand = new PutMetricDataCommand(
      input
    );

    const response: MetadataBearer = await this.cloudWatchClient.send(
      putEventCommand
    );
    return response;
  };

  public static __removeInstance__() {
    if (process.env.NODE_ENV !== "test") {
      return;
    }
    CloudWatchClient.instance = undefined;
  }
}

export default CloudWatchClient;
