export interface EventListener {
  event: string;
  cb: Function;
}

class Emitter {
  private static instance: Emitter | undefined;

  public static getInstance(): Emitter {
    if (!Emitter.instance) {
      Emitter.instance = new Emitter();
    }

    return Emitter.instance;
  }

  private _listeners: EventListener[] = [];

  constructor(listeners?: EventListener[]) {
    this._listeners = Array.isArray(listeners) ? listeners : [];
  }

  on(event: string, cb: Function) {
    this._listeners.push({
      event,
      cb,
    });

    return this;
  }

  emit(event: string, ...rest: any[]) {
    this._listeners.forEach((onListener: EventListener) => {
      if (onListener.event === event) {
        onListener.cb(...rest);
      }
    });
  }

  public static __removeInstance__() {
    if (process.env.NODE_ENV !== "test") {
      return;
    }
    Emitter.instance = undefined;
  }
}

export default Emitter;
