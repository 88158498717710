import { BoxProps } from "@amzn/awsui-components-react/polaris/box";

import { TimelineStepProps } from "./TimelineStep.interface";

export const STATUS_COLOR_MAP: Record<
  NonNullable<TimelineStepProps["status"]>,
  BoxProps.Color
> = {
  error: "text-status-error",
  warning: "text-status-error",
  success: "text-status-success",
  info: "text-status-info",
  stopped: "text-status-inactive",
  pending: "text-status-inactive",
  "in-progress": "text-status-inactive",
  loading: "text-status-inactive",
};

export enum TEST_IDS {
  STEP = "timeline__step",
  STEP_TITLE = "timeline__step-title",
}

export const ACTIVE_CLASS = "_active";
