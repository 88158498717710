import { useCollection } from "@amzn/awsui-collection-hooks";
import Button from "@amzn/awsui-components-react/polaris/button";
import CollectionPreferences, {
  CollectionPreferencesProps,
} from "@amzn/awsui-components-react/polaris/collection-preferences";
import Header from "@amzn/awsui-components-react/polaris/header";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import PropertyFilter from "@amzn/awsui-components-react/polaris/property-filter";
import Select, {
  SelectProps,
} from "@amzn/awsui-components-react/polaris/select";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Table, { TableProps } from "@amzn/awsui-components-react/polaris/table";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import EmptyMessage from "components/Table/EmptyMessage";
import StoragePartition, { StoragePartitionKeys } from "lib/StoragePartition";
import React, { useEffect, useMemo, useState } from "react";
import countText from "utils/count-text";
import getFilteringOptions from "utils/polaris/property-filter/get-filtering-options";

import { DEFAULT_PROPERTY_FILTER_VALUE, PAGE_SIZES } from "./constants";
import {
  CollectionSelectFilter,
  TestID,
  UseTableOptions,
  UseTableResult,
} from "./types";
import styles from "./use-table.modules.scss";

/**
 * Polaris table hook
 *
 * @param {ItemType[] | null | undefined} items list of items to display
 * @param {UseTableOptions<ItemType>} options
 * @returns { component: React.ReactNode }
 */
const useTable = <ItemType extends {}>(
  items: ItemType[] | null | undefined,
  {
    columnDefinitions,
    id,
    i18nStrings,
    visibleContent,
    actions,
    collectionSelectFilters: collectionSelectFilterOptions,
    contentDensity,
    disablePagination,
    disableFiltering,
    emptyMessageAction,
    error,
    filteringProperties: filteringPropertyOptions,
    info,
    isItemDisabled,
    loading,
    onRefresh,
    onSelectionChange,
    pageSize = PAGE_SIZES[Math.floor(PAGE_SIZES.length / 2)],
    resizableColumns,
    selectedItems,
    selectionType,
    stickyColumns,
    stickyHeader,
    storageDedupeKey,
    trackBy,
    variant,
    wrapLines,
  }: UseTableOptions<ItemType>
): UseTableResult => {
  const collectionSelectFilters = !disableFiltering
    ? (collectionSelectFilterOptions?.filter(
        (f) => !!f
      ) as CollectionSelectFilter<ItemType>[])
    : undefined;
  const filteringProperties = !disableFiltering
    ? filteringPropertyOptions
    : undefined;

  const getStorageData = <ReturnType extends any>(
    storagePartitionKey: StoragePartitionKeys
  ) => {
    if (!storageDedupeKey) {
      return;
    }

    const data: ReturnType | undefined = StoragePartition.getItem(
      storagePartitionKey,
      id,
      storageDedupeKey
    );

    return data ? data : undefined;
  };
  const setStorageData = (
    storagePartitionKey: StoragePartitionKeys,
    value: any
  ) => {
    if (!storageDedupeKey) {
      return;
    }

    StoragePartition.setItem(storagePartitionKey, value, id, storageDedupeKey);
  };
  const getStoredPreferences = () =>
    i18nStrings.preferences &&
    getStorageData<CollectionPreferencesProps.Preferences>(
      StoragePartitionKeys.tablePreferences
    );
  const getStoredCollectionSelectFilterValues = () => {
    if (!collectionSelectFilters) {
      return;
    }

    const values = getStorageData<Record<string, SelectProps.Option>>(
      StoragePartitionKeys.userPreferences
    );

    if (!values) {
      return;
    }

    const res: Record<string, SelectProps.Option> = {};

    // map stored values with current filter options.
    // can't just use the stored value since filter options can change overtime.
    collectionSelectFilters.forEach(({ id, options }) => {
      if (!values[id]) {
        return;
      }

      const option = options.find(({ value }) => value === values[id].value);

      if (option) {
        res[id] = option;
      }
    });

    return res;
  };
  const getStoredSortingState = () =>
    getStorageData<TableProps.SortingState<ItemType>>(
      StoragePartitionKeys.tableSortColumns
    );
  const storedState = useMemo(() => {
    if (!storageDedupeKey) {
      StoragePartition.setItem(StoragePartitionKeys.tablePreferences, null, id);
      StoragePartition.setItem(StoragePartitionKeys.tableSortColumns, null, id);
      return;
    }

    let preferences = getStoredPreferences();
    let sorting = getStoredSortingState();

    // clear partition under id if no preferences are found for the current storageDedupeKey
    // this will prevent buildup of old preferences as the storageDedupeKey is updated
    if (!preferences) {
      StoragePartition.setItem(StoragePartitionKeys.tablePreferences, null, id);
      preferences = undefined;
    }

    if (!sorting) {
      StoragePartition.setItem(StoragePartitionKeys.tableSortColumns, null, id);
      sorting = undefined;
    } else {
      // re-associate sorting comparator
      const sortingComparator = columnDefinitions.find(
        ({ sortingField }) =>
          sortingField === sorting?.sortingColumn.sortingField
      )?.sortingComparator;

      if (sortingComparator) {
        sorting.sortingColumn.sortingComparator = sortingComparator;
      }
    }

    return {
      preferences,
      sorting,
    };
  }, []);
  const [refreshing, setRefreshing] = useState(false);
  const allActions = useMemo(() => {
    const result = [];

    actions && result.push(...actions);

    onRefresh &&
      result.unshift(
        <Button
          key={`${id}_${TestID.ACTION_REFRESH}`}
          data-testid={TestID.ACTION_REFRESH}
          onClick={() => {
            setRefreshing(true);
            onRefresh();
          }}
          disabled={!items && loading}
          loading={refreshing}
          variant="normal"
          iconName="refresh"
        />
      );

    return result;
  }, [onRefresh, loading, refreshing, actions]);
  const defaultCollectionSelectFilterValues = useMemo<
    Record<string, SelectProps.Option | null>
  >(
    () =>
      collectionSelectFilters?.length
        ? collectionSelectFilters.reduce((acc, a) => {
            const defaultOption =
              a.defaultOption || a.options.find(({ value }) => value === "");

            return {
              ...acc,
              [a.id]: defaultOption || null,
            };
          }, {})
        : {},
    [collectionSelectFilters]
  );
  const [
    collectionSelectFilterValues,
    setCollectionSelectFilterValues,
  ] = useState<Record<string, SelectProps.Option | null>>({
    ...defaultCollectionSelectFilterValues,
    ...getStoredCollectionSelectFilterValues(),
  });
  const [
    preferences,
    setPreferences,
  ] = useState<CollectionPreferencesProps.Preferences>(
    storedState?.preferences || {
      pageSize,
      visibleContent,
      contentDensity,
      stickyColumns,
      wrapLines,
    }
  );
  const collectionSelectFilterActive =
    collectionSelectFilters?.length &&
    collectionSelectFilters.some(
      ({ id, defaultOption }) =>
        collectionSelectFilterValues[id]?.value !== (defaultOption?.value || "")
    );
  const noMatchMessage = (
    <EmptyMessage
      title={i18nStrings.noMatchMessage.title}
      description={i18nStrings.noMatchMessage.description}
      actions={
        <Button
          data-testid={TestID.ACTION_CLEAR_ALL_FILTERS}
          onClick={() => {
            collectionActions.setPropertyFiltering(
              DEFAULT_PROPERTY_FILTER_VALUE
            );
            collectionActions.setFiltering("");
            collectionSelectFilters?.length &&
              setCollectionSelectFilterValues(
                defaultCollectionSelectFilterValues
              );
          }}
        >
          {i18nStrings.noMatchMessage.clearAllLabel}
        </Button>
      }
    />
  );
  const sharedFilteringProps = {
    empty:
      error && i18nStrings.errorMessage ? (
        <EmptyMessage
          title={i18nStrings.errorMessage.title}
          description={i18nStrings.errorMessage.description}
        />
      ) : !items?.length ? (
        <EmptyMessage
          title={i18nStrings.emptyMessage.title}
          description={i18nStrings.emptyMessage.description}
          actions={emptyMessageAction}
        />
      ) : (
        noMatchMessage
      ),
    noMatch: noMatchMessage,
  };
  const {
    items: visibleItems,
    filteredItemsCount,
    collectionProps,
    actions: collectionActions,
    propertyFilterProps,
    filterProps,
    paginationProps,
  } = useCollection(
    (collectionSelectFilters?.length
      ? items?.filter((item) =>
          collectionSelectFilters?.every(({ id, filter }) => {
            const filterValue = collectionSelectFilterValues[id]?.value;

            if (!filterValue) {
              return true;
            }

            return filter(item, filterValue);
          })
        )
      : items) || [],
    {
      [filteringProperties ? "propertyFiltering" : "filtering"]: {
        ...sharedFilteringProps,
        ...(filteringProperties
          ? {
              filteringProperties,
              defaultQuery: DEFAULT_PROPERTY_FILTER_VALUE,
            }
          : {}),
      },
      pagination: !disablePagination
        ? { pageSize: preferences?.pageSize }
        : undefined,
      sorting: {
        defaultState: storedState?.sorting?.sortingColumn.sortingField
          ? storedState.sorting
          : undefined,
      },
      selection: selectionType
        ? {
            trackBy,
            keepSelection: true,
          }
        : undefined,
    }
  );
  const filtersActive =
    propertyFilterProps.query.tokens.length ||
    filterProps.filteringText ||
    collectionSelectFilterActive;
  const filteredItemsLabel = items
    ? countText(filteredItemsCount || 0, i18nStrings.filteredItemsCountSuffix)
    : undefined;
  const collectionSelectFilterSelectors =
    collectionSelectFilters?.length &&
    collectionSelectFilters.map(({ id, label, options }) => (
      <div key={id} data-testid={id} className={styles.select}>
        <Select
          selectedOption={collectionSelectFilterValues[id]}
          onChange={({ detail: { selectedOption } }) =>
            setCollectionSelectFilterValues({
              ...collectionSelectFilterValues,
              [id]: selectedOption,
            })
          }
          options={options}
          placeholder={label}
        />
      </div>
    ));
  const filterCountText = collectionSelectFilterSelectors
    ? undefined
    : filteredItemsLabel;
  const filter = filteringProperties ? (
    <PropertyFilter
      {...propertyFilterProps}
      i18nStrings={i18nStrings.filter}
      filteringOptions={getFilteringOptions(items || [], filteringProperties)}
      countText={filterCountText}
    />
  ) : (
    !disableFiltering && (
      <TextFilter
        {...filterProps}
        countText={filterCountText}
        filteringPlaceholder={i18nStrings.filter.filteringPlaceholder}
      />
    )
  );

  useEffect(() => {
    setStorageData(StoragePartitionKeys.tableSortColumns, {
      isDescending: collectionProps.sortingDescending,
      sortingColumn: {
        sortingField: collectionProps.sortingColumn?.sortingField,
      },
    });
  }, [collectionProps.sortingColumn, collectionProps.sortingDescending]);

  useEffect(() => {
    setStorageData(StoragePartitionKeys.tablePreferences, preferences);
  }, [preferences]);

  useEffect(() => {
    setStorageData(
      StoragePartitionKeys.userPreferences,
      collectionSelectFilterValues
    );
  }, [collectionSelectFilterValues]);

  useEffect(() => {
    if (!refreshing) {
      return;
    }

    !loading && setRefreshing(false);
  }, [loading, refreshing]);

  return {
    component: (
      <Table
        {...collectionProps}
        selectedItems={selectedItems}
        onSelectionChange={(detail) => {
          onSelectionChange?.(detail.detail.selectedItems);
          collectionProps.onSelectionChange?.(detail);
        }}
        header={
          <Header
            counter={
              items
                ? `(${selectedItems?.length ? `${selectedItems.length}/` : ""}${
                    items.length
                  })`
                : undefined
            }
            info={info}
            description={i18nStrings.description}
            actions={
              allActions.length ? (
                <SpaceBetween direction="horizontal" size="xs">
                  {allActions}
                </SpaceBetween>
              ) : null
            }
          >
            {i18nStrings.title}
          </Header>
        }
        columnDefinitions={columnDefinitions}
        visibleColumns={preferences?.visibleContent}
        items={visibleItems}
        filter={
          collectionSelectFilterSelectors ? (
            <div
              className={styles.filterWithCollectionSelect}
              data-testid={TestID.COLLECTION_SELECT_FILTERS}
            >
              {filter && <div className={styles.filter}>{filter}</div>}
              {collectionSelectFilterSelectors}
              {filtersActive && filteredItemsLabel && (
                <div className={styles.countText}>{filteredItemsLabel}</div>
              )}
            </div>
          ) : (
            filter
          )
        }
        pagination={
          !disablePagination && (
            <Pagination
              {...paginationProps}
              ariaLabels={i18nStrings.pagination}
            />
          )
        }
        preferences={
          i18nStrings.preferences && (
            <CollectionPreferences
              title={i18nStrings.preferences.title}
              cancelLabel={i18nStrings.preferences.cancelLabel}
              confirmLabel={i18nStrings.preferences.confirmLabel}
              preferences={preferences}
              onConfirm={({ detail }) => setPreferences(detail)}
              visibleContentPreference={{
                title: i18nStrings.preferences.visibleContent.title,
                options: [
                  {
                    label: i18nStrings.preferences.visibleContent.optionsLabel,
                    options: columnDefinitions.reduce<
                      CollectionPreferencesProps.VisibleContentOption[]
                    >(
                      (acc, column) =>
                        column.id
                          ? [
                              ...acc,
                              {
                                id: column.id,
                                label:
                                  column.label ||
                                  (typeof column.header === "string"
                                    ? column.header
                                    : column.id),
                                editable: column.editable,
                              },
                            ]
                          : acc,
                      []
                    ),
                  },
                ],
              }}
              pageSizePreference={{
                options: PAGE_SIZES.map((value) => ({
                  value,
                  label: `${value} ${i18nStrings.preferences?.pageSize.optionSuffix}`,
                })),
                title: i18nStrings.preferences.pageSize.optionSuffix,
              }}
              wrapLinesPreference={{
                label: i18nStrings.preferences.wrapLines.label,
                description: i18nStrings.preferences.wrapLines.description,
              }}
              contentDensityPreference={
                i18nStrings.preferences.contentDensity && {
                  label: i18nStrings.preferences.contentDensity.label,
                  description:
                    i18nStrings.preferences.contentDensity.description,
                }
              }
              stickyColumnsPreference={
                i18nStrings.preferences.stickyColumns && {
                  firstColumns: {
                    title: i18nStrings.preferences.stickyColumns.title,
                    description:
                      i18nStrings.preferences.stickyColumns.description,
                    options: [
                      {
                        label:
                          i18nStrings.preferences.stickyColumns.noneOptionLabel,
                        value: 0,
                      },
                      {
                        label:
                          i18nStrings.preferences.stickyColumns
                            .firstOptionLabel,
                        value: 1,
                      },
                    ],
                  },
                }
              }
            />
          )
        }
        isItemDisabled={isItemDisabled}
        loading={!items && loading}
        loadingText={i18nStrings.loadingText}
        wrapLines={preferences?.wrapLines}
        contentDensity={preferences?.contentDensity}
        stickyColumns={preferences.stickyColumns}
        resizableColumns={resizableColumns}
        stickyHeader={stickyHeader}
        selectionType={selectionType}
        variant={variant}
      />
    ),
  };
};

export default useTable;
