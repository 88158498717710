import Browser, { BrowserType, OSType } from "utils/browser";

const polyfills: Promise<any>[] = [];

if (Browser.operatingSystem() === OSType.Windows) {
  const ieVersion: number = Browser.getIEVersion();

  if (ieVersion >= 0 && ieVersion <= 11) {
    // @ts-ignore
    polyfills.push(import("core-js/features/url"));
    // @ts-ignore
    polyfills.push(import("core-js/features/url-search-params"));
  }
} else if (Browser.operatingSystem() === OSType.Mac) {
  const browserType = Browser.getBrowserType();

  if (browserType === BrowserType.Safari) {
    // @ts-ignore
    polyfills.push(import("core-js/features/url"));
  }
}

export default polyfills;
