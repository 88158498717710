import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";

import { RefreshSessionOptions } from "../types";

/**
 * Check if the session expiration falls within the expiration window before attempting to refresh.
 * There is a non-trivial (15 minute) expiration window enforced by the backend so we cannot rely on
 * Amplify.Auth.currentSession to refresh the session for us, which occurs close to the expiration boundary.
 * https://code.amazon.com/packages/EEAuthProxyLambda/blobs/656dfbb4fd112ad21bf8e0dd33f830e5552ca636/--/cmd/EEAuthProxyV2Lambda/main.go#L175
 *
 * @param {RefreshSessionOptions} [options] Refresh session options, optional
 * @returns {Promise<CognitoUserSession>}
 */
const refreshSession = async ({
  expiryWindow = 0,
  logger,
}: RefreshSessionOptions = {}) => {
  const currentSession = await Auth.currentSession();
  const timeTilExpiration =
    currentSession.getIdToken().getExpiration() -
    Date.now() / 1000 -
    expiryWindow;

  if (timeTilExpiration > 0) {
    logger?.info(
      `Current session is still valid, ${timeTilExpiration} second(s) remaining`
    );
    return currentSession;
  }

  logger?.info(`Current session expired, refreshing...`);

  const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();

  return new Promise<CognitoUserSession>((resolve, reject) => {
    cognitoUser.refreshSession(
      currentSession.getRefreshToken(),
      (err: any, session: CognitoUserSession) => {
        if (err) {
          return reject(err);
        }

        return resolve(session);
      }
    );
  });
};

export default refreshSession;
