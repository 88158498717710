import { ILogProvider } from "./../Logger.interface";

const LocationLoggerProvider: ILogProvider<string> = {
  getProviderName() {
    return "LocationProvider";
  },
  getData() {
    return window.location.href;
  },
};

export default LocationLoggerProvider;
