import isNumeric from "./is-numeric";

/**
 * Converts instances of boolean and number strings into parsed value
 * @param {Object} attributes
 * @returns {Object}
 */
const evaluateAttributeValues = (attributes?: any) => {
  if (!attributes) {
    return {};
  }

  const parsedAttributes = { ...attributes };
  Object.keys(parsedAttributes).forEach((key) => {
    if (parsedAttributes[key] === "true") {
      parsedAttributes[key] = true;
    }
    if (parsedAttributes[key] === "false") {
      parsedAttributes[key] = false;
    }
    if (isNumeric(parsedAttributes[key])) {
      parsedAttributes[key] = parseFloat(parsedAttributes[key]);
    }
  });
  return parsedAttributes;
};

export default evaluateAttributeValues;
