import { ILog } from "lib/Logger/Logger.interface";

interface PollEngineArgs {
  pollMs: number;
  pollEngineName: string;
  startImmediate: boolean;
  fn?: Function;
  fnContext?: any;
}

/**
 * Poll Engine is a utility function that polls a given function every pollMs.
 *
 * @param {PollEngineArgs} pollEngineArgs arguments to create a poll engine
 * @param {Function} pollEngineArgs.fn function that will be invoked after a specified time has passed
 * @param {number} pollEngineArgs.pollMs number of milliseconds to pass before logs are sent out
 * @param {string} pollEngineArgs.pollEngineName name of the poll engine name used in logs
 * @param {boolean} pollEngineArgs.startImmediate boolean indicating whether or not the poll engine should start immediately
 * @param {any} pollEngineArgs.fnContext optional context that will be passed in the callback timer function
 */
export default class PollEngine {
  private static logger: ILog;
  static setLogger(logger: ILog) {
    PollEngine.logger = logger;
  }
  private preLog: string;
  private pollMs: number;
  private stopPoll: boolean;
  private pollEngineName: string;
  private started: boolean;
  private fn?: Function;
  private fnContext?: any;
  private timeout: NodeJS.Timeout | undefined;
  constructor({
    fn,
    pollMs,
    pollEngineName,
    fnContext = null,
    startImmediate = false,
  }: PollEngineArgs) {
    this.preLog = "<PollEngine>";
    this.fn = fn;
    this.pollMs = pollMs;
    this.stopPoll = false;
    this.pollEngineName = pollEngineName;
    this.started = false;
    this.fnContext = fnContext;
    this.timeout = undefined;

    if (startImmediate) {
      this.started = true;
      this.startPolling();
    }
  }
  registerPollFn(fn: Function) {
    this.fn = fn;
  }
  registerContext(context: any) {
    this.fnContext = context;
  }
  startPolling() {
    this.log(`Starting ${this.pollEngineName} poll engine.`);
    this.started = true;
    this.poll();
  }
  stopPolling() {
    if (!this.started) {
      return;
    }

    this.log(`Stopping ${this.pollEngineName} poll engine.`);

    this.timeout && clearTimeout(this.timeout);
    this.started = false;
    this.stopPoll = true;
  }
  log(msg: string) {
    PollEngine.logger?.verbose(`${this.preLog}: ${msg}`);
  }
  poll = () => {
    if (this.stopPoll) {
      return;
    }

    this.fn?.call(this.fnContext);

    this.timeout = setTimeout(this.poll.bind(this), this.pollMs);
  };
}
