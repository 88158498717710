import { ContentLayout } from "components/PreviewComponent/strategy/ContentStrategy";
import React, { FC, useContext } from "react";
import { RecursivePartial } from "types";
import { Constants } from "utils";

import { MarkdownRendererContext } from "../../contexts/MarkdownRendererProvider";
import Link from "../Link";
import css from "./Children.module.scss";

export enum ChildrenVariant {
  LIST = "list",
  NORMAL = "normal",
}

export interface ChildrenProps {
  depth?: number;
  variant?: ChildrenVariant;
}

const Children: FC<ChildrenProps> = ({
  depth,
  variant = ChildrenVariant.LIST,
}) => {
  const { config = {} } = useContext(MarkdownRendererContext);
  const { contentLayout } = config;
  const renderItems = (items: ContentLayout, currentDepth = 0) => {
    currentDepth += 1;

    return (
      items && (
        <ul
          className={`${currentDepth === 1 ? css.children : ""} ${
            variant === ChildrenVariant.NORMAL ? css.normal : ""
          }`}
        >
          {items.map((item, index) => {
            let itemTitle:
              | string
              | RecursivePartial<
                  | {
                      [key: string]: string;
                    }
                  | null
                  | undefined
                > = item.title;

            if (typeof itemTitle === "object") {
              let itemLocaleTitle: string | undefined;

              // Attempt to get local title
              itemLocaleTitle = itemTitle?.[config.locale || ""];

              // itemLocaleTitle may be falsy if there was no local provided, or
              // if there was no title defined for the provided locale. Try
              // and get the title for the system default locale
              if (!itemLocaleTitle) {
                itemLocaleTitle = itemTitle?.[Constants.DefaultLocale];
              }

              itemTitle = itemLocaleTitle;
            }

            // At this point, there is no title value to be found.
            // Default to path value so we don't render a link without text
            if (!itemTitle) {
              itemTitle = item.path;
            }

            return (
              <li key={`${item.path}_${index + 1}`}>
                {<Link href={item.path}>{itemTitle}</Link>}
                {currentDepth < (depth || 0)
                  ? renderItems(item.navigation as [], currentDepth)
                  : null}
              </li>
            );
          })}
        </ul>
      )
    );
  };

  if (!contentLayout) {
    return null;
  }

  return renderItems(contentLayout);
};

export default Children;
