import { Constants, withRetry } from "utils";

export default function lazyPreviewComponentImport(): Promise<
  typeof import("./PreviewComponent.container")
> {
  return withRetry(() => import("./PreviewComponent.container"), {
    retryFn: Constants.exponentialBackoff,
    interval: Constants.retryBackoff.interval,
  });
}
