/**
 * Checks if a string is a number
 * @param {string} value
 * @returns {boolean}
 */
const isNumeric = (value: string) => {
  if (typeof value === "number") {
    return true;
  }

  if (typeof value !== "string") {
    return false;
  }

  const toFloat = parseFloat(value);
  return !isNaN(Number(value)) && (toFloat || toFloat === 0);
};

export default isNumeric;
