import { AxiosRequestConfig, AxiosResponse, Method } from "axios";
import { BaseApiClient } from "lib/ApiClient";
import { AbstractDatabase } from "lib/Database";

class CacheInterceptor<T> {
  private cachedHeaderName: string = "internal-cache-header";
  constructor(private db: AbstractDatabase<string>) {
    this.db = db;
  }
  addInterceptor = (client: BaseApiClient) => {
    client.interceptors.request.use((request) => this.requestHandler(request));

    client.interceptors.response.use(
      (response) => this.responseHandler(response),
      (error) => this.errorHandler(error)
    );
  };
  private responseHandler = (response: AxiosResponse<T>): AxiosResponse<T> => {
    if (this.isGetRequest(response.config.method)) {
      if (response.config.url) {
        this.db.create(response.config.url, JSON.stringify(response.data));
      }
    }
    return response;
  };
  private errorHandler = (error: any) => {
    if (this.containsCachedHeaderValue(error?.headers)) {
      return Promise.resolve(error);
    }
    return Promise.reject(error);
  };
  private requestHandler = (request: AxiosRequestConfig) => {
    if (this.isGetRequest(request.method)) {
      const data = this.db.get(request.url || "");
      if (data) {
        this.setCachedHeader(request);
        request.data = JSON.parse(data);
        return Promise.reject(request);
      }
    }
    return request;
  };
  private setCachedHeader = (request: AxiosRequestConfig) => {
    request.headers[this.cachedHeaderName] = true;
  };
  private containsCachedHeaderValue = (
    header: AxiosRequestConfig["headers"] = {}
  ) => {
    return !!header[this.cachedHeaderName];
  };
  private isGetRequest = (method: Method | undefined) => {
    return method && (method === "GET" || method === "get");
  };
}

export default CacheInterceptor;
