export const i18nStrings = {
  header: "AWS account access",
  closeAction: "Close",
  sectionAWSAccountLogin: "AWS account login",
  sectionAWSCliCredentials: "AWS CLI credentials",
  openAWSConsoleAction: "Open AWS console",
  copyLoginUrlAction: "Copy login URL",
};

export const TEST_IDS = {
  MESSAGE: "message",
  SECTION_CONSOLE_LOGIN: "section-console-login",
  SECTION_CLI_CREDENTIALS: "section-cli-credentials",
  BUTTON_CONSOLE_LOGIN: "button-console-login",
  BUTTON_COPY_LOGIN: "button-copy-login",
  CLOSE_BUTTON: "close-button",
};
