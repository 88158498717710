import deepClone from "lodash.clonedeep";

/**
 * DataChunk stores data and returns chunks of data with the specified
 * chunkSize value.
 *
 * This utility function is useful if we are sending large amounts of data.
 * If the API has a max size (KB) for a set of request data, create chunks of
 * data and send smaller payloads.
 *
 * For instance, each CloudWatch PutMetricData request is limited to 40 KB in size for
 * HTTP POST requests.
 */
class DataChunk<T> {
  constructor(private chunkSize: number = 10, private data: T[] = []) {}
  public setChunkSize(chunkSize: number) {
    this.chunkSize = chunkSize;
  }
  public addData(newData: T | T[]): number {
    if (Array.isArray(newData)) {
      return this.data.push(...newData);
    }
    return this.data.push(newData);
  }
  public isEmpty() {
    return this.data.length === 0;
  }
  public getRawData = (): T[] => {
    return this.data;
  };
  public getDataChunks = (): { data: T[][]; length: number } => {
    return {
      length: this.data.length,
      data: this._chunkArray(),
    };
  };
  /**
   * If a given length is given, remove first N elements.
   * If none is provided, clear all elements
   */
  public clear = (length?: number) => {
    if (!length) {
      this.data = [];
      return;
    }

    return this.data.splice(0, length);
  };

  private _chunkArray(): T[][] {
    const result = [];

    const clonedData = deepClone(this.data);

    while (clonedData.length > 0) {
      result.push(clonedData.splice(0, this.chunkSize));
    }

    return result;
  }
}

export default DataChunk;
