import { MetricsClient, S3Client } from "clients";
import React, { createContext, PropsWithChildren, ReactNode } from "react";

interface ClientProviderContextType {
  metricsClient: MetricsClient | undefined;
  s3Client: S3Client | undefined;
}

// strips | undefined from all keys in object
// type RequiredClientProviderContextType = {
//   [K in keyof ClientProviderContextType]-?: NonNullable<
//     ClientProviderContextType[K]
//   >;
// };

export const ClientProviderContext = createContext<ClientProviderContextType>({
  metricsClient: undefined,
  s3Client: undefined,
});

interface ClientProviderProps extends ClientProviderContextType {
  children: PropsWithChildren<ReactNode>;
}

const ClientProvider = ({
  metricsClient,
  s3Client,
  children,
}: ClientProviderProps) => (
  <ClientProviderContext.Provider
    value={{
      metricsClient,
      s3Client,
    }}
  >
    {children}
  </ClientProviderContext.Provider>
);

export default ClientProvider;
