const FORMAT_STRING_REGEXP = new RegExp(/{(\d+)}/gm);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function _onFormatReplace(args: any, num: any): string {
  const index = parseInt(num, 10) + 1;
  return args[index] !== undefined ? args[index] : "";
}

/**
 * Formatting utility function similar to Python's .format function.
 * https://www.digitalocean.com/community/tutorials/how-to-use-string-formatters-in-python-3
 *
 * Please view tests in text.test.ts for examples of how to use in javascript.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function formatString(...parameters: any[]): string {
  // eslint-disable-next-line prefer-rest-params
  const args = arguments;
  const source = args[0];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return source
    ? source.replace(FORMAT_STRING_REGEXP, (_match: any, num: any) =>
        _onFormatReplace(args, num)
      )
    : "";
}

export default formatString;
