import DataChunk from "lib/DataChunk";
import { RateLimiter } from "limiter";

export interface ClientRequestResponse {
  nextToken?: string | undefined;
}

export interface ClientArguments {
  limit?: number | undefined;
  nextToken?: string | undefined;
}
export type PromiseResult<D, _E> = D;

export interface PaginationClient<S, T> {
  request: (args: S) => Promise<PromiseResult<T, any> | undefined>;
}

type GetDataOptions<S> = {
  fresh?: boolean;
  clientArgs?: S;
};

type AllDataDelimiter = "*";

const rateLimitThrottleMs = 250;

/**
 * Paginator is a utility class that allows us to paginate through data
 * when the server expects a next token.
 *
 * Given a client, request data from a server with either page, or start/end
 * cursors.
 *
 * This Paginator also throttles requests to the backend so we do not rate limit ourselves.
 * We only allow one token (request) per rateLimitThrottleMs ms.
 *
 * Note, This is not a regular throttle (i.e. only fire one request per interval). This throttle queues up
 * commands, and executes them in the order they came in.
 *
 * S denotes the server response
 */
class Paginator<S extends ClientArguments, T extends ClientRequestResponse> {
  private readonly limiter: RateLimiter;
  private readonly allDataDelimiter: AllDataDelimiter = "*";
  private readonly dataChunk: DataChunk<NonNullable<T>[keyof T]>;
  private nextToken: string | undefined;
  private processedChunks: number = 0;
  constructor(
    private readonly client: PaginationClient<S, T>,
    private limit: number = 10,
    private readonly dataKey: keyof T,
    rateLimiterInterval?: number | undefined
  ) {
    this.dataChunk = new DataChunk<NonNullable<T>[keyof T]>(limit);

    /**
     * Only allow one token per rateLimitThrottleMs in order to not rate limit our
     * services.
     *
     * When running in a test environment, reduce interval so the tests
     * do not take forever.
     */
    this.limiter = new RateLimiter({
      tokensPerInterval: 1,
      interval:
        process.env.NODE_ENV === "test"
          ? 1
          : rateLimiterInterval || rateLimitThrottleMs,
    });

    this.init();
  }
  /**
   * Retrives data based off of page number. Each page will be constrained
   * to the the limit provided in the constructor.
   */
  public getPage = async <Args extends S>(
    page: number,
    options?: GetDataOptions<Args>
  ): Promise<T | undefined> => {
    const startingPoint = (page - 1) * this.limit;
    const endingPoint = startingPoint + this.limit;

    return this.getPaginatedData(startingPoint, endingPoint, options);
  };
  /**
   * Data constructed based off of start and ending point. If the server
   * does not contain the full range of data, exits early and returns
   * available data.
   */
  public getPaginatedData = async <Args extends S>(
    start: number,
    end: number | "*",
    options?: GetDataOptions<Args>
  ): Promise<T | undefined> => {
    if (options?.fresh) {
      this.init();
    }

    const cachedData: NonNullable<T>[keyof T][] = this.getDataFromChunker(
      start,
      end
    );

    const dataKey = this.dataKey;

    /**
     * Unless the requester provides { fresh: true }, return
     * cached data. This data persists with the lifecycle
     * of the component.
     */
    if (Array.isArray(cachedData) && cachedData.length) {
      return (Promise.resolve({
        [dataKey]: cachedData,
        nextToken: this.nextToken,
      }) as unknown) as T;
    }

    /**
     * If there was no next token, and processed chunks,
     * that means the server has no data to give us
     */
    if (!this.nextToken && !!this.processedChunks) {
      return (Promise.resolve({
        [dataKey]: [],
        nextToken: this.nextToken,
      }) as unknown) as T;
    }

    /**
     * Loop until we have retrieved all data the user has requested
     */
    const totalLoops =
      end === this.allDataDelimiter
        ? Number.MAX_VALUE
        : Math.ceil((end as number) / this.limit) - this.processedChunks;

    for (let i = 0; i < totalLoops; i++) {
      const result: T = (await this.paginationRequest<Args>(
        options?.clientArgs
      )) as T;

      this.nextToken = result?.nextToken;

      const data: NonNullable<T>[keyof T] | undefined = result?.[dataKey];

      data && this.dataChunk.addData(data);

      this.processedChunks += 1;

      if (!this.nextToken) {
        break;
      }
    }

    return ({
      [this.dataKey]: this.getDataFromChunker(start, end),
      nextToken: this.nextToken,
    } as unknown) as T;
  };
  public updateLimit = (limit: number) => {
    const oldLimit = this.limit;
    this.limit = limit;

    this.processedChunks = Math.ceil((oldLimit * this.processedChunks) / limit);
  };
  private paginationRequest = async <Args>(args: Args | undefined) => {
    // The rate limiter will "wait" until the next request is available
    // so we do not throttle ourselves
    await this.limiter.removeTokens(1);

    return this.client.request({
      ...args,
      limit: this.limit,
      nextToken: this.nextToken,
    } as S);
  };
  private getDataFromChunker = (
    start: number,
    end: number | AllDataDelimiter
  ): NonNullable<T>[keyof T][] => {
    if (end === this.allDataDelimiter) {
      return this.dataChunk.getRawData();
    }

    return this.dataChunk.getRawData().slice(start, end as number);
  };
  private init = () => {
    this.dataChunk.clear();
    this.nextToken = undefined;
    this.processedChunks = 0;
  };
}

export default Paginator;
