import { NonCancelableCustomEvent } from "@amzn/awsui-components-react/polaris/internal/events/index";
import Tiles, { TilesProps } from "@amzn/awsui-components-react/polaris/tiles";
import { useField, useFormikContext } from "formik";
import isEqual from "lodash.isequal";
import React, { useCallback } from "react";

import { FormikFieldProps } from "./../FormControls.interface";
import memoizedFormikPropCheck from "./../FormControls.util";

export interface TileFieldProps<S = any> {
  name: keyof S;
  tileProps: Omit<TilesProps, "value" | "onChange">;
}

function TileField<S, T extends string>({
  tileProps,
  field,
  helpers,
}: TileFieldProps<S> & FormikFieldProps<T>) {
  const handleChange = useCallback(
    (e: NonCancelableCustomEvent<TilesProps.ChangeDetail>) => {
      helpers.setTouched(true);
      helpers.setValue(e.detail.value as T);
    },
    [helpers]
  );

  return <Tiles onChange={handleChange} value={field.value} {...tileProps} />;
}

const MemoizedTileField = React.memo(TileField, (prevProps, nextProps) => {
  if (
    !memoizedFormikPropCheck(prevProps, nextProps) ||
    !isEqual(prevProps.tileProps?.items, nextProps.tileProps?.items)
  ) {
    return false;
  }

  return true;
});

const TileFieldContainer = <S,>(props: TileFieldProps<S>) => {
  const [field, meta, helpers] = useField<string>(props.name as string);
  const { submitCount } = useFormikContext();

  const optProps = {
    ...props,
    name: props.name as never,
    didSubmit: submitCount >= 0,
  };

  return <MemoizedTileField {...{ field, meta, helpers }} {...optProps} />;
};

export default TileFieldContainer;
