import Alert from "@amzn/awsui-components-react/polaris/alert";
import Box from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Tabs, { TabsProps } from "@amzn/awsui-components-react/polaris/tabs";
import AWSCliCredentials from "components/AWSCliCredentials";
import CopyButton from "components/CopyButton";
import BaseMarkdown from "components/MarkdownRenderer/BaseMarkdown";
import React, { FC, Fragment, ReactNode, useCallback, useMemo } from "react";

import { i18nStrings, TEST_IDS } from "./AWSAccountAccessModal.constants";
import { AWSAccountAccessModalProps } from "./AWSAccountAccessModal.interface";
import css from "./AWSAccountAccessModal.module.scss";
import { SectionHeader } from "./components";

const AWSAccountAccessModal: FC<AWSAccountAccessModalProps> = ({
  header = i18nStrings.header,
  infoMessage,
  roleConfigs,
  size,
  visible,
  defaultRegion,
  onDismiss,
}) => {
  const handleOnDismiss = useCallback(() => {
    onDismiss && onDismiss();
  }, [onDismiss]);
  const roles = useMemo(
    () =>
      roleConfigs.map<TabsProps.Tab>(
        ({ id, name, federatedLoginUrl, consoleLoginUrl, message }, index) => {
          const consoleLoginActions: ReactNode[] = [];

          consoleLoginUrl &&
            consoleLoginActions.push(
              <Button
                key={TEST_IDS.BUTTON_CONSOLE_LOGIN}
                data-testid={TEST_IDS.BUTTON_CONSOLE_LOGIN}
                href={consoleLoginUrl}
                variant="primary"
                iconAlign="right"
                iconName="external"
                target="_blank"
              >
                {i18nStrings.openAWSConsoleAction}
              </Button>
            );

          federatedLoginUrl &&
            consoleLoginActions.push(
              <CopyButton
                key={TEST_IDS.BUTTON_COPY_LOGIN}
                content={federatedLoginUrl}
              >
                {i18nStrings.copyLoginUrlAction}
              </CopyButton>
            );

          return {
            id,
            label: name,
            content: (
              <Box
                padding={{
                  horizontal: roleConfigs.length > 1 ? "l" : undefined,
                }}
              >
                <SpaceBetween direction="vertical" size="m">
                  {message ? (
                    <div data-testid={TEST_IDS.MESSAGE}>{message}</div>
                  ) : null}
                  {consoleLoginActions.length ? (
                    <section data-testid={TEST_IDS.SECTION_CONSOLE_LOGIN}>
                      <SectionHeader iconName="lock-private">
                        {i18nStrings.sectionAWSAccountLogin}
                      </SectionHeader>
                      <SpaceBetween direction="horizontal" size="xs">
                        {consoleLoginActions.map((action) => action)}
                      </SpaceBetween>
                    </section>
                  ) : null}
                  <section data-testid={TEST_IDS.SECTION_CLI_CREDENTIALS}>
                    <SectionHeader iconName="key">
                      {i18nStrings.sectionAWSCliCredentials}
                    </SectionHeader>
                    <AWSCliCredentials
                      credentials={roleConfigs[index].credentials}
                      variant="segmented-control"
                      showCopyAction
                    />
                    {infoMessage && (
                      <Box margin={{ top: "m" }}>
                        <Alert type="info">
                          <BaseMarkdown
                            components={{ p: Fragment }}
                            className={css.textContent}
                          >
                            {infoMessage}
                          </BaseMarkdown>
                        </Alert>
                      </Box>
                    )}
                  </section>
                </SpaceBetween>
              </Box>
            ),
          };
        }
      ),
    [roleConfigs, defaultRegion]
  );

  return (
    <Modal
      className={roles.length > 1 ? css.modal : undefined}
      header={header}
      onDismiss={handleOnDismiss}
      size={size}
      visible={visible}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              data-testid={TEST_IDS.CLOSE_BUTTON}
              onClick={handleOnDismiss}
              variant="link"
            >
              {i18nStrings.closeAction}
            </Button>
          </SpaceBetween>
        </Box>
      }
      disableContentPaddings={roles.length > 1}
    >
      {roles.length > 1 && (
        <Box padding={{ bottom: "xxs" }}>
          <Tabs className={css.tabs} tabs={roles} />
        </Box>
      )}
      {roles.length === 1 && roles[0].content}
    </Modal>
  );
};

export { AWSAccountAccessModalProps } from "./AWSAccountAccessModal.interface";
export default AWSAccountAccessModal;
