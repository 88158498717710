import Browser, { BrowserType, OSType } from "utils/browser";

const polyfills: Promise<any>[] = [];

// Support conditions based on https://caniuse.com/css-scroll-behavior
if (Browser.operatingSystem() === OSType.Windows) {
  const ieVersion: number = Browser.getIEVersion();

  if (ieVersion >= 0 && ieVersion <= 18) {
    // @ts-ignore
    polyfills.push(import("smoothscroll-polyfill"));
  }
} else if (Browser.operatingSystem() === OSType.Mac) {
  const browserType = Browser.getBrowserType();
  if (browserType === BrowserType.Safari) {
    // @ts-ignore
    polyfills.push(import("smoothscroll-polyfill"));
  }
}

export default polyfills;
