import { useCallback, useEffect, useState } from "react";

interface UseVisibilityChangeOptions {
  onVisible?: () => void;
  onHidden?: () => void;
}

interface UseVisibilityChangeResult {
  isHidden: boolean;
}

/**
 * If a user exits the current tab view, execute the onHidden callback.
 * If the user comes back into view, call the onVisible callback.
 */
const useVisibilityChange = ({
  onVisible,
  onHidden,
}: UseVisibilityChangeOptions = {}): UseVisibilityChangeResult => {
  // supported browsers: https://developer.mozilla.org/en-US/docs/Web/API/Document/hidden#browser_compatibility
  const isSupported = document?.hidden !== undefined;
  const [isHidden, setIsHidden] = useState(false);
  const onVisibilityChange = useCallback(() => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Document/hidden
    if (document.hidden) {
      onHidden?.();
      setIsHidden(true);
    } else {
      onVisible?.();
      setIsHidden(false);
    }
  }, [onHidden, onVisible]);

  useEffect(() => {
    isSupported &&
      document.addEventListener("visibilitychange", onVisibilityChange);

    return () => {
      isSupported &&
        document.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, []);

  return { isHidden };
};

export default useVisibilityChange;
