import { SideNavigationProps } from "@amzn/awsui-components-react/polaris/side-navigation";

/**
 * Recursively finds greatest matched path. Determines what menu item is highlighted.
 * Polaris activeHref highlights exact match, but we want to highlight closest parent match
 * @param {SideNavigationProps.Item[]} navItems
 * @param {string} match
 * @return {string} Closest matched path
 */
const getActiveHref = (
  navItems: SideNavigationProps.Item[],
  match = "",
  customComparator?: (pathname: string, href: string) => boolean
) => {
  (navItems as SideNavigationProps.ExpandableLinkGroup[]).forEach((item) => {
    if (item.items?.length) {
      match = getActiveHref(
        item.items as SideNavigationProps.Item[],
        match,
        customComparator
      );
    }
    const href = (item as SideNavigationProps.ExpandableLinkGroup).href;
    if (
      href &&
      (customComparator
        ? customComparator(location.pathname, href)
        : location.pathname.startsWith(href)) &&
      href.length > match.length
    ) {
      match = href;
    }
  });
  return match;
};

export default getActiveHref;
