import Checkbox, {
  CheckboxProps,
} from "@amzn/awsui-components-react/polaris/checkbox";
import { NonCancelableCustomEvent } from "@amzn/awsui-components-react/polaris/internal/events/index";
import { useField } from "formik";
import React, { useCallback } from "react";

import { FormikFieldProps } from "./../FormControls.interface";
import memoizedFormikPropCheck from "./../FormControls.util";

export interface CheckboxFieldProps<S = any> {
  name: keyof S;
  checkboxProps: Omit<CheckboxProps, "checked" | "onBlur" | "onChange">;
}

const CheckboxField = <S, T extends boolean>({
  checkboxProps,
  field,
  helpers,
}: CheckboxFieldProps<S> & FormikFieldProps<T>): React.ReactElement => {
  const handleBlur = useCallback(() => {
    /**
     * @jcortezj 4/06/2022
     * Setting a timeout of 0 allows the next tab to focus before
     * helpers.setTouched gets called.
     *
     * Without the setTimeout, if you press tab in some context (e.g. in a modal) it does not
     * focus to the next form element correctly.
     *
     * Validated by @parkyh
     */
    setTimeout(() => {
      helpers.setTouched(true);
    }, 0);
  }, [helpers]);

  const onChange = useCallback(
    (e: NonCancelableCustomEvent<CheckboxProps.ChangeDetail>) => {
      helpers.setValue(e.detail.checked as T);
    },
    [helpers]
  );

  return (
    <Checkbox
      onBlur={handleBlur}
      onChange={onChange}
      checked={!!field.value}
      {...checkboxProps}
    />
  );
};

const MemoizedCheckboxField = React.memo(
  CheckboxField,
  (prevProps, nextProps) => {
    if (
      !memoizedFormikPropCheck(prevProps, nextProps) ||
      prevProps.checkboxProps.disabled !== nextProps.checkboxProps.disabled
    ) {
      return false;
    }

    return true;
  }
);

const CheckboxFieldContainer = <S,>(props: CheckboxFieldProps<S>) => {
  const [field, meta, helpers] = useField<boolean>(props.name as string);

  const optProps = {
    ...props,
    name: props.name as never,
  };

  return <MemoizedCheckboxField {...{ field, meta, helpers }} {...optProps} />;
};

export default CheckboxFieldContainer;
