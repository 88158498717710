import {
  ILog,
  ILogEntry,
  ILogFactory,
  ILogProvider,
  ILogSink,
  LogLevel,
  LogMetadata,
} from "./Logger.interface";
import { SinkType } from "./Sinks";
import ConsoleLogSink from "./Sinks/ConsoleLog";

class BaseLogger implements ILog {
  private enabled: boolean;
  constructor(
    private logName: string,
    private level: LogLevel,
    private logSinks: ILogSink[] = [],
    private loggerMetadata?: LogMetadata,
    private providers?: ILogProvider[],
    private getAvailableSinks?: () => ILogSink[],
    private requestedSinks?: SinkType[]
  ) {
    this.enabled = true;
    this.logSinks = logSinks || [];

    this.addAsynchronousSinks();
  }

  public clearSinks() {
    this.logSinks = [];
  }

  public addSink(logSink: ILogSink) {
    const sinkExists: ILogSink | undefined = this.logSinks.find((sink) => {
      return sink.getSinkName() === logSink.getSinkName();
    });

    // only add a sink if it has not been added before
    if (sinkExists) {
      return;
    }

    this.logSinks.push(logSink);
  }

  public getSinks() {
    return this.logSinks;
  }

  public getLoggerName(): string {
    return this.logName;
  }

  public isLoggingEnabled(level: LogLevel): boolean {
    return this.enabled && this.level <= level;
  }

  public disableLogger(): void {
    this.enabled = false;
  }

  public setLogLevel(logLevel: LogLevel): void {
    this.level = logLevel;
  }

  public getLogLevel(): LogLevel {
    return this.level;
  }

  public verbose(...msg: any[]) {
    if (!this.isLoggingEnabled(LogLevel.VERBOSE)) {
      return;
    }

    const logMessageEntry = this.createLogMessageEntry(LogLevel.VERBOSE, msg);
    this.writeEntry(logMessageEntry);
  }

  public debug(...msg: any[]) {
    if (!this.isLoggingEnabled(LogLevel.DEBUG)) {
      return;
    }

    const logMessageEntry = this.createLogMessageEntry(LogLevel.DEBUG, msg);
    this.writeEntry(logMessageEntry);
  }

  public info(...msg: any[]) {
    if (!this.isLoggingEnabled(LogLevel.INFO)) {
      return;
    }

    const logMessageEntry = this.createLogMessageEntry(LogLevel.INFO, msg);
    this.writeEntry(logMessageEntry);
  }

  public warn(...msg: any[]) {
    if (!this.isLoggingEnabled(LogLevel.WARNING)) {
      return;
    }

    const logMessageEntry = this.createLogMessageEntry(LogLevel.WARNING, msg);
    this.writeEntry(logMessageEntry);
  }

  public error(...msg: any[]) {
    if (!this.isLoggingEnabled(LogLevel.ERROR)) {
      return;
    }

    const logMessageEntry = this.createLogMessageEntry(LogLevel.ERROR, msg);
    this.writeEntry(logMessageEntry);
  }

  public critical(...msg: any[]) {
    if (!this.isLoggingEnabled(LogLevel.CRITICAL)) {
      return;
    }

    const logMessageEntry = this.createLogMessageEntry(LogLevel.CRITICAL, msg);
    this.writeEntry(logMessageEntry);
  }

  /**
   * Log sinks can be added at any point of the lifecycle of the application.
   *
   * For example, the CloudWatchLogSink is only added once the user
   * has successfully authenticated.
   *
   * Before sending a logMessageEntry, ensure all the sinks have been added
   * to the Logger before sending out the message. This ensures asynchronous
   * loggers do not miss out on a message.
   */
  private addAsynchronousSinks() {
    const requestedSinks = this.requestedSinks || [];

    if (this.logSinks.length < requestedSinks.length + 1) {
      const registeredSinks = this.getAvailableSinks?.() || [];

      const availableSinks: ILogSink[] = registeredSinks.filter((sink) => {
        const sinkWasFound = requestedSinks.find(
          (s) => s === sink.getSinkName()
        );

        return (
          sinkWasFound &&
          // do not add a sink that has already been added
          !this.logSinks.some((l) => l.getSinkName() === sinkWasFound)
        );
      });

      this.logSinks.push(...availableSinks);
    }
  }

  private createLogMessageEntry(level: LogLevel, msg: any[]): ILogEntry {
    this.addAsynchronousSinks();

    const providerMetadata: any[] | undefined = this.providers?.map(
      (provider) => {
        return {
          name: provider.getProviderName(),
          data: provider.getData(),
        };
      }
    );

    return {
      ts: new Date().getTime(),
      loggerName: this.logName,
      logLevel: level,
      msg,
      loggerMetadata: this.loggerMetadata,
      ...(providerMetadata && { providerMetadata }),
    };
  }

  private writeEntry(messageData: ILogEntry): void {
    this.logSinks.forEach((sink: ILogSink) => {
      sink.writeEntry(messageData);
    });
  }
}

class LogFactory implements ILogFactory {
  constructor(
    private level: LogLevel,
    private sinks: ILogSink[],
    private loggerMetadata?: LogMetadata,
    private providers?: ILogProvider[],
    private getAvailableSinks?: () => ILogSink[],
    private requestedSinks?: SinkType[]
  ) {}

  /**
   * LogFactory is responsible for creating Loggers using the BaseLogger
   * as its core
   */
  public getLogger(loggerName: string): ILog {
    return new BaseLogger(
      loggerName,
      this.level,
      this.sinks,
      this.loggerMetadata,
      this.providers,
      this.getAvailableSinks,
      this.requestedSinks
    );
  }
}

class Logger {
  private static logMetadata: LogMetadata | undefined = undefined;
  private static defaultSink: ILogSink = new ConsoleLogSink();
  private static availableSinks: ILogSink[] = [];
  private static providers: ILogProvider[] | undefined = undefined;

  public static addLogMetadata(logMetadata: LogMetadata): void {
    Logger.logMetadata = {
      ...(Logger.logMetadata || {}),
      ...logMetadata,
    };
  }

  public static addAvailableSink(sink: ILogSink): void {
    Logger.availableSinks.push(sink);
  }

  public static registerProvider(provider: ILogProvider) {
    if (!Logger.providers) {
      Logger.providers = [];
    }

    Logger.providers.push(provider);
  }

  public static getAvailableSinks(): ILogSink[] {
    return Logger.availableSinks;
  }

  /**
   * Utilizes LogFactory to create a Logger, given a loggerName and
   * an optional logLevel. if requestedSinks is provided, add them to the
   * BaseLogger.
   */
  public static getLogger(
    loggerName: string,
    logLevel?: LogLevel,
    requestedSinks?: SinkType[]
  ): ILog {
    const loggerLevel: LogLevel =
      logLevel === undefined ? LogLevel.INFO : logLevel;

    const loggerSinks: ILogSink[] = [this.defaultSink];

    return new LogFactory(
      loggerLevel,
      loggerSinks,
      Logger.logMetadata,
      Logger.providers,
      Logger.getAvailableSinks,
      requestedSinks
    ).getLogger(loggerName);
  }
}

export default Logger;
