import { Credentials } from "aws-sdk";
import { BaseSettings } from "clients/AppSettings";
import AWSCredentials from "lib/AWSCredentials";
import DeferredPromise from "lib/DeferredPromise";
import { ILog } from "lib/Logger";
import Logger from "lib/Logger/Logger";

import CloudWatchClient from "./../CloudWatchClient";
import CloudWatchLogsClient from "./../CloudWatchLogsClient/CloudWatchLogsClient";
import MetricsClient from "./../CloudWatchMetricClient/CloudWatchMetricClient";
import FirehoseClient from "./../FirehoseClient";
import S3Client from "./../S3Client";

interface Clients {
  cloudWatchLogsClient: CloudWatchLogsClient;
  cloudWatchClient: CloudWatchClient;
  metricsClient: MetricsClient;
  s3Client: S3Client;
}

interface UnauthenticatedClients {
  firehoseClient: FirehoseClient;
}

export interface Settings extends Omit<BaseSettings, "amplify"> {
  cloudWatchNamespace: string;
  cloudWatchLogGroup: string;
  cloudWatchLogStream: string;
  stage: string;
}

/**
 * ClientFactory is responsible for initializing all of the
 * clients by using the credentials generated from the
 * EEAuthProxy.
 *
 * Since checking whether a user is logged in or not is asynchronous,
 * create DeferredPromises and resolve them once the ClientFactory
 * has been initialized.
 */
class ClientFactory {
  private readonly logger = Logger.getLogger("ClientFactory");

  private _unauthenticatedClientFactoryLogger: ILog | undefined;
  private _cloudWatchLogsClient: DeferredPromise<CloudWatchLogsClient> = new DeferredPromise<CloudWatchLogsClient>();
  private _cloudWatchClient: DeferredPromise<CloudWatchClient> = new DeferredPromise<CloudWatchClient>();
  private _firehoseClient: DeferredPromise<FirehoseClient> = new DeferredPromise<FirehoseClient>();
  private _metricClient: DeferredPromise<MetricsClient> = new DeferredPromise<MetricsClient>();
  private _s3Client: DeferredPromise<S3Client> = new DeferredPromise<S3Client>();

  constructor() {
    Promise.all([
      this._cloudWatchLogsClient.promise,
      this._cloudWatchClient.promise,
      this._metricClient.promise,
      this._s3Client.promise,
    ]).then((_) => {
      this.logger.info("ClientFactory clients initialized");
    });

    Promise.all([this._firehoseClient.promise]).then((_) => {
      this._unauthenticatedClientFactoryLogger?.info(
        "UnauthenticatedClientFactory clients initialized"
      );
    });
  }

  public async initialize(
    credentials: AWSCredentials,
    {
      region,
      stage,
      cloudWatchNamespace,
      cloudWatchLogGroup,
      cloudWatchLogStream,
    }: Settings
  ): Promise<Clients> {
    const cloudWatchLogsClient: CloudWatchLogsClient = CloudWatchLogsClient.getInstance(
      credentials,
      region,
      this.logger,
      {
        cloudWatchNamespace,
        cloudWatchLogGroup,
        cloudWatchLogStream,
      }
    );
    this._cloudWatchLogsClient.resolve(cloudWatchLogsClient);

    const cloudWatchClient: CloudWatchClient = CloudWatchClient.getInstance(
      credentials,
      region,
      cloudWatchNamespace
    );
    this._cloudWatchClient.resolve(cloudWatchClient);

    const metricsClient: MetricsClient = MetricsClient.getInstance(
      cloudWatchClient,
      region,
      this.logger,
      { stage }
    );
    this._metricClient.resolve(metricsClient);

    const s3Client: S3Client = S3Client.getInstance(
      credentials,
      region,
      this.logger
    );
    this._s3Client.resolve(s3Client);

    return {
      cloudWatchLogsClient,
      cloudWatchClient,
      metricsClient,
      s3Client,
    };
  }

  public async getCloudWatchLogsClient(): Promise<CloudWatchLogsClient> {
    return this._cloudWatchLogsClient.promise;
  }
  public async getCloudWatchClient(): Promise<CloudWatchClient> {
    return this._cloudWatchClient.promise;
  }
  public async getMetricClient(): Promise<MetricsClient> {
    return this._metricClient.promise;
  }
  public async getS3Client(): Promise<S3Client> {
    return this._s3Client.promise;
  }
  public async getFirehouseClient(): Promise<FirehoseClient> {
    return this._firehoseClient.promise;
  }
  public async initializeUnauthenticatedClients(
    credentials: Credentials,
    region: string,
    logger: ILog
  ): Promise<UnauthenticatedClients> {
    this._unauthenticatedClientFactoryLogger = logger;

    const firehoseClient: FirehoseClient = FirehoseClient.getInstance(
      credentials,
      region,
      logger
    );
    this._firehoseClient.resolve(firehoseClient);

    return {
      firehoseClient,
    };
  }
}

export default new ClientFactory();
