/**
 * List of messages that are excluded. This filter prevents noisy logs and
 * metrics in our service by determining if they are un-actionable or benign errors.
 *
 * - Chunk errors are a result of NetworkErrors from a client and there is nothing
 *  actionable our team can do to resolve them. Apps, such as EEWebConsoleApp utilize
 *  retry logic to attempt to retry loading chunks.
 * - Script errors are errors that are received from browser extensions. They are
 *  out of our control since we cannot control what extensions users download.
 * - ResizeObserver is a benign error
 */

const exclusionList: RegExp[] = [
  /loading\s(CSS\s)?chunk\s(\d)+\sfailed/gim,
  /Script error./gim,
  /ResizeObserver loop completed with undelivered notifications./gim,
];

/**
 * Serialize object into a string and extract loggable fields.
 */
export const handleErrorObject = (
  _key: string,
  value: Error | ErrorEvent
): Object => {
  if (value instanceof Error) {
    const loggableFields = ["name", "number", "message", "description"];
    const errorObj = {};
    Object.getOwnPropertyNames(value).forEach(function (key) {
      if (loggableFields.indexOf(key.toLowerCase()) !== -1) {
        // @ts-ignore
        errorObj[key] = value[key];
      }
    });
    return errorObj;
  } else if (value instanceof ErrorEvent) {
    return {
      message: value?.message,
      lineno: value?.lineno,
      filename: value?.filename,
    };
  }

  return value;
};

const isObject = (s: unknown): s is Object => {
  return typeof s === "object" && s !== null;
};

/**
 * Given an error, determine if this is an excluded error that is un-actionable
 * or benign.
 */
const isErrorInExclusionList = (
  err: Error | Object | string | null
): boolean => {
  if (!err) {
    return true;
  }

  let errString: string = "";

  if (isObject(err)) {
    errString = JSON.stringify(err, handleErrorObject);
  } else {
    errString = err;
  }

  return exclusionList.some((exclusion) => {
    const isExcluded = exclusion.test(errString);
    /**
     * lastIndex needs to be reset to 0 after every search.
     */
    exclusion.lastIndex = 0;
    return isExcluded;
  });
};

export default isErrorInExclusionList;
