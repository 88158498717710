import Box from "@amzn/awsui-components-react/polaris/box";
import React from "react";

export interface EmptyMessageProps {
  title: string;
  description?: string;
  actions?: React.ReactNode;
}

const EmptyMessage = (props: EmptyMessageProps) => (
  <div data-testid="empty-message">
    <Box textAlign="center">
      <Box color="text-body-secondary" variant="strong" data-testid="title">
        {props.title}
      </Box>
      {props.description && (
        <Box color="text-body-secondary" variant="p" data-testid="description">
          {props.description}
        </Box>
      )}
      {props.actions && (
        <Box margin={{ top: "xs" }} data-testid="actions">
          {props.actions}
        </Box>
      )}
    </Box>
  </div>
);

export default EmptyMessage;
