import DatePicker, {
  DatePickerProps,
} from "@amzn/awsui-components-react/polaris/date-picker";
import { NonCancelableCustomEvent } from "@amzn/awsui-components-react/polaris/internal/events/index";
import { useField, useFormikContext } from "formik";
import React, { useCallback } from "react";

import { FormikFieldProps } from "./../FormControls.interface";
import memoizedFormikPropCheck, {
  isFieldInvalid,
} from "./../FormControls.util";

export interface DatePickerFieldProps<S> {
  name: keyof S;
  datePickerProps: Omit<DatePickerProps, "value" | "onBlur" | "onChange">;
  placeholder: string;
  didSubmit?: boolean;
}

const DatePickerField = <S, T extends string>({
  datePickerProps,
  placeholder,
  field,
  meta,
  helpers,
  didSubmit,
}: DatePickerFieldProps<S> & FormikFieldProps<T>) => {
  const handleBlur = useCallback(() => {
    /**
     * @jcortezj 1/14/2022
     * Setting a timeout of 0 allows the next tab to focus before
     * helpers.setTouched gets called.
     *
     * Without the setTimeout, if you press tab in some context (e.g. in a modal) it does not
     * focus to the next form element correctly.
     *
     * Validated by @parkyh
     */
    setTimeout(() => {
      helpers.setTouched(true);
    }, 0);
  }, [helpers]);

  const onChange = useCallback(
    (e: NonCancelableCustomEvent<DatePickerProps.ChangeDetail>) => {
      helpers.setValue(e.detail.value as T);
    },
    [helpers]
  );

  return (
    <DatePicker
      onBlur={handleBlur}
      onChange={onChange}
      value={field.value}
      invalid={isFieldInvalid<T>({ meta }, didSubmit)}
      placeholder={placeholder}
      {...datePickerProps}
    />
  );
};

const MemoizedDatePickerField = React.memo(
  DatePickerField,
  (prevProps, nextProps) => {
    if (
      !memoizedFormikPropCheck(prevProps, nextProps) ||
      prevProps.datePickerProps.disabled !== nextProps.datePickerProps.disabled
    ) {
      return false;
    }

    return true;
  }
);

const DatePickerFieldContainer = <S,>(props: DatePickerFieldProps<S>) => {
  const [field, meta, helpers] = useField<string>(props.name as string);
  const { submitCount } = useFormikContext();

  const optProps = {
    ...props,
    name: props.name as never,
    didSubmit: submitCount >= 0,
  };

  return (
    <MemoizedDatePickerField {...{ field, meta, helpers }} {...optProps} />
  );
};

export default DatePickerFieldContainer;
