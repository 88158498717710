import { AWSCShortbread } from "@amzn/shortbread";
import Logger from "lib/Logger/Logger";
import { StoragePartitionKeys } from "lib/StoragePartition/types";
import {
  CookieGetOptions,
  CookieSetOptions,
  default as UniversalCookie,
} from "universal-cookie";

import { CookieKeys } from "./types";

/**
 * 🍪 Cookie manager with Shortbread integration
 */
export default class Cookies {
  private static instance: Cookies;
  static getInstance() {
    if (!Cookies.instance) {
      Cookies.instance = new Cookies();
    }

    return Cookies.instance;
  }
  readonly MAX_AGE_DAYS = 360;
  readonly COOKIE_PATH = "/";
  readonly DOMAIN = `.${location.hostname}`;

  private readonly logger = Logger.getLogger("Cookies");
  private readonly cookieLib = new UniversalCookie();
  private readonly shortbread = AWSCShortbread({
    domain: `.${location.hostname}`,
    registry: {
      [CookieKeys.DARK_MODE]: {
        category: "functional",
      },
      [StoragePartitionKeys.eventParticipant]: {
        category: "essential",
      },
      [StoragePartitionKeys.tablePreferences]: {
        category: "functional",
      },
      [StoragePartitionKeys.tableSortColumns]: {
        category: "functional",
      },
      [StoragePartitionKeys.userPreferences]: {
        category: "functional",
      },
    },
    onConsentChanged: (c) => {
      this.logger.info("consent changed:", c);

      // we do not need to delete cookies on consent change,
      // keeping in case deletion requirement changes
      // see https://w.amazon.com/bin/view/Shortbread/adoption/faq/
      // if (!c.functional) {
      //     this.removeAll();
      //     this.logger.info('opted out of functional cookies, cleared cookies and storage');
      // }
    },
  });

  private constructor() {}

  get = <T = any>(name: string, options: CookieGetOptions = {}) =>
    this.cookieLib.get<T>(name, options);

  set = (
    name: CookieKeys,
    value: any,
    {
      path = this.COOKIE_PATH,
      maxAge = this.MAX_AGE_DAYS * 24 * 60 * 60,
      ...rest
    }: CookieSetOptions = {}
  ) => {
    if (!this.hasConsent(name)) {
      this.logger.info(
        `user did not provide consent, "${name}" cookie will not be set`
      );
      return;
    }

    this.cookieLib.set(name, value, {
      path,
      maxAge,
      ...rest,
    });
  };

  remove = (
    name: string,
    { path = this.COOKIE_PATH, ...rest }: CookieSetOptions = {}
  ) => {
    this.cookieLib.remove(name, {
      path,
      ...rest,
    });
  };

  removeAll = ({ path = this.COOKIE_PATH, ...rest }: CookieSetOptions = {}) => {
    Object.values(CookieKeys).forEach((cookieKey) => {
      this.remove(cookieKey, {
        path,
        ...rest,
      });
    });
  };

  customize = () => {
    this.shortbread.customizeCookies();
  };

  checkForConsent = () => {
    this.shortbread.checkForCookieConsent();
  };

  hasConsent = (name: string) => {
    try {
      return this.shortbread.hasConsent(name);
    } catch {
      this.logger.error(
        `Cookie or storage key "${name}" not in registry. This key must be added to the AWSCShortbread registry in order check for consent.`
      );
    }
  };

  getConsent = () => this.shortbread.getConsentCookie();
}
