import { Amplify, Auth } from "aws-amplify";
import ApiClient from "lib/ApiClient";
import DeferredPromise from "lib/DeferredPromise";

import { BaseSettings, SettingsFileName, ValidationError } from "./types";
import processData from "./utils/process-data";

class AppSettings {
  private static instance?: AppSettings;
  static validate<S extends BaseSettings = BaseSettings>(
    settings: S,
    requiredKeys: (keyof S)[]
  ) {
    if (
      requiredKeys.every((key) =>
        Object.prototype.hasOwnProperty.call(settings, key)
      )
    ) {
      return true;
    }

    throw new Error(ValidationError.MISSING_REQUIRED_KEYS);
  }
  static getInstance(
    isProd = process.env.NODE_ENV === "production"
  ): AppSettings {
    if (!AppSettings.instance) {
      AppSettings.instance = new AppSettings(isProd);
    }

    return AppSettings.instance;
  }

  private deferredAppSettingsPromise: DeferredPromise<any> | undefined;
  private readonly apiClient = ApiClient.createGenericClient({
    clientName: "AppSettingsClient",
    baseUrl: "",
  });
  private readonly isProd;

  private constructor(isProd: boolean) {
    this.isProd = isProd;

    Amplify.configure({
      cookieStorage: {
        domain: window.location.hostname,
        path: "/",
        secure: true,
      },
    });
  }

  async get<S extends BaseSettings = BaseSettings>(): Promise<S> {
    if (!this.deferredAppSettingsPromise) {
      this.deferredAppSettingsPromise = new DeferredPromise<S>();
    } else {
      return this.deferredAppSettingsPromise.promise;
    }

    const fileName = this.isProd ? SettingsFileName.PROD : SettingsFileName.DEV;

    try {
      const settings = await this.apiClient
        .get<S>(`/${fileName}?t=${Date.now()}`)
        .then(({ data }) => processData<S>(data));

      this.deferredAppSettingsPromise.resolve(settings);
    } catch (e) {
      this.deferredAppSettingsPromise.reject(ValidationError.GET_ERROR);
    }

    return this.deferredAppSettingsPromise.promise;
  }

  configureAuth = async () => {
    try {
      const settings = await AppSettings.getInstance().get();

      Auth.configure({
        ...settings.amplify,
      });
    } catch {
      throw ValidationError.SETUP_AUTH_FAILED;
    }
  };

  static __removeInstance__() {
    if (process.env.NODE_ENV !== "test") {
      return;
    }

    AppSettings.instance = undefined;
  }
}

export default AppSettings;
