import axios, {
  AxiosInstance as ClientInstance,
  AxiosRequestConfig as RequestConfig,
} from "axios";

const getClient = (baseURL: string, options: RequestConfig): ClientInstance => {
  const axiosOptions = {
    baseURL,
    ...options,
  };

  return axios.create(axiosOptions);
};

export { ClientInstance, RequestConfig };

export default getClient;
