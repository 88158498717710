import { LocalStorageProvider } from "lib";

/**
 * useLocalStorage is a hook that wraps the LocalStorageProvider
 * and exports out functions to interface with local storage.
 */
const useLocalStorage = () => {
  return {
    getItem: LocalStorageProvider.getItem,
    setItem: LocalStorageProvider.setItem,
    removeItem: LocalStorageProvider.removeItem,
    clear: LocalStorageProvider.clear,
  };
};
export default useLocalStorage;
