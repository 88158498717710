import {
  ACCOUNT_SOURCE_ENABLED_FIELD_MAP,
  DISCRETE_UPDATE_APIS,
  EVENT_STATE_ENABLED_FIELD_MAP,
  EVENT_STATE_ORDER,
} from "./constants";
import {
  EventAccountSource,
  EventField,
  EventMatrixOptions,
  EventState,
  EventStateInfrastructure,
  EventStateRuntime,
  EventStateTypeOptions,
  EventStateTypes,
  EventType,
} from "./types";

class EventMatrix {
  /**
   * Checks if event provisioning was started at least once.
   *
   * @param {EventStateTypeOptions} state
   * @returns {boolean}
   */
  static isProvisionStarted(state: EventStateTypeOptions) {
    // We need to check runtime state as well in case
    // the infrastructure step is not applicable (customer provided)
    return (
      EventMatrix.compareState(state.runtime, "not_started", "runtime") >= 0 &&
      (state.infrastructure === "not_applicable" ||
        EventMatrix.compareState(
          state.infrastructure,
          "provision_in_progress",
          "infrastructure"
        ) >= 0)
    );
  }

  /**
   * Checks if an event is currently deploying.
   * An event is deploying if it has started provisioning
   * and has not finished deploying. An event can transition
   * to a deploying state while the event is in progress.
   *
   * @param {EventStateTypeOptions} state
   * @returns {boolean}
   */
  static isDeploymentInProgress(state: EventStateTypeOptions) {
    return [
      "provision_in_progress",
      "deployment_central_in_progress",
      "deployment_in_progress",
    ].includes(state.infrastructure);
  }

  /**
   * Checks if deployment was successful.
   * Deployment is considered successful for events that
   * do not have any associated infrastructure.
   *
   * @param {EventStateTypeOptions} state
   * @returns {boolean}
   */
  static isDeploymentSuccess(state: EventStateTypeOptions) {
    return ["not_applicable", "deployment_success"].includes(
      state.infrastructure
    );
  }

  /**
   * Checks if the deployment step resolved with a successful or failed state.
   *
   * @param {EventStateTypeOptions} state
   * @returns {boolean}
   */
  static isDeploymentResolved(state: EventStateTypeOptions) {
    return (
      EventMatrix.isDeploymentSuccess(state) ||
      [
        "provision_failed",
        "deployment_central_failed",
        "deployment_halted",
        "deployment_failed",
      ].includes(state.infrastructure)
    );
  }

  /**
   * Checks if the event is ready to start.
   * Applicable only when the event is not started.
   *
   * @param {EventStateTypeOptions} state
   * @returns {boolean}
   */
  static isEventReadyToStart(state: EventStateTypeOptions) {
    return (
      state.runtime === "not_started" &&
      (state.infrastructure === "not_applicable" ||
        EventMatrix.compareState(
          state.infrastructure,
          "deployment_in_progress",
          "infrastructure"
        ) >= 0)
    );
  }

  /**
   * Checks if the event was started
   *
   * @param {EventStateTypeOptions} state
   * @returns {boolean}
   */
  static isEventStarted(state: EventStateTypeOptions) {
    return (
      EventMatrix.compareState(state.runtime, "start_in_progress", "runtime") >=
      0
    );
  }

  /**
   * Checks if the event was started successfully
   *
   * @param {EventStateTypeOptions} state
   * @returns {boolean}
   */
  static isEventStartedSuccess(state: EventStateTypeOptions) {
    return (
      EventMatrix.compareState(state.runtime, "start_success", "runtime") >= 0
    );
  }

  /**
   * Checks if the event is in progress
   *
   * @param {EventStateTypeOptions} state
   * @returns {boolean}
   */
  static isEventInProgress(state: EventStateTypeOptions) {
    return (
      EventMatrix.isEventStartedSuccess(state) &&
      EventMatrix.compareState(
        state.runtime,
        "terminate_in_progress",
        "runtime"
      ) < 0
    );
  }

  /**
   * Checks if the event was terminated successfully
   *
   * @param {EventStateTypeOptions} state
   * @returns {boolean}
   */
  static isEventTerminated(state: EventStateTypeOptions) {
    return (
      EventMatrix.compareState(state.runtime, "terminate_success", "runtime") >=
      0
    );
  }

  /**
   * Checks if an event is active.
   * An active event is one that is not canceled or terminated.
   *
   * @param {EventStateTypeOptions} state
   * @returns {boolean}
   */
  static isEventActive(state: EventStateTypeOptions) {
    return (
      !EventMatrix.isEventTerminated(state) && state.runtime !== "canceled"
    );
  }

  /**
   * Checks if the event state is transitory.
   * A transitory state is one that may transition automatically after
   * some time due to long-running async workflows. A typical use case
   * for this value is to start/stop data pollers.
   *
   * @param {EventStateTypeOptions} state
   * @returns {boolean}
   */
  static isTransitoryState(state: EventStateTypeOptions) {
    return (
      EventMatrix.isProvisionStarted(state) &&
      !EventMatrix.isEventTerminated(state)
    );
  }

  /**
   * Checks if a field has a discrete update API (e.g. updateRegions).
   *
   * @param {EventField} field
   * @returns {boolean}
   */
  static hasDiscreteUpdateApi(field: EventField) {
    return DISCRETE_UPDATE_APIS.includes(field);
  }

  /**
   * Checks if a state is before/same/after a target state:
   * 0 means state a is the same as state b
   * -1 means state a is before state b
   * 1 means state a is after state b
   *
   * @param {EventState} a
   * @param {EventState} b
   * @param {EventStateTypes} type
   * @returns {number}
   */
  static compareState(
    a: EventStateRuntime,
    b: EventStateRuntime,
    type: "runtime"
  ): number;
  static compareState(
    a: EventStateInfrastructure,
    b: EventStateInfrastructure,
    type: "infrastructure"
  ): number;
  static compareState(
    a: EventState,
    b: EventState,
    type: EventStateTypes = "runtime"
  ) {
    if (a === b) {
      return 0;
    }

    const states = EVENT_STATE_ORDER[type];
    const aIndex = states.findIndex((s: EventState) => a === s);
    const bIndex = states.findIndex((s: EventState) => b === s);

    return aIndex > bIndex ? 1 : -1;
  }

  constructor(
    private eventType: EventType,
    private eventAccountSource: EventAccountSource,
    private eventState?: EventStateTypeOptions,
    private options?: EventMatrixOptions
  ) {}

  get logger() {
    return this.options?.logger;
  }

  get accountSource() {
    return this.eventAccountSource;
  }

  set accountSource(eventAccountSource: EventAccountSource) {
    this.eventAccountSource = eventAccountSource;
  }

  get type() {
    return this.eventType;
  }

  set type(eventType: EventType) {
    this.eventType = eventType;
  }

  get state() {
    return this.eventState;
  }

  set state(eventState: EventStateTypeOptions | undefined) {
    this.eventState = eventState;
  }

  compareState(state: EventStateRuntime, type: "runtime"): number;
  compareState(state: EventStateInfrastructure, type: "infrastructure"): number;
  compareState(state: EventState, type: EventStateTypes = "runtime") {
    if (!this.state) {
      return -1;
    }

    // @ts-ignore: Should keep EventMatrix.compareState overrides as strict as possible.
    //  We can resolve the TS error by adding a generic override to the static method, but
    //  that will remove strict type checking for type-based (runtime/infrastructure) event
    //  state arguments.
    return EventMatrix.compareState(this.state[type], state, type);
  }

  /**
   * Checks if field is enabled for the current account source and event type
   *
   * @param {EventField} field
   * @returns {boolean}
   */
  isFieldEnabled(field: EventField) {
    const accountSourceMatrix =
      ACCOUNT_SOURCE_ENABLED_FIELD_MAP[this.accountSource];

    if (!accountSourceMatrix) {
      this.logger?.critical(
        `Unknown account source "${this.accountSource}". Failed to determine enabled state for field "${field}".`
      );
      return false;
    }

    const eventTypeMatrix = accountSourceMatrix[this.type];

    if (eventTypeMatrix === null) {
      return true;
    }

    if (!eventTypeMatrix) {
      this.logger?.critical(
        `Unknown type "${this.type}". Failed to determine enabled state for field "${field}".`
      );
      return false;
    }

    return eventTypeMatrix?.includes(field);
  }

  /**
   * Checks if field is enabled based on current event state
   *
   * @param {EventField} field
   * @returns {boolean}
   */
  isFieldEditable(field: EventField) {
    if (!this.state) {
      this.logger?.info("No event state value");
      return false;
    }

    const fieldMatrix = EVENT_STATE_ENABLED_FIELD_MAP[field];

    if (fieldMatrix === null) {
      return true;
    }

    if (!fieldMatrix) {
      this.logger?.critical(
        `Unknown field "${field}". Failed to determine editable state.`
      );
      return false;
    }

    return (
      (fieldMatrix.runtime === null ||
        fieldMatrix.runtime.includes(this.state.runtime)) &&
      (fieldMatrix.infrastructure === null ||
        fieldMatrix.infrastructure.includes(this.state.infrastructure))
    );
  }
}

export default EventMatrix;
