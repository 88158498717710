import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import ValueWithLabel from "components/ValueWithLabel";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";

import { AWS_LOGOUT_URL, i18nStrings } from "./AWSConsoleLogin.constants";
import { AWSConsoleLoginProps } from "./AWSConsoleLogin.interface";
import css from "./AWSConsoleLogin.module.scss";

const AWSConsoleLogin: FC<AWSConsoleLoginProps> = ({
  delay = 0,
  details,
  header = i18nStrings.header,
  onLogin,
  url,
}) => {
  const timeoutHandle = useRef<number>();
  const [loggedOut, setLoggedOut] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);
  const onFrameLoad = useCallback(() => {
    setLoggedOut(true);
  }, [setLoggedOut]);

  useEffect(() => {
    return () => {
      window.clearTimeout(timeoutHandle.current);
    };
  });

  useEffect(() => {
    if (!loggedOut) {
      return;
    }

    window.clearTimeout(timeoutHandle.current);
    timeoutHandle.current = window.setTimeout(() => setLoggingIn(true), delay);
  }, [loggedOut, delay]);

  useEffect(() => {
    if (!loggingIn) {
      return;
    }

    onLogin && onLogin(url);
    if (url) {
      window.location.href = url;
    }
  }, [loggingIn, url, onLogin]);

  return (
    <>
      <Container header={<Header variant="h2">{header}</Header>}>
        <ColumnLayout columns={4} variant="text-grid">
          <ValueWithLabel label={i18nStrings.valueLabelStatus}>
            <StatusIndicator type="loading">
              {i18nStrings.loggingIn}
            </StatusIndicator>
          </ValueWithLabel>
          {details?.account ? (
            <ValueWithLabel label={i18nStrings.valueLabelAccountNumber}>
              {details.account}
            </ValueWithLabel>
          ) : null}
          {details?.role ? (
            <ValueWithLabel label={i18nStrings.valueLabelIAMRole}>
              {details.role}
            </ValueWithLabel>
          ) : null}
          {details?.destination ? (
            <ValueWithLabel label={i18nStrings.valueLabelDestination}>
              {details.destination}
            </ValueWithLabel>
          ) : null}
        </ColumnLayout>
      </Container>
      <iframe
        spellCheck={false}
        className={css.iframe}
        src={AWS_LOGOUT_URL}
        onLoad={onFrameLoad}
      />
    </>
  );
};

export { AWSConsoleLoginProps } from "./AWSConsoleLogin.interface";
export default AWSConsoleLogin;
