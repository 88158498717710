export interface IDatabase<T> {
  getAll: () => Record<string, T>;
  create: (key: string, value: T) => T;
  get: (key: string) => T | null;
  update: (key: string, updatedValue: T) => T;
  delete: (key: string) => boolean;
}

export abstract class AbstractDatabase<T> implements IDatabase<T> {
  public abstract getAll(): Record<string, T>;
  public abstract create(key: string, value: T): T;
  public abstract get(key: string): T | null;
  public abstract update(key: string, updatedValue: T): T;
  public abstract delete(key: string): boolean;
}

export interface DatabaseExternalInterface<T> {
  initialDb?: Record<string, T>;
}
