import Input, { InputProps } from "@amzn/awsui-components-react/polaris/input";
import { NonCancelableCustomEvent } from "@amzn/awsui-components-react/polaris/internal/events/index";
import { useField, useFormikContext } from "formik";
import React, { useCallback } from "react";

import { FormikFieldProps } from "./../FormControls.interface";
import memoizedFormikPropCheck, {
  isFieldInvalid,
} from "./../FormControls.util";

export interface InputFieldProps<S = any> {
  name: keyof S;
  inputProps: Omit<InputProps, "value" | "onBlur" | "onChange">;
  placeholder: string;
  didSubmit?: boolean;
}

const InputField = <S, T extends string>({
  inputProps,
  placeholder,
  field,
  meta,
  helpers,
  didSubmit,
}: InputFieldProps<S> & FormikFieldProps<T>): React.ReactElement => {
  const handleBlur = useCallback(() => {
    /**
     * @jcortezj 1/14/2022
     * Setting a timeout of 0 allows the next tab to focus before
     * helpers.setTouched gets called.
     *
     * Without the setTimeout, if you press tab in some context (e.g. in a modal) it does not
     * focus to the next form element correctly.
     *
     * Validated by @parkyh
     */
    setTimeout(() => {
      helpers.setTouched(true);
    }, 0);
  }, [helpers]);

  const onChange = useCallback(
    (e: NonCancelableCustomEvent<InputProps.ChangeDetail>) => {
      helpers.setValue(e.detail.value as T);
    },
    [helpers]
  );

  // Formik forces us to provide an initial value. If the initial
  // value is Number.MIN_VALUE, do not provide a pre-supplied number
  const fieldValue: string =
    +field.value == Number.MIN_VALUE ? "" : (field.value as string);

  return (
    <Input
      onBlur={handleBlur}
      onChange={onChange}
      value={fieldValue as string}
      invalid={isFieldInvalid<T>({ meta }, didSubmit)}
      placeholder={placeholder}
      {...inputProps}
    />
  );
};

const MemoizedInputField = React.memo(InputField, (prevProps, nextProps) => {
  if (
    !memoizedFormikPropCheck(prevProps, nextProps) ||
    prevProps.inputProps.disabled !== nextProps.inputProps.disabled
  ) {
    return false;
  }

  return true;
});

const InputFieldContainer = <S,>(props: InputFieldProps<S>) => {
  const [field, meta, helpers] = useField<string>(props.name as string);
  const { submitCount } = useFormikContext();

  const optProps = {
    ...props,
    name: props.name as never,
    didSubmit: submitCount >= 0,
  };

  return <MemoizedInputField {...{ field, meta, helpers }} {...optProps} />;
};

export default InputFieldContainer;
