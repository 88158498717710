import { applyMode, Mode } from "@amzn/awsui-global-styles";
import Cookies, { CookieKeys } from "lib/cookies";
import React, {
  createContext,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from "react";

interface DarkModeContextProps {
  enabled: boolean;
  setEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DarkModeContext = createContext<DarkModeContextProps>(
  {} as DarkModeContextProps
);

const cookies = Cookies.getInstance();
const DarkModeProvider = (props: PropsWithChildren<ReactNode>) => {
  const [enabled, setEnabled] = useState<boolean>(
    cookies?.get(CookieKeys.DARK_MODE) === "true"
  );

  useEffect(() => {
    applyMode(enabled ? Mode.Dark : Mode.Light);
    cookies?.set(CookieKeys.DARK_MODE, enabled);
  }, [enabled]);

  return (
    <DarkModeContext.Provider value={{ enabled, setEnabled }}>
      {props.children}
    </DarkModeContext.Provider>
  );
};

export default DarkModeProvider;
