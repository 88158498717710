export const i18nStrings = {
  topNavigation: {
    searchIconAriaLabel: "Search",
    searchDismissIconAriaLabel: "Close search",
    overflowMenuTriggerText: "More",
    overflowMenuTitleText: "Menu",
  },
  darkMode: "Dark mode 🌙",
  lightMode: "Light mode ☀️",
  logoAlt: "Workshop Studio",
  utilities: {
    settings: "Settings",
  },
  badges: {
    publicPreview: "Public preview",
  },
  popovers: {
    publicPreview: {
      content: "Event delivery feature is in public preview.",
      readMoreLink: "Read more",
    },
  },
};

export enum ItemIds {
  DarkMode = "system-dark-mode-toggle",
}
