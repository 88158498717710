import Box from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import React, { useCallback, useState } from "react";

export type AuthTokenExceptions = "NetworkError" | "AmazonCorporateSSOToken";

interface AuthTokenExceptionModalProps {
  type: AuthTokenExceptions;
  onDismiss?: () => void;
}

export const AuthTokenExceptionCopyText: Record<
  AuthTokenExceptions,
  { header: string; body: string }
> = {
  NetworkError: {
    header: "A Network Change was Detected",
    body: "Check your network connection and refresh the page.",
  },
  AmazonCorporateSSOToken: {
    header: "Amazon Corporate SSO token expired",
    body:
      "Your Amazon Corporate SSO token token has expired. Please refresh the page and re-authenticate to use the console application.",
  },
};

const AuthTokenExceptionModal = ({
  type,
  onDismiss,
}: AuthTokenExceptionModalProps) => {
  const [visible, setVisible] = useState(true);

  const onRefresh = useCallback(() => {
    window.location.reload();
  }, []);

  const { header, body } = AuthTokenExceptionCopyText[type];

  return (
    <Modal
      onDismiss={() => {
        onDismiss?.();
        setVisible(false);
      }}
      visible={visible}
      closeAriaLabel="Close modal"
      size="medium"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={onRefresh} variant="primary">
              Refresh
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={header}
    >
      {body}
    </Modal>
  );
};

export default AuthTokenExceptionModal;
