import DataChunk from "lib/DataChunk";
import PollEngine from "lib/PollEngine";

export interface AbstractClient {
  pollMs: number;
  pollEngineName: string;
  maxErrorCountThreshold?: number;
  chunkSize?: number;
}

/**
 * Base class used if a client queues up data and outputs in a
 * specified interval.
 *
 * S generic parameter denotes the data being stored in the data chunk
 * T generic parameter denotes the response of sending queued commands to a client
 */
export default abstract class AbstractClientAggregator<S = any, T = any> {
  protected readonly pollMs: number;
  protected readonly maxErrorCountThreshold: number;
  protected dataChunk: DataChunk<S>;
  protected errCount: number = 0;
  protected pollEngine: PollEngine;
  constructor({
    pollMs,
    pollEngineName,
    maxErrorCountThreshold = 5,
    chunkSize = 20,
  }: AbstractClient) {
    this.pollEngine = new PollEngine({
      pollMs,
      pollEngineName,
      startImmediate: false,
    });

    this.pollMs = pollMs;
    this.maxErrorCountThreshold = maxErrorCountThreshold;

    this.dataChunk = new DataChunk(chunkSize);
  }

  public getPollMs(): number {
    return this.pollMs;
  }

  /**
   * If the number of errors exceeds this.maxErrorCountThreshold,
   * stop PollEngine from pollling.
   *
   * @returns {boolean} if true, stop polling
   */
  protected trackError(): boolean {
    this.errCount = this.errCount + 1;

    const shouldStop = this.errCount >= this.maxErrorCountThreshold;

    if (shouldStop) {
      this.pollEngine.stopPolling();
    }

    return shouldStop;
  }

  /**
   * Each inherited class must contain a flush method that
   * immediately flushes out queued up logs
   */
  abstract flush(): void;

  /**
   * Sends any queued up commands and sends them to
   * the appropriate client. Used in conjunction with
   * flush to send data to appropriate stream.
   */
  protected abstract sendQueuedCommands(): Promise<T | void>;
}
