export enum SettingsFileName {
  DEV = "settings.local.json",
  PROD = "settings.json",
}

export enum ValidationError {
  MISSING_REQUIRED_KEYS = "Missing one or more required keys",
  GET_ERROR = "Failed to get settings",
  SETUP_AUTH_FAILED = "Failed to setup auth. No settings data available.",
}

export interface BaseSettings extends Object {
  amplify: AmplifySettings;
  region: string;
}

export interface AmplifySettings {
  oauth: AmplifyAuthOptions;
}

export interface AmplifyAuthOptions {
  redirectSignIn: string;
  redirectSignOut: string;
  redirectSignInPath?: string;
  redirectSignOutPath?: string;
}
