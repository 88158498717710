import { useEffect, useRef } from "react";

const useIsMounted = () => {
  const ref = useRef(false);

  useEffect(() => {
    ref.current = true;

    return () => {
      ref.current = false;
    };
  }, []);

  return {
    // We need to return the ref object here in case the value is used inside of a closure.
    // Passing just ref.current may not give the latest value (e.g. within timeout)
    isMounted: ref,
  };
};

export default useIsMounted;
