import { PropertyFilterProps } from "@amzn/awsui-components-react/polaris";

import { ExtendedFilteringProperty } from "./types";

const _pushFilteringOption = (
  option: string,
  key: string,
  options: PropertyFilterProps.FilteringOption[],
  optionSets: Record<string, Set<string>>
) => {
  optionSets[key] = optionSets[key] || new Set();
  if (optionSets[key].has(option)) {
    return;
  }
  optionSets[key].add(option);
  options.push({
    propertyKey: key,
    value: String(option),
  });
};

/**
 * Creates a list of Polaris filtering options with
 * the provided data and filtering properties. Filtering
 * options will also be generated if showOptions is true.
 * @param {Item[]} items
 * @param filteringProperties
 * @returns {PropertyFilterProps.FilteringOption[]} A list of Polaris filtering options
 */
const getFilteringOptions = <Item extends object>(
  items: Item[],
  filteringProperties: ExtendedFilteringProperty[]
): PropertyFilterProps.FilteringOption[] => {
  const optionSets: Record<string, Set<string>> = {};
  const options: PropertyFilterProps.FilteringOption[] = [];

  items.forEach((item: Record<string, any>) => {
    filteringProperties.forEach((property) => {
      if (!property.key || !property.showOptions) {
        return;
      }
      const propertyOptions = item[property.key];
      if (Array.isArray(propertyOptions)) {
        propertyOptions.forEach((option) => {
          _pushFilteringOption(option, property.key, options, optionSets);
        });
      } else if (propertyOptions) {
        _pushFilteringOption(
          propertyOptions,
          property.key,
          options,
          optionSets
        );
      }
    });
  });

  return options;
};

export default getFilteringOptions;
