import Form from "@amzn/awsui-components-react/polaris/form";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import FormikField from "components/FormControls/FormikField";
import InputField from "components/FormControls/InputField";
import TextAreaField from "components/FormControls/TextAreaField";
import { FormikProvider, useFormik } from "formik";
import useLogger from "hooks/use-logger";
import useMetric from "hooks/use-metric";
import useModal from "hooks/use-modal";
import WorkshopContentSIMTemplateBuilder, {
  WORKSHOP_SIM_BUILDER_TEXT,
} from "lib/SIMTemplateBuilder/WorkshopContentSIMTemplateBuilder";
import React, { FC, useCallback, useEffect, useState } from "react";
import Constants from "utils/constants";
import formatString from "utils/format-string";

import FormSchema, {
  FormSchemaInterface,
  FormSchemaType,
} from "./ContentIssueModalForm.validation";

const COPY_TEXT = {
  ...WORKSHOP_SIM_BUILDER_TEXT,
  primaryActionText: "Open in SIM",
  secondaryActionText: "Cancel",
  optionalText: "optional",
  maxUrlLengthErr:
    "The issue has exceeded a max length. Please limit your responses from {0} to {1} characters. You will be able to modify the issue prior to final submission.",
};

export const FORM_ID = {
  WORKSHOP_PAGE: "workshopPage",
  ISSUE: "issue",
  PROPOSED_SOLUTION: "proposedSolution",
  OPEN_IN_SIM_BUTTON: "openInSimButton",
};

export interface ContentIssueModalProps {
  isVisible: boolean;
  onModalClosed: () => void;
  /**
   * The consuming application should have all of these
   * values to feed into the Modal
   */
  providedFormValues: {
    assignee: FormSchemaInterface["assignee"];
    contentId: FormSchemaInterface["contentId"];
    impactLevel: FormSchemaInterface["impactLevel"];
    workshopTitle: FormSchemaInterface["workshopTitle"];
    quickLinkId?: string;
  };
  onComplete?: (issue: string) => void;
}

const initialIssueTemplateInvalidState = {
  invalid: false,
  issueLength: -1,
  maxLength: -1,
};

const ContentIssueModal: FC<ContentIssueModalProps> = ({
  isVisible,
  onModalClosed,
  providedFormValues,
  onComplete,
}: ContentIssueModalProps) => {
  const [logger] = useLogger("ContentIssueModal");
  const { addCounterMetric } = useMetric();
  const [issueTemplateInvalid, setIssueTemplateInvalid] = useState<{
    invalid: boolean;
    issueLength: number;
    maxLength: number;
  }>(initialIssueTemplateInvalidState);
  const {
    assignee,
    contentId,
    impactLevel,
    workshopTitle,
    quickLinkId,
  } = providedFormValues;
  const [validateOnChange, setValidateOnChange] = useState(false);

  const onSubmit = async (values: FormSchemaType) => {
    const workshopContentSimTemplateBuilder = new WorkshopContentSIMTemplateBuilder();

    const issue = workshopContentSimTemplateBuilder
      .addTitle(formatString(COPY_TEXT.contentIssueTitle, workshopTitle))
      .addDescription(
        workshopContentSimTemplateBuilder.formatDescription({
          pageOfWorkshop: values.workshopPage,
          issue: values.issue,
          proposedSolution: values.proposedSolution
            ? values.proposedSolution
            : "",
        })
      )
      .addAssignedUser(values.assignee)
      .addImpactLevel(values.impactLevel)
      .addCustomField({
        id: "contentid",
        type: "string",
        value: values.contentId,
      })
      .createIssueTemplate();

    const isIssueTemplateLengthValid = workshopContentSimTemplateBuilder.validateIssueTemplateLength(
      issue
    );

    if (!isIssueTemplateLengthValid) {
      setIssueTemplateInvalid({
        invalid: true,
        issueLength: issue.length,
        maxLength: workshopContentSimTemplateBuilder.getMaxUrlLength(),
      });
      return;
    } else if (issueTemplateInvalid.invalid) {
      setIssueTemplateInvalid(initialIssueTemplateInvalidState);
    }

    addCounterMetric(Constants.CloudWatchMetric.CONTENT_ISSUE_TEMPLATE_CREATED);
    logger.info("Opening issue in a new tab", issue);
    workshopContentSimTemplateBuilder.open(issue);
    onComplete?.(issue);
  };

  const formik = useFormik({
    validationSchema: FormSchema,
    validateOnChange,
    validateOnBlur: false,
    initialValues: {
      workshopPage: "",
      issue: "",
      proposedSolution: "",
      workshopTitle,
      impactLevel,
      assignee,
      contentId,
      quickLinkId,
    },
    onSubmit,
  });

  const closeModal = () => {
    hideModal();
    onModalClosed();
  };

  const onPrimaryActionClick = useCallback(() => {
    formik.handleSubmit();
  }, []);

  const onSecondaryActionClick = useCallback(() => {
    closeModal();
  }, []);

  useEffect(() => {
    if (formik.submitCount === 1) {
      setValidateOnChange(true);
    }
  }, [formik.submitCount]);

  useEffect(() => {
    if (!isVisible) {
      formik.resetForm();
    }
  }, [isVisible]);

  const { modalComponent, hideModal } = useModal({
    isVisible,
    onDismiss: onModalClosed,
    modalHeader: formatString(COPY_TEXT.contentIssueTitle, workshopTitle),
    actions: {
      primary: {
        text: COPY_TEXT.primaryActionText,
        onClick: onPrimaryActionClick,
        disabled: !formik.dirty || !formik.isValid,
        iconAlign: "right",
        iconName: "external",
      },
      tertiary: {
        text: COPY_TEXT.secondaryActionText,
        onClick: onSecondaryActionClick,
      },
    },
    content: (
      <FormikProvider value={formik}>
        <Form
          errorText={
            issueTemplateInvalid.invalid
              ? formatString(
                  COPY_TEXT.maxUrlLengthErr,
                  issueTemplateInvalid.issueLength,
                  issueTemplateInvalid.maxLength
                )
              : ""
          }
        >
          <SpaceBetween size="l">
            <FormikField<FormSchemaType>
              name="workshopPage"
              dataTestId={FORM_ID.WORKSHOP_PAGE}
              formFieldProps={{
                label: COPY_TEXT.linkPromptText,
                stretch: true,
                children: (
                  <InputField<FormSchemaType>
                    name="workshopPage"
                    placeholder="Published content or build preview URL"
                    inputProps={{
                      type: "text",
                    }}
                  />
                ),
              }}
            />

            <FormikField<FormSchemaType>
              name="issue"
              dataTestId={FORM_ID.ISSUE}
              formFieldProps={{
                label: COPY_TEXT.issuePromptText,
                stretch: true,
                children: (
                  <TextAreaField<FormSchemaType>
                    name="issue"
                    placeholder="Issue details"
                    textFieldProps={{}}
                  />
                ),
              }}
            />

            <FormikField<FormSchemaType>
              name="proposedSolution"
              dataTestId={FORM_ID.PROPOSED_SOLUTION}
              formFieldProps={{
                label: (
                  <>
                    {COPY_TEXT.proposedSolutionPromptText} -{" "}
                    <i>{COPY_TEXT.optionalText}</i>
                  </>
                ),
                stretch: true,
                children: (
                  <TextAreaField<FormSchemaType>
                    name="proposedSolution"
                    placeholder="Proposed solution"
                    textFieldProps={{}}
                  />
                ),
              }}
            />
          </SpaceBetween>
        </Form>
      </FormikProvider>
    ),
  });

  return modalComponent;
};

export default ContentIssueModal;
