import Icon from "@amzn/awsui-components-react/polaris/icon";
import PolarisLink, {
  LinkProps as PolarisLinkProps,
} from "@amzn/awsui-components-react/polaris/link";
import { MarkdownRendererContext } from "components/MarkdownRenderer/contexts/MarkdownRendererProvider";
import React, { FC, useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";

import buildResourcePath from "../../utility/build-resource-path";
import isURLExternal from "../../utility/is-url-external";
import setPageHash from "../../utility/set-page-hash";
import styles from "./Link.module.scss";
import { LinkAction, LinkProps } from "./types";

const defaultLinkAction = LinkAction.navigate;

const Link: FC<LinkProps> = ({
  href = "",
  children,
  target,
  action,
  external,
  isImageLink,
}) => {
  const history = useHistory();
  const { config = {} } = useContext(MarkdownRendererContext);
  const { baseUrls = {}, queryParamString } = config;
  const linkAction = LinkAction[action as keyof typeof LinkAction]
    ? action
    : defaultLinkAction;
  const linkHref =
    buildResourcePath(href, {
      linkAction: action,
      baseUrls,
      queryParamString,
    }) || "";
  const onFollow = useCallback<NonNullable<PolarisLinkProps["onFollow"]>>(
    (e) => {
      const { href = "", target, external } = e.detail;

      if (target === "_blank") {
        return;
      }

      // Replace history state for anchor links using native history API and scroll element into view
      if (href.startsWith("#")) {
        e.preventDefault();
        setPageHash(href);
        return;
      }

      // If the external attribute is false and the href value is not
      // an external URL, perform client-side navigation
      if (!external && !isURLExternal(href)) {
        e.preventDefault();
        history.push(href);
      }
    },
    []
  );
  const linkProps: PolarisLinkProps = {
    href: linkHref,
    fontSize: "inherit",
    external: external ?? isURLExternal(linkHref),
    target,
  };

  if (linkAction === LinkAction.download) {
    return (
      <a
        href={linkHref}
        className={styles.link}
        rel="noopener noreferrer"
        target="_blank"
        download
      >
        {children}{" "}
        <span className={styles.icon}>
          <Icon name="download" size="inherit" />
        </span>
      </a>
    );
  }

  if (isImageLink) {
    return (
      <a
        className={styles.imageLink}
        href={linkProps.href}
        data-image={isImageLink}
        target={linkProps.external ? "_blank" : linkProps.target}
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  }

  return (
    <PolarisLink {...linkProps} onFollow={onFollow}>
      {children}
    </PolarisLink>
  );
};

export default Link;
