import AttributeEditor, {
  AttributeEditorProps,
} from "@amzn/awsui-components-react/polaris/attribute-editor";
import { NonCancelableCustomEvent } from "@amzn/awsui-components-react/polaris/internal/events/index";
import { useField } from "formik";
import isEqual from "lodash.isequal";
import React, { useCallback } from "react";

import { FormikFieldProps } from "./../FormControls.interface";
import memoizedFormikPropCheck from "./../FormControls.util";
import css from "./AttributeEditorField.module.scss";

export interface AttributeEditorFieldProps<S, T = AttributeEditorProps<any>> {
  name: keyof S;
  attributeEditorProps: Omit<AttributeEditorProps<any>, "items"> & {
    templateItem: T;
  };
}

const AttributeEditorField = <S, T, U extends string[]>({
  attributeEditorProps,
  field,
  helpers,
}: AttributeEditorFieldProps<S, T> &
  FormikFieldProps<U>): React.ReactElement => {
  const { templateItem } = attributeEditorProps;
  const { setValue } = helpers;

  const handleAdd = useCallback(
    (_e: NonCancelableCustomEvent<{}>) => {
      const value = field.value || [];
      setValue([...value, { ...templateItem }] as U);
    },
    [setValue, field.value]
  );

  const handleRemove = useCallback(
    (
      e: NonCancelableCustomEvent<AttributeEditorProps.RemoveButtonClickDetail>
    ) => {
      const {
        detail: { itemIndex },
      } = e;
      const items = field.value?.slice();
      items?.splice(itemIndex, 1);
      setValue(items as U);
    },
    [setValue, field.value]
  );

  return (
    <AttributeEditor
      className={css.attributeEditorField}
      {...attributeEditorProps}
      items={field.value || []}
      onAddButtonClick={handleAdd}
      onRemoveButtonClick={handleRemove}
    />
  );
};
const MemoizedAttributeEditorField = React.memo(
  AttributeEditorField,
  (prevProps, nextProps) => {
    if (
      !memoizedFormikPropCheck(prevProps, nextProps) ||
      !isEqual(
        prevProps.attributeEditorProps.definition,
        nextProps.attributeEditorProps.definition
      )
    ) {
      return false;
    }

    return true;
  }
);

const AttributeEditorFieldContainer = <S, T>(
  props: AttributeEditorFieldProps<S, T>
) => {
  const [field, meta, helpers] = useField<string[]>(props.name as string);

  const optProps = {
    ...props,
    name: props.name as never,
  };

  return (
    <MemoizedAttributeEditorField {...{ field, meta, helpers }} {...optProps} />
  );
};

export default AttributeEditorFieldContainer;
