import BaseApiClient, {
  ClientInstance,
  getClient,
  RequestConfig,
} from "../BaseApiClient";

class GenericClient extends BaseApiClient {
  constructor(clientName: string, baseUrl: string, options?: RequestConfig) {
    super(clientName, GenericClient.createClient(baseUrl, options));
  }
  static createClient = (
    baseURL: string,
    axiosOptions?: RequestConfig
  ): ClientInstance => {
    const options: RequestConfig = {
      baseURL,
      ...(axiosOptions || {}),
    };

    return getClient(baseURL, options);
  };
}

export default GenericClient;
