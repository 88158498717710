/**
 * A deferred promise is used when the resolution/rejection of
 * a Promise is unknown and is deferred to be resolved/rejected
 * at a later time.
 */
export default class DeferredPromise<T> {
  public readonly promise: Promise<T>;
  // @ts-ignore
  public resolve: (value: T | PromiseLike<T>) => void;
  // @ts-ignore
  public reject: (error?: any) => void;
  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this.reject = reject;
      this.resolve = resolve;
    });
  }
}
