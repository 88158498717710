import Box from "@amzn/awsui-components-react/polaris/box";
import Link, { LinkProps } from "@amzn/awsui-components-react/polaris/link";
import Cookies from "lib/cookies";
import React, { useCallback, useMemo } from "react";

import { Constants } from "../../utils";
import styles from "./Footer.module.scss";

const cookies = Cookies.getInstance();

export interface FooterProps extends React.HTMLProps<HTMLElement> {
  sticky?: boolean;
}

const Footer = ({ className, sticky, ...htmlFooterProps }: FooterProps) => {
  const classNames = useMemo(() => {
    const classes = [styles.consoleNavFooter];

    if (className) {
      classes.push(className.trim());
    }

    if (sticky) {
      classes.push(styles.sticky);
    }

    return classes.join(" ");
  }, [className, sticky]);
  const customizeCookies = useCallback<NonNullable<LinkProps["onFollow"]>>(
    (e) => {
      e.preventDefault();
      cookies.customize();
    },
    []
  );

  return (
    <footer {...htmlFooterProps} className={classNames}>
      <section>
        <Box variant="small" color="inherit">
          © 2008 - {new Date().getFullYear()}, Amazon Web Services, Inc. or its
          affiliates. All rights reserved.
        </Box>
      </section>
      <section className={styles.footerContainerRight}>
        <Link
          href={Constants.ExternalLinks.privacy}
          target="_blank"
          color="inverted"
          fontSize="body-s"
        >
          Privacy policy
        </Link>
        <Link
          href={Constants.ExternalLinks.terms}
          target="_blank"
          color="inverted"
          fontSize="body-s"
        >
          Terms of use
        </Link>
        <Link onFollow={customizeCookies} color="inverted" fontSize="body-s">
          Cookie preferences
        </Link>
      </section>
    </footer>
  );
};

export default Footer;
