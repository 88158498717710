import isEqual from "lodash.isequal";
import { useCallback, useEffect, useReducer, useRef } from "react";
import withRetry, { WithRetryOptions } from "utils/with-retry/with-retry";

import { LogLevel, SinkType } from "./../../lib";
import useIsMounted from "./../use-is-mounted";
import useLogger from "./../use-logger";
import usePerformanceTimer from "./../use-performance-timer";
import usePrevious from "./../use-previous";

export type Parameters<T extends (...args: any[]) => any> = T extends (
  ...args: infer P
) => any
  ? P
  : never;

type UnwrapPromise<T> = T extends Promise<infer U> ? U : T;
type InferFunctionOutput<T> = T extends (...args: any[]) => infer R ? R : any;
export type ReturnType<T> = UnwrapPromise<InferFunctionOutput<T>>;

export enum RequestType {
  INIT = "INIT",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
  RESET = "RESET",
}

interface UseRequestState<S, T> {
  isLoading: boolean;
  isError: T | undefined;
  data: S | null;
}

export interface UseRequestResponse<S, T> {
  isLoading: boolean;
  isError: T | undefined;
  data: ReturnType<S> | null | undefined;
  refetch: () => Promise<void>;
  reset: () => void;
}

export const createDataFetchReducer = <S, T>() => {
  return (
    state: UseRequestState<S, T> = {
      isLoading: false,
      isError: undefined,
      data: null,
    },
    action: { type: RequestType; payload?: S; error?: T }
  ) => {
    switch (action.type) {
      case RequestType.INIT:
        return {
          ...state,
          isLoading: true,
          isError: undefined,
        };
      case RequestType.SUCCESS:
        return {
          ...state,
          isLoading: false,
          isError: undefined,
          data: action.payload,
        };
      case RequestType.FAILURE:
        return {
          ...state,
          isLoading: false,
          isError: action.error,
        };
      case RequestType.RESET:
        return {
          isLoading: false,
          isError: undefined,
          data: null,
        };
      default:
        return state;
    }
  };
};

interface BaseAWSError {
  name?: string;
  stack?: string;
  /**
   * A unique short code representing the error that was emitted.
   */
  code?: number;
  /**
   * A longer human readable error message.
   */
  message?: string;
  /**
   * Whether the error message is retryable.
   */
  retryable?: boolean;
  /**
   * In the case of a request that reached the service, this value contains the response status code.
   */
  statusCode?: number;
  /**
   * Set when a networking error occurs to easily identify the endpoint of the request.
   */
  hostname?: string;
  /**
   * Set when a networking error occurs to easily identify the region of the request.
   */
  region?: string;
  /**
   * Amount of time (in seconds) that the request waited before being resent.
   */
  retryDelay?: number;
  /**
   * The unique request ID associated with the response.
   */
  requestId?: string;
  /**
   * Second request ID associated with the response from S3.
   */
  extendedRequestId?: string;
  /**
   * CloudFront request ID associated with the response.
   */
  cfId?: string;
}

/**
 * Determines whether or not input arguments are empty or not
 */
export const argumentsAreEmpty = <S extends (...args: any[]) => any>(
  ...args: Parameters<S>
): boolean => {
  if (Array.isArray(args)) {
    if (args.length === 0) {
      return true;
    }

    const [inputArgs] = args;

    if (!Array.isArray(inputArgs) || inputArgs.length === 0) {
      return true;
    }

    if (inputArgs.length === 1) {
      const [arg] = inputArgs;

      return (
        arg &&
        Object.keys(arg).length === 0 &&
        Object.getPrototypeOf(arg) === Object.prototype
      );
    }

    return false;
  }

  return true;
};

export const extractErrorMessage = <S extends BaseAWSError>(err: S) => {
  return {
    ...(err?.name ? { name: err?.name } : {}),
    ...(err?.stack ? { stack: err?.stack } : {}),
    ...(err?.code ? { code: err?.code } : {}),
    ...(err?.message ? { message: err?.message } : {}),
    ...(err?.statusCode ? { statusCode: err?.statusCode } : {}),
    ...(err?.hostname ? { hostname: err?.hostname } : {}),
    ...(err?.region ? { region: err?.region } : {}),
    ...(err?.retryable !== undefined ? { retryable: err?.retryable } : {}),
    ...(err?.retryDelay !== undefined ? { retryDelay: err?.retryDelay } : {}),
    ...(err?.requestId ? { requestId: err?.requestId } : {}),
    ...(err?.extendedRequestId
      ? {
          extendedRequestId: err?.extendedRequestId,
        }
      : {}),
    ...(err?.cfId ? { cfId: err?.cfId } : {}),
  };
};

export interface UseRequestOptions<S> {
  /**
   * The context to use when sending an API request
   */
  context?: any;
  /**
   * Custom hook that gets called on an error. The callback
   * function receives the error, and can convert it to
   * something more human readable.
   * @param error S
   */
  onError?: (error: S) => string;
  /**
   * The name of the API request
   * i.e. getContentApi
   */
  apiRequestName?: string;
  /**
   * Optional argument if the given request should be retried
   * using the input arguments from the withRetry utility function
   */
  retryApiArgs?: WithRetryOptions<S>;
  /**
   * If true, will not send error logs to cloudwatch
   */
  skipCloudWatchLogStream?: boolean;
}

/**
 * useRequest is a reusable typesafe hook that executes API calls.
 *
 * - Returns the different states of a given API request (loading, data, error)
 * - Refetch capabilities to update stale data
 * - Ensures that there are no memory leaks if a component unmounts
 * - Strong type safe generic definitions
 * - Has the capability to retry a failed API
 * - Outputs the results of the request using the ConsoleLogSink
 *
 * @example <caption>Calls EEContentCatalog.getContent('22')</caption>
 * const { isLoading, data, isError, refetch } = useRequest<typeof EEContentCatalog['getContent'], AWSError>(
 *     EEContentCatalog.getContent,
 *     {
 *         context: EEContentCatalog,
 *         apiRequestName: 'getContentApi',
 *         onError: (err: AWSError) => {
 *           return `Custom error extraction with ${err.message}.`;
 *         },
 *     },
 *     '22'
 * )
 *
 * @example <caption>Supports multiple input arguments and ensures type safety</caption>
 * const { isLoading, data, isError, refetch } = useRequest<typeof EEContentCatalog['multipleParameters'], AWSError>(
 *     EEContentCatalog.multipleParameters,
 *     {
 *         context: EEContentCatalog,
 *     },
 *     999,
 *     'Test string',
 *     [
 *        {
 *          fruits: [
 *            'apple',
 *            'banana'
 *          ]
 *        }
 *     ]
 * )
 *
 * @typedef useRequest
 * @param {request} request - function that performs the request (i.e. EEContentCatalog.getContent)
 * @param {...args} args - zero or more arguments fed into the request (i.e. '22')
 *
 * @returns {UseRequestResponse<T>} response - Response from the invocation of an API
 * @param {boolean} response.isLoading - Indicates if the API is in a loading state
 * @param {boolean} response.isError - Indicates if an error has occurred after invoking an API
 * @param {ReturnType<T> | null | undefined} response.data - Typesafe generic response of the API
 * @param {() => Promise<void>} response.refetch - Function that invokes the API for fresh data
 */
const useRequest = <S extends (...args: any[]) => any, T = any>(
  request: S,
  options: UseRequestOptions<T>,
  ...args: Parameters<S>
): UseRequestResponse<S, T> => {
  const {
    context = null,
    apiRequestName,
    onError,
    retryApiArgs,
    skipCloudWatchLogStream,
  } = options;
  const { isMounted } = useIsMounted();
  const prevArgs = usePrevious<Parameters<S> | undefined>(args, undefined);
  const [useRequestLogger] = useLogger("useRequestLogger", LogLevel.VERBOSE);
  const [useRequestErrorLogger] = useLogger(
    "useRequestErrorLogger",
    LogLevel.ERROR,
    skipCloudWatchLogStream ? [] : [SinkType.CloudWatchLogSink]
  );

  const { startTimer, endTimer } = usePerformanceTimer();
  const refReducer = useRef(
    createDataFetchReducer<ReturnType<S> | null | undefined, T>()
  );

  const [state, dispatch] = useReducer(refReducer.current, {
    isLoading: false,
    isError: undefined,
    data: null,
  });

  const fetchData: (dataArgs: Parameters<S>) => Promise<void> = useCallback(
    async (dataArgs: Parameters<S>) => {
      if (!request) {
        /**
         * This should never happen (especially with Typescript type checking).
         *
         * In the case that it does (it happened in Aug 2021), return an error immediately.
         */
        dispatch({
          type: RequestType.FAILURE,
          error: ("Request object must be provided, unable to perform request." as unknown) as T,
        });
        return;
      }
      // create a unique group name so we don't get duplicate requests
      const uniqueGroupName: string = JSON.stringify(dataArgs);

      startTimer(request.name, uniqueGroupName);

      dispatch({ type: RequestType.INIT });

      try {
        let result: ReturnType<S>;

        if (retryApiArgs) {
          result = await withRetry<ReturnType<S>, T>(
            () => request.apply(context, dataArgs),
            {
              context,
              ...retryApiArgs,
            }
          );
        } else {
          result = await request.apply(context, dataArgs);
        }

        const successTotalTimeMs = endTimer(request.name, uniqueGroupName);

        if (isMounted.current) {
          useRequestLogger.verbose(
            `${apiRequestName || request?.name} request successful${
              successTotalTimeMs
                ? ` after ${successTotalTimeMs.toFixed(2)}ms.`
                : "."
            }`,
            {
              args: argumentsAreEmpty(args) ? null : args,
              response: result,
            }
          );

          dispatch({ type: RequestType.SUCCESS, payload: result });
        }
      } catch (error) {
        const err: T = error as T;
        const errTotalMs = endTimer(request.name, uniqueGroupName);

        if (isMounted.current) {
          const customError = (onError && onError(err)) || "";

          const useRequestErrors = [
            `${apiRequestName || request?.name} request failed${
              errTotalMs ? ` after ${errTotalMs}ms.` : ""
            }`,
            // @ts-ignore
            extractErrorMessage(err),
            customError,
          ].filter(Boolean);

          useRequestErrorLogger.error.apply(
            useRequestErrorLogger,
            useRequestErrors
          );

          dispatch({ type: RequestType.FAILURE, error: err as T });
        }
      }
    },
    []
  );

  const refetch = useCallback(() => {
    return fetchData(args);
  }, [fetchData]);

  useEffect(() => {
    if (!isEqual(prevArgs, args)) {
      fetchData(args);
    }
  }, [JSON.stringify(args)]);

  const reset = () => {
    dispatch({ type: RequestType.RESET });
  };

  return {
    isLoading: state.isLoading,
    isError: state.isError,
    data: state.data,
    refetch,
    reset,
  };
};

export default useRequest;
