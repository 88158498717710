import {
  DirectiveNode,
  DirectiveNodeProcessor,
  ProcessOptions,
} from "../types";

class ImageDirective extends DirectiveNodeProcessor {
  readonly hName = "img";

  process(node: DirectiveNode, options: ProcessOptions): void {
    super.process(node, options);

    const alt = this.getAltText(node.children);

    if (alt) {
      this.appendNodeProperties(node, {
        alt,
      });
    }
  }

  getAltText(children?: DirectiveNode[]): string {
    if (!children?.length) {
      return "";
    }

    return children
      ?.map((child) => {
        if ("value" in child) {
          return child.value;
        } else if ("alt" in child) {
          return child.alt;
        } else {
          return this.getAltText(child.children);
        }
      })
      .join("");
  }
}

export default ImageDirective;
