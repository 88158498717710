import { NonCancelableCustomEvent } from "@amzn/awsui-components-react/polaris/internal/events/index";
import Select, {
  SelectProps,
} from "@amzn/awsui-components-react/polaris/select";
import { useField } from "formik";
import isEmpty from "lodash.isempty";
import isEqual from "lodash.isequal";
import React, { useCallback, useEffect, useState } from "react";

import { FormikFieldProps } from "./../FormControls.interface";
import memoizedFormikPropCheck from "./../FormControls.util";

export interface SelectFieldProps<S = any> {
  name: keyof S;
  selectProps: Omit<SelectProps, "selectedOption" | "onBlur" | "onChange">;
  placeholder?: string;
}

function SelectField<S, T extends string>({
  placeholder,
  selectProps,
  field,
  meta,
  helpers,
}: SelectFieldProps<S> & FormikFieldProps<T>) {
  const [
    selectedOption,
    setSelectedOption,
  ] = useState<SelectProps.Option | null>(null);

  useEffect(() => {
    const optionSelected = selectProps?.options?.find(
      (option: SelectProps.Option) => {
        return option.value === field.value;
      }
    );

    optionSelected && setSelectedOption(optionSelected);
  }, [field.value, selectProps.options]);

  const handleBlur = useCallback(() => {
    /**
     * @jcortezj 1/14/2022
     * Setting a timeout of 0 allows the next tab to focus before
     * helpers.setTouched gets called.
     *
     * Without the setTimeout, if you press tab in some context (e.g. in a modal) it does not
     * focus to the next form element correctly.
     *
     * Validated by @parkyh
     */
    setTimeout(() => {
      helpers.setTouched(true);
    }, 0);
  }, [helpers]);

  const handleChange = useCallback(
    (e: NonCancelableCustomEvent<SelectProps.ChangeDetail>) => {
      helpers.setValue(e.detail.selectedOption.value as T);
    },
    [helpers]
  );

  return (
    <Select
      onBlur={handleBlur}
      onChange={handleChange}
      placeholder={placeholder}
      selectedOption={selectedOption}
      invalid={meta.touched && !isEmpty(meta.error)}
      {...selectProps}
    />
  );
}

const MemoizedSelectField = React.memo(SelectField, (prevProps, nextProps) => {
  if (
    !memoizedFormikPropCheck(prevProps, nextProps) ||
    prevProps.selectProps.disabled !== nextProps.selectProps.disabled
  ) {
    return false;
  }

  if (!isEqual(prevProps.selectProps.options, nextProps.selectProps.options)) {
    return false;
  }

  return true;
});

const SelectFieldContainer = <S,>(props: SelectFieldProps<S>) => {
  const [field, meta, helpers] = useField<string>(props.name as string);

  const optProps = {
    ...props,
    name: props.name as never,
  };

  return <MemoizedSelectField {...{ field, meta, helpers }} {...optProps} />;
};

export default SelectFieldContainer;
