import { useCallback, useReducer, useRef } from "react";
import withRetry from "utils/with-retry/with-retry";

import { LogLevel, SinkType } from "./../../../lib";
import useIsMounted from "./../../use-is-mounted";
import useLogger from "./../../use-logger";
import usePerformanceTimer from "./../../use-performance-timer";
import {
  argumentsAreEmpty,
  createDataFetchReducer,
  extractErrorMessage,
  Parameters,
  RequestType,
  ReturnType,
  UseRequestOptions,
  UseRequestResponse,
} from "./../use-request";

export interface UseLazyRequestResponse<S, T>
  extends Omit<UseRequestResponse<S, T>, "refetch"> {}

/**
 * useLazyRequest is a reusable typesafe hook that executes API calls lazily.
 *
 * - Exposes a typesafe function that can be invoked at any time with strong type safe definitions
 * - Returns the different states of a given API request (loading, data, error)
 * - Ensures that there are no memory leaks if a component unmounts
 *
 * @example <caption>Calls EEContentCatalog.getContent('22') lazily</caption>
 * const [ getContent, { isLoading, data, isError } ] = useLazyRequest<typeof EEContentCatalog['getContent']>(
 *     EEContentCatalog.getContent,
 *     {
 *         context: EEContentCatalog,
 *         apiRequestName: 'getContentApi',
 *         onError: (err: AWSError) => {
 *           return `Custom error extraction with ${err.message}.`;
 *         }
 *     }
 * )
 *
 * await getContent('22');
 *
 * @example <caption>Supports multiple input arguments and ensures type safety</caption>
 * const [ multipleParamsFn, { isLoading, data, isError } ] = useLazyRequest<typeof EEContentCatalog['multipleParameters']>(
 *     EEContentCatalog.multipleParameters,
 *     {}
 * )
 *
 * await multipleParamsFn(999,
 *   'Test string',
 *   [
 *      {
 *        fruits: [
 *          'apple',
 *          'banana'
 *        ]
 *      }
 *     ])
 *
 * @typedef useLazyRequest
 * @param {request} request - function that is used to perform a request (i.e. EEContentCatalog.getContent)
 * @returns {UseLazyRequestResponse<T>} response - array containing a function that can be executed lazily,
 * and the response from the invocation of an API
 * @param {(...args) => Promise<void>} response[0] - A function that accepts zero or more typesafe arguments
 * @param {boolean} response[1].isLoading - Indicates if the API is in a loading state
 * @param {boolean} response[1].isError - Indicates if an error has occurred after invoking an API
 * @param {ReturnType<T> | null | undefined} response[1].data - Typesafe generic response of the API
 */
const useLazyRequest = <S extends (...args: any[]) => any, T>(
  request: S,
  options: UseRequestOptions<T>
): [
  (...args: Parameters<S>) => Promise<ReturnType<S> | undefined>,
  UseLazyRequestResponse<S, T>
] => {
  const {
    context = null,
    apiRequestName,
    onError,
    retryApiArgs,
    skipCloudWatchLogStream,
  } = options;
  const { isMounted } = useIsMounted();
  const [useLazyRequestLogger] = useLogger(
    "useLazyRequestLogger",
    LogLevel.VERBOSE
  );
  const [useLazyRequestErrorLogger] = useLogger(
    "useLazyRequestErrorLogger",
    LogLevel.ERROR,
    skipCloudWatchLogStream ? [] : [SinkType.CloudWatchLogSink]
  );
  const { startTimer, endTimer } = usePerformanceTimer();

  const refReducer = useRef(
    createDataFetchReducer<ReturnType<S> | null | undefined, T>()
  );

  const [state, dispatch] = useReducer(refReducer.current, {
    isLoading: false,
    isError: undefined,
    data: null,
  });

  const performLazyRequest: (
    ...args: Parameters<S>
  ) => Promise<ReturnType<S> | undefined> = useCallback(
    async (...args: Parameters<S>) => {
      if (!isMounted.current) {
        useLazyRequestLogger.warn(
          `Lazy ${
            apiRequestName || request?.name
          } request unable to be fulfilled, component unmounted.`
        );
        return;
      }

      if (!request) {
        /**
         * This should never happen (especially with Typescript type checking).
         *
         * In the case that it does (it happened in Aug 2021), return an error immediately.
         */
        dispatch({
          type: RequestType.FAILURE,
          error: ("Request object must be provided, unable to perform request." as unknown) as T,
        });
        return;
      }

      // create a unique group name so we don't get duplicate requests
      const uniqueGroupName: string = JSON.stringify(args);

      startTimer(request.name, uniqueGroupName);
      dispatch({ type: RequestType.INIT });
      let result: ReturnType<S>;
      try {
        if (retryApiArgs) {
          result = await withRetry<ReturnType<S>, T>(
            () => request.apply(context, args),
            {
              context,
              ...retryApiArgs,
            }
          );
        } else {
          result = await request.apply(context, args);
        }

        const successTotalTimeMs = endTimer(request.name, uniqueGroupName);

        if (isMounted.current) {
          useLazyRequestLogger.verbose(
            `${apiRequestName || request?.name} request successful${
              successTotalTimeMs
                ? ` after ${successTotalTimeMs.toFixed(2)}ms.`
                : ""
            }`,
            {
              args: argumentsAreEmpty(args) ? null : args,
              response: result,
            }
          );
          dispatch({ type: RequestType.SUCCESS, payload: result });
        }
        return result;
      } catch (error) {
        const err: T = error as T;
        const errTotalMs = endTimer(request.name, uniqueGroupName);

        const customErr = (onError && onError(err)) || "";

        if (isMounted.current) {
          const useLazyRequestErrors = [
            `${apiRequestName || request?.name} request failed${
              errTotalMs ? ` after ${errTotalMs}ms.` : ""
            }`,
            // @ts-ignore
            extractErrorMessage(err),
            customErr,
          ].filter(Boolean);

          useLazyRequestErrorLogger.error.apply(
            useLazyRequestErrorLogger,
            useLazyRequestErrors
          );

          dispatch({ type: RequestType.FAILURE, error: err });
        }
      }
    },
    []
  );

  const reset = () => {
    dispatch({ type: RequestType.RESET });
  };

  return [
    performLazyRequest,
    {
      isLoading: state.isLoading,
      isError: state.isError,
      data: state.data,
      reset,
    },
  ];
};

export default useLazyRequest;
