import {
  DirectiveNode,
  DirectiveNodeProcessor,
  ProcessOptions,
} from "../types";

class LinkDirective extends DirectiveNodeProcessor {
  readonly hName = "a";

  process(node: DirectiveNode, options: ProcessOptions): void {
    super.process(node, options);

    this.appendNodeProperties(node, {
      href: encodeURI(node.attributes.href || ""),
    });
  }
}

export default LinkDirective;
