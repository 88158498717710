import { ApiClient } from "lib";
import React, { useEffect } from "react";
import { PromiseReflect } from "utils";

import useAxiosClient from "../use-axios-client";
import usePrevious from "../use-previous";

interface UseEmbeddedRequestParams {
  contentBaseUrl: string;
  manifestRequestUrl: string;
  metadataRequestUrl: string;
  onReset?: () => void;
}

export const staticClient = ApiClient.createGenericClient({
  clientName: "EmbeddedRequestStaticClient",
  /**
   * If previewing the workshop from EEWebConsole, baseUrl will be dynamically populated by the response
   * of getContentBuild.
   *
   * If it is a public workshop, the baseUrl will come directly as a prop from PreviewComponent
   */
  baseUrl: "",
  cache: true,
  cacheStoragedType: "in-memory",
});

/**
 * useEmbeddedRequest is a reusable hook that fetches public content for a published workshop.
 * Both of the files live in the same location, which is why they utilize the same EmbeddedRequestStaticClient.
 *
 * The hook makes two different requests.
 *   1. metadata.json - contains the workshops title & description (used for SEO)
 *   2. manifest.json - contains an object that describes the structure of the workshop
 */
const useEmbeddedRequest = <S, T>({
  contentBaseUrl,
  manifestRequestUrl,
  metadataRequestUrl,
  onReset,
}: UseEmbeddedRequestParams) => {
  const previousContentBuildId = usePrevious<string | undefined>(
    contentBaseUrl,
    contentBaseUrl
  );
  const {
    get: {
      request: manifestRequest,
      reset: resetManifestResponse,
      response: {
        isLoading: manifestIsLoading,
        isError: manifestError,
        data: manifestData,
      },
    },
  } = useAxiosClient<S>(staticClient);

  const {
    get: {
      request: metadataRequest,
      reset: resetMetadataResponse,
      response: { data: metadataData },
    },
  } = useAxiosClient<T>(staticClient);

  useEffect(() => {
    const performRequest = async (baseUrl: string) => {
      staticClient.setBaseUrl(baseUrl);

      await PromiseReflect([
        manifestRequest(manifestRequestUrl),
        metadataRequest(metadataRequestUrl),
      ]);
    };

    if (contentBaseUrl) {
      performRequest(contentBaseUrl);
    }

    return () => {
      staticClient.setBaseUrl("");
    };
  }, [contentBaseUrl]);

  useEffect(() => {
    if (previousContentBuildId !== contentBaseUrl) {
      onReset?.();
      resetManifestResponse();
      resetMetadataResponse();
    }
  }, [previousContentBuildId, contentBaseUrl]);

  return {
    manifest: {
      isLoading: manifestIsLoading,
      isError: manifestError,
      data: manifestData,
    },
    metadata: {
      data: metadataData,
    },
  };
};

export default useEmbeddedRequest;
