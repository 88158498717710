import Button, {
  ButtonProps,
} from "@amzn/awsui-components-react/polaris/button";
import Popover from "@amzn/awsui-components-react/polaris/popover";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import useCopyToClipboard from "hooks/use-copy-to-clipboard";
import React, { FC, useMemo, useState } from "react";

import css from "./CopyButton.module.scss";

export enum TestIDs {
  CopyButton = "copy-button",
}

interface CopyButtonI18nStrings {
  copied: string;
  loading: string;
  error: string;
}

interface CopyButtonProps {
  elementId?: string;
  content?: string;
  deferredContent?: () => Promise<string>;
  disabled?: boolean;
  className?: string;
  i18n?: Partial<CopyButtonI18nStrings>;
  variant?: ButtonProps.Variant;
}

const defaultI18nStrings: CopyButtonI18nStrings = {
  copied: "Copied!",
  loading: "Loading...",
  error: "Could not copy content",
};

const CopyButton: FC<CopyButtonProps> = ({
  elementId,
  children,
  content,
  deferredContent,
  disabled,
  className,
  i18n,
  variant,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const status = useMemo(() => {
    i18n = {
      ...defaultI18nStrings,
      ...(i18n || {}),
    };

    if (isLoading) {
      return <StatusIndicator type="loading">{i18n.loading}</StatusIndicator>;
    } else if (isError) {
      return <StatusIndicator type="error">{i18n.error}</StatusIndicator>;
    }

    return <StatusIndicator type="success">{i18n.copied}</StatusIndicator>;
  }, [isLoading, isError]);
  const {
    copyToClipboard: copyToClipboardUtil,
    copyContentToClipboard,
  } = useCopyToClipboard({
    elementId: elementId as string,
  });

  const copyToClipboard = async () => {
    setIsError(false);
    setIsLoading(false);

    if (elementId) {
      copyToClipboardUtil();
    } else if (content) {
      copyContentToClipboard(content);
    } else if (deferredContent) {
      setIsLoading(true);
      const content = await deferredContent().catch(() => {
        setIsError(true);
      });
      setIsLoading(false);
      if (!content) {
        setIsError(true);
        return;
      }
      copyContentToClipboard(content);
    }
  };

  const copyButtonStyles = [
    // inline-icons do not need padding
    ...(variant === "inline-icon" || children ? [] : [css.styledButton]),
  ];
  const action = (
    <Button
      className={copyButtonStyles.join(" ")}
      disabled={disabled || isLoading}
      variant={variant}
      onClick={() => copyToClipboard()}
      data-testid={TestIDs.CopyButton}
      iconName="copy"
      ariaLabel="Copy content"
    >
      {children}
    </Button>
  );

  if (disabled) {
    return action;
  }

  return (
    <Popover
      className={className}
      size="small"
      position="top"
      triggerType="custom"
      dismissButton={false}
      content={status}
    >
      {action}
    </Popover>
  );
};

export default CopyButton;
