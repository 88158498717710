import Browser, { OSType } from "utils/browser";

const reactAppPolyfills: Promise<any>[] = [];

if (Browser.operatingSystem() === OSType.Windows) {
  // if it is not IE, it will return -1
  const ieVersion: number = Browser.getIEVersion();

  if (ieVersion >= 0 && ieVersion <= 11) {
    // @ts-ignore
    reactAppPolyfills.push(import("react-app-polyfill/ie11"));
    // @ts-ignore
    reactAppPolyfills.push(import("react-app-polyfill/stable"));
  }
}

export default reactAppPolyfills;
