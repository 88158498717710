import Box from "@amzn/awsui-components-react/polaris/box";
import SegmentedControl from "@amzn/awsui-components-react/polaris/segmented-control";
import Tabs from "@amzn/awsui-components-react/polaris/tabs";
import CodeBlock from "components/CodeBlock";
import React, { FC, useMemo, useState } from "react";
import awsCliEnvCredentials, { CliEnv } from "utils/aws-cli-env-credentials";

import { AWSCliCredentialsProps } from "./AWSCliCredentials.interface";
import css from "./AWSCliCredentials.module.scss";

const AWSCliCredentials: FC<AWSCliCredentialsProps> = ({
  credentials,
  defaultEnv = CliEnv.Bash,
  defaultRegion,
  showCopyAction,
  tabsVariant,
  variant = "tabs",
}) => {
  const [selectedEnvId, setSelectedEnvId] = useState(defaultEnv);
  const cliEnvs = useMemo(
    () => awsCliEnvCredentials(credentials, defaultRegion),
    [credentials, defaultRegion]
  );
  const selectedCliEnv = useMemo(
    () => cliEnvs.find(({ env }) => env === selectedEnvId),
    [cliEnvs, selectedEnvId]
  );

  return (
    <>
      {variant === "segmented-control" && (
        <div>
          <Box margin={{ bottom: "xs" }}>
            <SegmentedControl
              className={css.segmentedControl}
              selectedId={selectedEnvId}
              onChange={({ detail: { selectedId } }) =>
                setSelectedEnvId(selectedId as CliEnv)
              }
              options={cliEnvs.map((CliEnv) => ({
                id: CliEnv.env,
                text: CliEnv.getName(),
              }))}
            />
          </Box>
          {selectedCliEnv && (
            <CodeBlock showCopyAction={showCopyAction}>
              {selectedCliEnv.toString()}
            </CodeBlock>
          )}
        </div>
      )}
      {!variant ||
        (variant === "tabs" && (
          <Tabs
            variant={tabsVariant}
            disableContentPaddings={tabsVariant !== "container"}
            activeTabId={selectedEnvId}
            onChange={({ detail: { activeTabId } }) =>
              setSelectedEnvId(activeTabId as CliEnv)
            }
            tabs={cliEnvs.map((CliEnv) => ({
              id: CliEnv.env,
              label: CliEnv.getName(),
              content: (
                <Box
                  padding={{
                    top: tabsVariant !== "container" ? "m" : undefined,
                  }}
                >
                  <CodeBlock showCopyAction={showCopyAction}>
                    {CliEnv.toString()}
                  </CodeBlock>
                </Box>
              ),
            }))}
          />
        ))}
    </>
  );
};

export { AWSCliCredentialsProps } from "./AWSCliCredentials.interface";
export default AWSCliCredentials;
