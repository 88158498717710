import React from "react";
import Markdown, { Options } from "react-markdown";
import gfm from "remark-gfm";

const BaseMarkdown = (props: Omit<Options, "rawSourcePos" | "skipHtml">) => {
  const remarkPlugins: Options["remarkPlugins"] = [
    gfm,
    ...(props.remarkPlugins || []),
  ];

  return (
    <Markdown {...props} remarkPlugins={remarkPlugins} rawSourcePos skipHtml />
  );
};

export default BaseMarkdown;
