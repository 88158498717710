/**
 * User agent logic based off of: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/User-Agent
 * Internet Explorer logic based off of: https://www.whatismybrowser.com/guides/the-latest-user-agent/internet-explorer
 */

export enum OSType {
  Unknown = "Unknown",
  Windows = "Windows",
  Mac = "Mac",
  Linux = "Linux",
}

export enum BrowserType {
  Unknown = "Unknown",
  Firefox = "Firefox",
  Edge = "Edge",
  Chrome = "Chrome",
  Safari = "Safari",
}

interface LatestPerformanceEntry extends PerformanceEntry {
  domContentLoadedEventEnd: number;
  domContentLoadedEventStart: number;
  domComplete: number;
  domInteractive: number;
}

interface TimingData {
  domContentLoadedMs: number;
  domCompleteMs: number;
  domInteractiveMs: number;
}

const edgeRegExp: RegExp = /Edge?\//;

export class BrowserUtil {
  private osType: OSType;
  constructor() {
    this.osType = this.getOSType();
  }

  public operatingSystem() {
    return this.osType;
  }

  // returns version of IE or -1, if browser is not Internet Explorer
  public getIEVersion(): number {
    let version = this.getClassicIEVersion();

    if (version === -1) {
      version = this.getTridentEdgeVersion();
    }

    return version;
  }

  /*
   * Logic derived entirely from MDN docs
   * https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#avoiding_user_agent_detection
   */
  public isMobileDevice(): boolean {
    let hasTouchScreen = false;

    if ("maxTouchPoints" in navigator || "msMaxTouchPoints" in navigator) {
      hasTouchScreen =
        navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
    } else {
      const mQ = window.matchMedia && window?.matchMedia("(pointer:coarse)");

      if (mQ && mQ.media === "(pointer:coarse)") {
        hasTouchScreen = !!mQ.matches;
      } else if ("orientation" in window) {
        hasTouchScreen = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        const UA = navigator!.userAgent;

        hasTouchScreen =
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
    }

    return hasTouchScreen;
  }

  public getTimingData(): TimingData | undefined {
    if ("getEntriesByType" in performance) {
      const entries = performance?.getEntriesByType("navigation");
      const perfEntry: LatestPerformanceEntry | undefined = Array.isArray(
        entries
      )
        ? (entries[0] as LatestPerformanceEntry)
        : undefined;

      if (!perfEntry) {
        return undefined;
      }

      return {
        domContentLoadedMs:
          perfEntry.domContentLoadedEventEnd -
          perfEntry.domContentLoadedEventStart,
        domCompleteMs: perfEntry.domComplete,
        domInteractiveMs: perfEntry.domInteractive,
      };
    }

    return undefined;
  }

  public getOSType(): OSType {
    const userAgent = this.getUserAgent();

    let osType = OSType.Unknown;
    if (userAgent.indexOf("Windows") !== -1) {
      osType = OSType.Windows;
    } else if (userAgent.indexOf("Mac") !== -1) {
      osType = OSType.Mac;
    } else if (userAgent.indexOf("Linux") !== -1) {
      osType = OSType.Linux;
    }

    return osType;
  }
  public getUserLanguage(): string {
    return window.navigator.language;
  }
  /**
   * Gets current browser
   * https://www.seanmcp.com/articles/how-to-get-the-browser-version-in-javascript/#sniffing-logic
   * @returns {BrowserType}
   */
  public getBrowserType(): BrowserType {
    const userAgent = this.getUserAgent();

    let browserType = BrowserType.Unknown;

    if (userAgent.includes("Firefox/")) {
      browserType = BrowserType.Firefox;
    } else if (edgeRegExp.test(userAgent)) {
      browserType = BrowserType.Edge;
    } else if (userAgent.includes("Chrome/")) {
      browserType = BrowserType.Chrome;
    } else if (userAgent.includes("Safari/")) {
      browserType = BrowserType.Safari;
    }
    return browserType;
  }
  public getUserAgent(): string {
    const defaultAgent = "";
    try {
      // try/catch required for node.js env
      return window && window.navigator
        ? window.navigator.userAgent
        : defaultAgent;
    } catch (error) {
      return defaultAgent;
    }
  }
  private getTridentEdgeVersion(): number {
    const ua = this.getUserAgent();
    let version = -1;

    version = this.getTridentVersion(ua) || version;

    return version;
  }
  // returns classic IE version or -1, if browser is not Internet Explorer
  private getClassicIEVersion(): number {
    const ua = this.getUserAgent();
    let version = -1;

    const msie = ua.indexOf("MSIE ");
    if (msie > 0) {
      // for IE 10 or older => return version number
      version = parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
    }

    version = this.getTridentVersion(ua) || version;

    return version;
  }
  private getTridentVersion(ua: string): number | null {
    let tridentVersion = null;

    const trident = ua.indexOf("Trident/");
    if (trident > 0) {
      // for IE 11 => return version number
      const rv = ua.indexOf("rv:");
      tridentVersion = parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
    }
    return tridentVersion;
  }
}

export default new BrowserUtil();
