import { FlashbarProps } from "@amzn/awsui-components-react/polaris/flashbar";

export type ExtendedFlashbarProps = FlashbarProps.MessageDefinition & {
  id?: string;
};

export class ExtendedFlashbarMessageDefinition {
  id: string = Date();
  type: FlashbarProps.MessageDefinition["type"];
  onDismiss?: FlashbarProps.MessageDefinition["onDismiss"];
  dismissible?: boolean;
  content: React.ReactNode;
  constructor(data: ExtendedFlashbarProps) {
    this.dismissible = !!data.onDismiss;
    Object.assign(this, data);
  }
}

export type SetterOrUpdater<T> = (
  valOrUpdater: ((currVal: T) => T) | T
) => void;
