import { SelectProps } from "@amzn/awsui-components-react/polaris/select";
import {
  FormikField,
  InputField,
  MultiSelectField,
  SelectField,
} from "components/FormControls";
import { BaseAttributeType } from "lib/FormikFormUtils/FormikFormUtils";
import React, { ComponentProps } from "react";

type FormFieldProps = Pick<
  ComponentProps<typeof FormikField>,
  "formFieldProps"
>;

type FactoryProps = {
  placeholder?: string;
  inputProps?: ComponentProps<typeof InputField>["inputProps"];
  selectProps?: ComponentProps<typeof SelectField>["selectProps"];
  multiSelectProps?: ComponentProps<
    typeof MultiSelectField
  >["multiSelectProps"];
};

export interface OptionalFactoryProps {
  componentProps?: FactoryProps;
  formFieldProps?: FormFieldProps["formFieldProps"];
}

interface Factory<S> {
  create(type: string, attribute: S): JSX.Element;
}

class BaseInput {
  constructor(
    protected formName: string,
    protected optProps?: OptionalFactoryProps
  ) {
    this.formName = formName;
    this.optProps = optProps;
  }

  public getFormFieldProps(): FormFieldProps {
    return {
      formFieldProps: {
        stretch: true,
        ...((this.optProps?.formFieldProps && this.optProps?.formFieldProps) ||
          {}),
      },
    };
  }

  public createOptions<S extends string[]>(
    choiceOptions: S
  ): SelectProps.Options {
    return choiceOptions.map((choice) => {
      return {
        label: choice,
        value: choice,
      };
    });
  }
}

class FieldInput<S extends BaseAttributeType>
  extends BaseInput
  implements Factory<S> {
  constructor(formName: string, optProps?: OptionalFactoryProps) {
    super(formName, optProps);
  }
  public create = (type: string, attribute: S): JSX.Element => {
    const { inputProps = {}, placeholder = "" } =
      this.optProps?.componentProps || ({} as FactoryProps);

    const { name, description } = attribute;
    const formFieldProps = this.getFormFieldProps().formFieldProps;

    return (
      <FormikField
        name={this.formName}
        dataTestId={`dynamic-field-${this.formName}`}
        formFieldProps={{
          label: name,
          description,
          ...formFieldProps,
          children: (
            <InputField
              name={this.formName}
              placeholder={placeholder}
              inputProps={{
                ...inputProps,
                type: type === "string" ? "text" : "number",
              }}
            />
          ),
        }}
      />
    );
  };
}

class MultiSelectInput<S extends BaseAttributeType>
  extends BaseInput
  implements Factory<S> {
  constructor(formName: string, optProps?: OptionalFactoryProps) {
    super(formName, optProps);
  }
  public create = (_type: string, attribute: S): JSX.Element => {
    const { multiSelectProps = {}, placeholder = "" } =
      this.optProps?.componentProps || {};

    const { name, description, choiceOptions = [] } = attribute;
    const formFieldProps = this.getFormFieldProps().formFieldProps;

    return (
      <FormikField
        name={this.formName}
        dataTestId={`dynamic-field-${this.formName}`}
        formFieldProps={{
          label: name,
          description,
          ...formFieldProps,
          children: (
            <MultiSelectField
              name={this.formName}
              placeholder={placeholder}
              multiSelectProps={{
                tokenLimit: 3,
                ...multiSelectProps,
                options: this.createOptions(choiceOptions),
              }}
            />
          ),
        }}
      />
    );
  };
}

class SelectInput<S extends BaseAttributeType>
  extends BaseInput
  implements Factory<S> {
  constructor(formName: string, optProps?: OptionalFactoryProps) {
    super(formName, optProps);
  }
  public create = (_type: string, attribute: S): JSX.Element => {
    const { selectProps = {}, placeholder = "" } =
      this.optProps?.componentProps || {};

    const { name, description, choiceOptions = [] } = attribute;
    const formFieldProps = this.getFormFieldProps().formFieldProps;

    return (
      <FormikField
        name={this.formName}
        dataTestId={`dynamic-field-${this.formName}`}
        formFieldProps={{
          label: name,
          description,
          ...formFieldProps,
          children: (
            <SelectField
              name={this.formName}
              placeholder={placeholder}
              selectProps={{
                ...selectProps,
                options: this.createOptions(choiceOptions),
              }}
            />
          ),
        }}
      />
    );
  };
}

const FactoryMethods = {
  ["string"]: FieldInput,
  ["multi_choice"]: MultiSelectInput,
  ["single_choice"]: SelectInput,
  ["number"]: FieldInput,
};

class FormFactory {
  static buildFormElement<S extends BaseAttributeType>(
    attribute: S,
    optProps?: OptionalFactoryProps
  ) {
    const { type } = attribute;
    // @ts-ignore
    const FormFieldFactory = FactoryMethods[type as string];

    if (!FormFieldFactory) {
      return null;
    }

    return new FormFieldFactory(
      attribute.dynamicFormName || (attribute.name as string),
      optProps
    ).create(type, attribute);
  }
}

export default FormFactory;
