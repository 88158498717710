import Box from "@amzn/awsui-components-react/polaris/box";
import Checkbox from "@amzn/awsui-components-react/polaris/checkbox";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import TextContent from "@amzn/awsui-components-react/polaris/text-content";
import { useInterval, useLocalStorage } from "hooks";
import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { Constants, getAppVersion } from "utils";

const CONSOLE_LOG_SINK_ID = Constants.ConsoleLogLocalStorageId;
export const NUM_CLICKS_TO_ACTIVATE = 10;
export const NUM_SECONDS_TO_ENTER = 2;
const RESET_CLICK_VALUE = 1;

export interface DebugMenuContainerProps {
  gitInformation?: {
    sha?: string;
    branch?: string;
  };
  children?: PropsWithChildren<ReactNode>;
}

/**
 * To enable the Debug Menu, click on the Left Control key NUM_CLICKS_TO_ACTIVATE
 * number of times, within a NUM_SECONDS_TO_ENTER timeframe.
 */
const DebugMenuContainer: React.FC<DebugMenuContainerProps> = ({
  children,
  gitInformation,
}: DebugMenuContainerProps): JSX.Element | null => {
  const { sha, branch } = gitInformation || {};
  const { getItem, setItem, removeItem } = useLocalStorage();
  const [checked, setChecked] = React.useState<boolean>(
    !!getItem(CONSOLE_LOG_SINK_ID)
  );
  const [appBuildVersion, setAppBuildVersion] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [countdown, setCountDown] = useState(NUM_SECONDS_TO_ENTER);
  const [numTimesControlCliked, setNumTimesControlCliked] = useState(
    RESET_CLICK_VALUE
  );

  useInterval(
    () => {
      if (countdown <= 0) {
        setIsActive(false);
        setNumTimesControlCliked(RESET_CLICK_VALUE);
        setCountDown(NUM_SECONDS_TO_ENTER);
        return;
      }
      setCountDown(countdown - 1);
    },
    isActive ? 1000 : null
  );

  const onKeyUp = useCallback(
    (event: KeyboardEvent | TouchEvent) => {
      let isActivated = false;

      /**
       * If a user pressed on the shift and left control key,
       * at the same time, it is a valid selection
       */
      if (event.type === "keyup") {
        const isLeftControl = (event as KeyboardEvent).code === "ControlLeft";
        isActivated = !!isLeftControl;
      }

      if (isActivated) {
        if (numTimesControlCliked < NUM_CLICKS_TO_ACTIVATE) {
          if (!isActive) {
            setIsActive(true);
          }

          setNumTimesControlCliked(numTimesControlCliked + 1);
          return;
        }

        setNumTimesControlCliked(RESET_CLICK_VALUE);
        setIsActive(false);
        setModalOpen(true);
      }
    },
    [isActive, numTimesControlCliked]
  );

  useEffect(() => {
    window.addEventListener("keyup", onKeyUp);
    return (): void => window.removeEventListener("keyup", onKeyUp);
  }, [numTimesControlCliked, onKeyUp]);

  useLayoutEffect(() => {
    const appBuildVersion: string | null = getAppVersion();
    appBuildVersion && setAppBuildVersion(appBuildVersion);
  }, []);

  return (
    <div data-testid="debug-menu">
      {children}
      <Modal
        data-testid="debug-modal"
        onDismiss={() => setModalOpen(false)}
        visible={modalOpen}
        closeAriaLabel="Close modal"
        size="medium"
        header="Event Engine Debug Menu"
      >
        <>
          {(sha || branch || appBuildVersion) && (
            <Box margin={{ bottom: "m" }}>
              <SpaceBetween size="m" direction="vertical">
                <TextContent>
                  <h4>Build Information</h4>
                  {branch && (
                    <p>
                      <b>Branch</b> {branch}
                    </p>
                  )}
                  {sha && (
                    <p>
                      <b>Commit SHA</b> {sha}
                    </p>
                  )}
                  {appBuildVersion && (
                    <p>
                      <b>Build Version</b> {appBuildVersion}
                    </p>
                  )}
                </TextContent>
              </SpaceBetween>
            </Box>
          )}
          <Checkbox
            onChange={({ detail }) => {
              if (detail.checked) {
                setItem(CONSOLE_LOG_SINK_ID, "true");
              } else {
                removeItem(CONSOLE_LOG_SINK_ID);
              }
              setChecked(detail.checked);
            }}
            checked={checked}
          >
            Enable ConsoleLogSink
          </Checkbox>
        </>
      </Modal>
    </div>
  );
};

export default DebugMenuContainer;
