import { useEffect, useRef, useState } from "react";
import Constants, { IRetryBackoff } from "utils/constants/constants";

const { exponentialBackoff, retryBackoff: defaultRetryBackoff } = Constants;

type IntervalId = ReturnType<typeof setTimeout>;

const useExponentialInterval = (
  callback: ({ runCount }: { runCount: number; isLastRun: boolean }) => void,
  retryBackoff: IRetryBackoff = defaultRetryBackoff,
  startImmediate: boolean = true
) => {
  const runCount = useRef<number>(0);
  const [isRunning, setIsRunning] = useState<boolean>(startImmediate);
  const savedCallback = useRef<Function>();
  const intervalId = useRef<IntervalId | undefined>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      if (savedCallback && savedCallback.current) {
        const runs = runCount.current + 1;
        runCount.current = runs;

        const isLastRun = retryBackoff.maxRetries <= runs;

        savedCallback.current({
          runCount: runs,
          isLastRun,
        });

        if (isLastRun) {
          return;
        }

        intervalId.current = setTimeout(
          tick,
          exponentialBackoff(runs, retryBackoff)
        );
      }
    }

    if (!isRunning) {
      return;
    }

    intervalId.current = setTimeout(
      tick,
      exponentialBackoff(runCount.current, retryBackoff)
    );

    return () => clearTimeout(intervalId.current as IntervalId);
  }, [isRunning]);

  return {
    startExponentialInterval() {
      setIsRunning(true);
    },
    stopExponentialInterval() {
      setIsRunning(false);
      clearTimeout(intervalId.current as IntervalId);
      runCount.current = 0;
    },
    isRunning,
  };
};

export default useExponentialInterval;
