import React from "react";
import getStringList from "utils/get-string-list";

import { ErrorMessageProps } from "../../../components/ErrorMessage";
import { DIRECTIVE_REGISTRY } from "../constants";
import {
  DirectiveNode,
  DirectiveNodeProcessor,
  DirectiveType,
  ProcessOptions,
} from "../types";

class ErrorDirective extends DirectiveNodeProcessor {
  readonly hName = "error";

  process(node: DirectiveNode, options: ProcessOptions): void {
    super.process(node, options);

    const position = node.position;
    const nodeType = this.getShortNodeType(node.type);
    const supportedTypes = this.getSupportedTypes(node);
    const errorMessageProps: ErrorMessageProps = {
      type: "unknownDirective",
      message: `${
        position ? `Line ${position.start.line}: ` : ""
      }Unknown ${nodeType} directive “${node.name}”`,
    };

    if (supportedTypes.length) {
      errorMessageProps.type = "invalidDirectiveType";
      errorMessageProps.message = (
        <>
          {position ? `Line ${position.start.line}: ` : ""}&ldquo;
          {node.name}&rdquo; cannot be declared as a {nodeType} directive.
          Please declare it as a{" "}
          {getStringList(supportedTypes.map(this.getShortNodeType))} directive
        </>
      );
    }

    this.appendNodeProperties(node, errorMessageProps);
  }

  private getSupportedTypes(node: DirectiveNode) {
    const supportedTypes: DirectiveType[] = [];

    (Object.keys(DIRECTIVE_REGISTRY) as DirectiveType[]).forEach((type) => {
      Object.keys(DIRECTIVE_REGISTRY[type]).includes(node.name) &&
        supportedTypes.push(type);
    });

    return supportedTypes;
  }

  private getShortNodeType(type: DirectiveType) {
    return type.replace(/Directive$/i, "");
  }
}

export default ErrorDirective;
