import {
  AbstractDatabase,
  DatabaseExternalInterface,
} from "./Database.interface";
import InMemoryDatabase from "./InMemoryDatabase";

export type DatabaseType = "in-memory";

const getDatabase = <T>(
  _type: DatabaseType,
  props?: DatabaseExternalInterface<T>
): AbstractDatabase<T> => {
  return new InMemoryDatabase(props);
};

export default getDatabase;
