import Link from "@amzn/awsui-components-react/polaris/link";
import React, { ReactNode } from "react";

import formatString from "../format-string";

type Values = string | number | null | undefined;
type DisplayValues = ReactNode | Values | Values[];

interface I18nStrings {
  overflowAction: string;
}

interface GetDisplayValueOptions {
  defaultValue?: ReactNode;
  i18nStrings?: I18nStrings;
  listDelimiter?: string;
  listOverflow?: {
    maxItems: number;
    onShowMore?: () => void;
  };
}

const DEFAULT_I18N_STRINGS: I18nStrings = {
  overflowAction: "Show more (+{0})",
};

const DEFAULT_OPTIONS = {
  defaultValue: "",
  listDelimiter: ", ",
  i18nStrings: DEFAULT_I18N_STRINGS,
};

/**
 * Returns display value to render in tables and key/value pairs
 *
 * @param {DisplayValues} value
 * @param {GetDisplayValueOptions} options
 * @returns {ReactNode}
 */
const getDisplayValue = (
  value?: DisplayValues,
  options: GetDisplayValueOptions = DEFAULT_OPTIONS
) => {
  const {
    defaultValue = DEFAULT_OPTIONS.defaultValue,
    i18nStrings = DEFAULT_OPTIONS.i18nStrings,
    listDelimiter = DEFAULT_OPTIONS.listDelimiter,
    listOverflow,
  } = options;

  if (isEmptyValue(value)) {
    return defaultValue;
  }

  const { overflowAction = DEFAULT_I18N_STRINGS.overflowAction } = i18nStrings;

  if (Array.isArray(value)) {
    let visibleItems = value.filter((v) => !isEmptyValue(v));
    let overflow: ReactNode = "";

    if (
      typeof listOverflow?.maxItems === "number" &&
      visibleItems.length &&
      listOverflow.maxItems < visibleItems.length
    ) {
      const overflowCount = visibleItems.length - listOverflow.maxItems;
      visibleItems = visibleItems.slice(0, listOverflow.maxItems);
      overflow = formatString(overflowAction, overflowCount);

      if (listOverflow.onShowMore) {
        overflow = <Link onFollow={listOverflow.onShowMore}>{overflow}</Link>;
      }
    }

    return visibleItems.length ? (
      <>
        {visibleItems.map(String).join(listDelimiter)}
        {overflow ? (
          <>
            {"\n"}
            {overflow}
          </>
        ) : null}
      </>
    ) : (
      defaultValue
    );
  }

  return String(value);
};

const isEmptyValue = (value?: DisplayValues) =>
  value === undefined ||
  value === null ||
  (typeof value === "string" && !value.trim());

export default getDisplayValue;
