import Box from "@amzn/awsui-components-react/polaris/box";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import Link from "@amzn/awsui-components-react/polaris/link";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";

import content from "./content";
import styles from "./ErrorPage.module.scss";
import { ErrorPageProps, TestId } from "./types";

const ErrorPage: FC<ErrorPageProps> = ({
  type = "404",
  imgSrc,
  title,
  message,
  actions,
}) => {
  const history = useHistory();
  const templateContent = content[type];
  imgSrc = imgSrc || templateContent.imgSrc;
  title = title || templateContent.title;
  message = message || templateContent.message;

  return (
    <Box
      className={styles.container}
      padding={{ vertical: "xl" }}
      data-testid="error-page-component"
    >
      <Box>
        <img
          data-testid={TestId.Image}
          className={styles.graphic}
          src={imgSrc}
          alt="Error page graphic"
        />
      </Box>
      <Box
        data-testid={TestId.Title}
        margin={{ top: "m", bottom: "xs" }}
        variant="h2"
        fontSize="heading-xl"
        fontWeight="normal"
      >
        {title}
      </Box>
      <Box data-testid={TestId.Message} variant="p">
        {message}
      </Box>
      {actions?.length ? (
        <Box margin={{ top: "m" }} padding={{ right: "xxxl" }}>
          <ColumnLayout data-testid={TestId.Actions} columns={4} disableGutters>
            {actions.map(({ text, href, external }) => (
              <Link
                key={href}
                href={href}
                external={external}
                onFollow={(e) => {
                  !external && e.preventDefault();
                  history.push(href);
                }}
              >
                {text}
              </Link>
            ))}
          </ColumnLayout>
        </Box>
      ) : null}
    </Box>
  );
};
<ErrorPage type="404" />;
export * from "./types";
export default ErrorPage;
