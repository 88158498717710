import Box from "@amzn/awsui-components-react/polaris/box";
import React, { FC } from "react";

import styles from "./ValueWithLabel.module.scss";

export interface ValueWithLabelProps {
  label: string;
  breakSpaces?: boolean;
}

const ValueWithLabel: FC<ValueWithLabelProps> = ({
  label,
  breakSpaces,
  children,
}) => {
  return (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <Box>
        <span className={breakSpaces ? styles.breakSpaces : undefined}>
          {children}
        </span>
      </Box>
    </div>
  );
};

export default ValueWithLabel;
