import convertStreamToText from "./../convert-stream-to-text";

const convertStreamToJson = async <S extends any>(
  body: BodyInit
): Promise<S | undefined> => {
  try {
    const text: string = await convertStreamToText(body);
    return JSON.parse(text);
  } catch (e) {
    return undefined;
  }
};

export default convertStreamToJson;
