import Box from "@amzn/awsui-components-react/polaris/box";
import Button, {
  ButtonProps,
} from "@amzn/awsui-components-react/polaris/button";
import Modal, { ModalProps } from "@amzn/awsui-components-react/polaris/modal";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import React, { useCallback, useEffect, useState } from "react";
import { DeepPartial } from "types";

export enum TestIDs {
  ActionPrimary = "action-primary",
  ActionSecondary = "action-secondary",
  ActionTertiary = "action-tertiary",
}

interface ModalAction extends Omit<ButtonProps, "children"> {
  text: string;
}

interface ModalActions {
  primary: ModalAction;
  secondary: ModalAction;
  tertiary: ModalAction;
}

export interface UseModalProps {
  modalHeader: ModalProps["header"];
  content: React.ReactNode;
  actions?: Partial<ModalActions>;
  /**
   * Sets initial visibility of modal.
   * Visibility state is managed internally by this hook
   */
  isVisible?: boolean;
  /**
   * Gets invoked when the user dismissed the modal (clicks
   * on the X close icon at the)
   */
  onDismiss?: ModalProps["onDismiss"];
  optionalModalProps?: DeepPartial<ModalProps>;
}

const useModal = ({
  modalHeader,
  content,
  actions,
  isVisible,
  onDismiss,
  optionalModalProps,
}: UseModalProps) => {
  const [modalVisible, setModalVisible] = useState(isVisible);

  useEffect(() => {
    if (isVisible) {
      showModal();
    } else {
      hideModal();
    }

    return () => {
      hideModal();
    };
  }, [isVisible]);

  const showModal = useCallback(() => {
    setModalVisible(true);
  }, [setModalVisible]);

  const hideModal = useCallback(() => {
    setModalVisible(false);
  }, [setModalVisible]);

  const onModalDismiss = useCallback(
    (event) => {
      hideModal();
      onDismiss && onDismiss(event);
    },
    [onDismiss]
  );

  const modalComponent = modalVisible ? (
    <Modal
      closeAriaLabel="Close"
      onDismiss={onModalDismiss}
      visible={modalVisible}
      size="medium"
      footer={
        actions &&
        (actions.primary || actions?.secondary || actions?.tertiary) && (
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              {actions?.tertiary && (
                <Button
                  data-testid={TestIDs.ActionTertiary}
                  variant="link"
                  {...actions.tertiary}
                >
                  {actions.tertiary.text}
                </Button>
              )}
              {actions?.secondary && (
                <Button
                  data-testid={TestIDs.ActionSecondary}
                  variant="normal"
                  {...actions.secondary}
                >
                  {actions.secondary.text}
                </Button>
              )}
              {actions?.primary && (
                <Button
                  data-testid={TestIDs.ActionPrimary}
                  variant="primary"
                  {...actions.primary}
                >
                  {actions.primary.text}
                </Button>
              )}
            </SpaceBetween>
          </Box>
        )
      }
      header={modalHeader}
      {...optionalModalProps}
    >
      {content}
    </Modal>
  ) : null;

  return { modalComponent, showModal, hideModal, visible: modalVisible };
};

export default useModal;
