import PolarisTabs, {
  TabsProps as PolarisTabsProps,
} from "@amzn/awsui-components-react/polaris/tabs";
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { TabsContext } from "../../contexts/TabsProvider";
import {
  DirectiveName,
  DirectiveProperties,
  DirectiveType,
} from "../../hooks/use-directive/types";
import getAllowedProps from "../../utility/get-allowed-props";
import ErrorMessage from "../ErrorMessage";
import css from "./Tabs.module.scss";

export interface TabsProps
  extends Pick<PolarisTabsProps, "variant" | "activeTabId"> {
  groupId?: string;
}

const allowedTabsProps: (keyof TabsProps)[] = ["variant", "activeTabId"];
const allowedVariants: PolarisTabsProps.Variant[] = ["container", "default"];
const errorMessage = (
  <ErrorMessage
    type="childDirectiveDependency"
    message="Tabs directive must have at least one child tab directive declared"
  />
);

const Tabs: FC<TabsProps> = ({ children, ...attributes }) => {
  const {
    getTabsGroupActiveTab,
    setTabsGroupActiveTab,
    tabsGroupActiveTabs,
  } = useContext(TabsContext);
  const tabs = useMemo<PolarisTabsProps.Tab[] | undefined>(() => {
    if (!(Array.isArray(children) && children?.length)) {
      return;
    }

    const tabContainers = children.filter((child) => {
      const directiveProps:
        | DirectiveProperties
        | undefined = (child as React.ReactElement).props.directive;

      return (
        directiveProps &&
        directiveProps.type === DirectiveType.CONTAINER &&
        directiveProps.name === DirectiveName.TAB
      );
    });

    if (!tabContainers.length) {
      return;
    }

    return tabContainers.map((tab: any, index: number) => {
      const {
        label = `Tab ${index + 1}`,
        id = `tab-${index + 1}`,
        disabled = false,
        children,
      } = tab.props;

      return {
        label,
        id,
        disabled,
        content: <div className={css.content}>{children}</div>,
      };
    });
  }, [children]);
  const [activeTabId, setActiveTabId] = useState(
    attributes.activeTabId || tabs?.[0].id
  );
  const setActiveTabIdWrapper = useCallback<
    NonNullable<PolarisTabsProps["onChange"]>
  >(
    ({ detail: { activeTabId } }) => {
      if (setTabsGroupActiveTab && attributes.groupId) {
        setTabsGroupActiveTab(attributes.groupId, activeTabId);
      } else {
        setActiveTabId(activeTabId);
      }
    },
    [attributes]
  );

  // Whenever a tab group active tab value is set in the shared context,
  // we need to set the tabs activeTabId value to synchronize like groups
  useEffect(() => {
    if (!tabs) {
      return;
    }

    if (getTabsGroupActiveTab && attributes.groupId) {
      let id = getTabsGroupActiveTab(attributes.groupId);

      if (!id || !tabs.find((tab) => tab.id === id)) {
        id = tabs[0].id;
      }

      setActiveTabId(id);
    }
  }, [tabs, attributes, tabsGroupActiveTabs]);

  if (attributes.variant && !allowedVariants.includes(attributes.variant)) {
    attributes.variant = undefined;
  }

  if (tabs === undefined) {
    return errorMessage;
  }

  return (
    <div className={css.tabs}>
      <PolarisTabs
        {...getAllowedProps(allowedTabsProps, attributes)}
        activeTabId={activeTabId}
        onChange={setActiveTabIdWrapper}
        tabs={tabs}
      />
    </div>
  );
};

export default Tabs;
