import { useEffect, useRef, useState } from "react";

const isDarkModeCSSClassPresent = (target: HTMLElement) =>
  target.classList.contains("awsui-polaris-dark-mode");

const useIsDarkMode = (target = document.body) => {
  const [isDarkMode, setIsDarkMode] = useState(
    isDarkModeCSSClassPresent(target)
  );
  const { current: observer } = useRef(
    new MutationObserver(() => setIsDarkMode(isDarkModeCSSClassPresent(target)))
  );

  useEffect(() => {
    observer.observe(target, {
      attributes: true,
      attributeFilter: ["class"],
      childList: false,
      characterData: false,
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return isDarkMode;
};

export default useIsDarkMode;
