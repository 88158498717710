import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import arduino from "react-syntax-highlighter/dist/esm/languages/prism/arduino";
import bash from "react-syntax-highlighter/dist/esm/languages/prism/bash";
import c from "react-syntax-highlighter/dist/esm/languages/prism/c";
import cpp from "react-syntax-highlighter/dist/esm/languages/prism/cpp";
import csharp from "react-syntax-highlighter/dist/esm/languages/prism/csharp";
import css from "react-syntax-highlighter/dist/esm/languages/prism/css";
import dart from "react-syntax-highlighter/dist/esm/languages/prism/dart";
import diff from "react-syntax-highlighter/dist/esm/languages/prism/diff";
import docker from "react-syntax-highlighter/dist/esm/languages/prism/docker";
import git from "react-syntax-highlighter/dist/esm/languages/prism/git";
import go from "react-syntax-highlighter/dist/esm/languages/prism/go";
import graphql from "react-syntax-highlighter/dist/esm/languages/prism/graphql";
import java from "react-syntax-highlighter/dist/esm/languages/prism/java";
import javascript from "react-syntax-highlighter/dist/esm/languages/prism/javascript";
import jq from "react-syntax-highlighter/dist/esm/languages/prism/jq";
import json from "react-syntax-highlighter/dist/esm/languages/prism/json";
import jsx from "react-syntax-highlighter/dist/esm/languages/prism/jsx";
import kotlin from "react-syntax-highlighter/dist/esm/languages/prism/kotlin";
import less from "react-syntax-highlighter/dist/esm/languages/prism/less";
import lisp from "react-syntax-highlighter/dist/esm/languages/prism/lisp";
import llvm from "react-syntax-highlighter/dist/esm/languages/prism/llvm";
import lua from "react-syntax-highlighter/dist/esm/languages/prism/lua";
import makefile from "react-syntax-highlighter/dist/esm/languages/prism/makefile";
import markdown from "react-syntax-highlighter/dist/esm/languages/prism/markdown";
import markup from "react-syntax-highlighter/dist/esm/languages/prism/markup";
import nginx from "react-syntax-highlighter/dist/esm/languages/prism/nginx";
import objectivec from "react-syntax-highlighter/dist/esm/languages/prism/objectivec";
import perl from "react-syntax-highlighter/dist/esm/languages/prism/perl";
import php from "react-syntax-highlighter/dist/esm/languages/prism/php";
import powershell from "react-syntax-highlighter/dist/esm/languages/prism/powershell";
import python from "react-syntax-highlighter/dist/esm/languages/prism/python";
import r from "react-syntax-highlighter/dist/esm/languages/prism/r";
import regex from "react-syntax-highlighter/dist/esm/languages/prism/regex";
import ruby from "react-syntax-highlighter/dist/esm/languages/prism/ruby";
import rust from "react-syntax-highlighter/dist/esm/languages/prism/rust";
import sass from "react-syntax-highlighter/dist/esm/languages/prism/sass";
import scss from "react-syntax-highlighter/dist/esm/languages/prism/scss";
import sql from "react-syntax-highlighter/dist/esm/languages/prism/sql";
import swift from "react-syntax-highlighter/dist/esm/languages/prism/swift";
import toml from "react-syntax-highlighter/dist/esm/languages/prism/toml";
import tsx from "react-syntax-highlighter/dist/esm/languages/prism/tsx";
import typescript from "react-syntax-highlighter/dist/esm/languages/prism/typescript";
import vim from "react-syntax-highlighter/dist/esm/languages/prism/vim";
import wasm from "react-syntax-highlighter/dist/esm/languages/prism/wasm";
import yaml from "react-syntax-highlighter/dist/esm/languages/prism/yaml";

import cedar from "./prism/cedar";

// full list https://github.com/wooorm/refractor#data
const languages = [
  arduino,
  bash,
  c,
  cpp,
  css,
  csharp,
  dart,
  diff,
  docker,
  git,
  go,
  graphql,
  java,
  javascript,
  jq,
  json,
  jsx,
  kotlin,
  less,
  lisp,
  llvm,
  lua,
  makefile,
  markdown,
  markup,
  nginx,
  objectivec,
  perl,
  php,
  powershell,
  python,
  r,
  regex,
  ruby,
  rust,
  sass,
  scss,
  sql,
  swift,
  toml,
  tsx,
  typescript,
  vim,
  wasm,
  yaml,

  // custom languages
  cedar,
];

languages.forEach((language) => {
  SyntaxHighlighter.registerLanguage(language.name, language);
});

export default SyntaxHighlighter;
