import Container from "@amzn/awsui-components-react/polaris/container";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import React, { FC, useMemo } from "react";

import { TimelineStep } from "./components";
import { defaultI18nStrings } from "./Timeline.constants";
import { TimelineProps } from "./Timeline.interface";
import css from "./Timeline.module.scss";

const Timeline: FC<TimelineProps> = ({
  steps,
  activeStep,
  align,
  className,
  direction = "horizontal",
  footer,
  header,
  stepMinWidth,
  stepWidth,
  style,
  variant,
  wrapTitles,
}) => {
  const classNames = useMemo(() => {
    const classList = [className, css.timeline];

    variant === "container" && classList.push(css.contentPadding);
    direction === "vertical" && classList.push(css.directionVertical);
    stepWidth === "contain" && classList.push(css.containStepWidth);
    align && classList.push(css[`align-${align}`]);

    return classList.join(" ");
  }, [align, className, direction, stepWidth, variant]);
  const timelineContent = (
    <ol className={classNames} style={style}>
      {steps.map(({ id, i18nStrings = {}, status, info }, index) => {
        index++;
        const {
          title = `${defaultI18nStrings.stepTitlePrefix} ${index}`,
          description,
        } = i18nStrings;
        return (
          <TimelineStep
            key={id}
            id={id}
            title={title}
            active={activeStep === id}
            description={description}
            info={info}
            minWidth={stepMinWidth}
            status={status}
            index={index}
            wrapTitle={wrapTitles}
          />
        );
      })}
    </ol>
  );

  if (variant === "container") {
    return (
      <Container footer={footer} header={header} disableContentPaddings>
        {timelineContent}
      </Container>
    );
  } else {
    return (
      <SpaceBetween direction="vertical" size="s">
        {header ? header : null}
        {timelineContent}
        {footer ? footer : null}
      </SpaceBetween>
    );
  }
};

export { TimelineProps } from "./Timeline.interface";
export default Timeline;
