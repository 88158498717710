import { i18nStrings } from "./aws-cli-env-credentials.constants";
import { AwsCliCredentials, CliEnv } from "./aws-cli-env-credentials.interface";

abstract class Creator {
  protected readonly credentials: AwsCliCredentials;
  protected readonly defaultRegion?: string;

  constructor(credentials: AwsCliCredentials, defaultRegion?: string) {
    this.credentials = credentials;
    this.defaultRegion = defaultRegion;
  }
  abstract readonly env: CliEnv;

  abstract getCommands(): string[];

  getName() {
    return i18nStrings.cliNames[this.env];
  }

  toString() {
    return this.getCommands().join("\n");
  }
}

export class EnvBash extends Creator {
  env = CliEnv.Bash;

  getCommands(): string[] {
    return [
      ...(this.defaultRegion
        ? [`export AWS_DEFAULT_REGION="${this.defaultRegion}"`]
        : []),
      `export AWS_ACCESS_KEY_ID="${this.credentials.accessKeyId}"`,
      `export AWS_SECRET_ACCESS_KEY="${this.credentials.secretAccessKey}"`,
      `export AWS_SESSION_TOKEN="${this.credentials.sessionToken}"`,
    ];
  }
}

export class EnvFish extends Creator {
  env = CliEnv.Fish;

  getCommands(): string[] {
    return [
      ...(this.defaultRegion
        ? [`set -x AWS_DEFAULT_REGION "${this.defaultRegion}"`]
        : []),
      `set -x AWS_ACCESS_KEY_ID "${this.credentials.accessKeyId}"`,
      `set -x AWS_SECRET_ACCESS_KEY "${this.credentials.secretAccessKey}"`,
      `set -x AWS_SESSION_TOKEN "${this.credentials.sessionToken}"`,
    ];
  }
}

export class EnvWindowsPowerShell extends Creator {
  env = CliEnv.WindowsPowerShell;

  getCommands(): string[] {
    return [
      ...(this.defaultRegion
        ? [`$Env:AWS_DEFAULT_REGION="${this.defaultRegion}"`]
        : []),
      `$Env:AWS_ACCESS_KEY_ID="${this.credentials.accessKeyId}"`,
      `$Env:AWS_SECRET_ACCESS_KEY="${this.credentials.secretAccessKey}"`,
      `$Env:AWS_SESSION_TOKEN="${this.credentials.sessionToken}"`,
    ];
  }
}

export class EnvWindowsCmd extends Creator {
  env = CliEnv.WindowsCmd;

  getCommands(): string[] {
    return [
      ...(this.defaultRegion
        ? [`set AWS_DEFAULT_REGION=${this.defaultRegion}`]
        : []),
      `set AWS_ACCESS_KEY_ID=${this.credentials.accessKeyId}`,
      `set AWS_SECRET_ACCESS_KEY=${this.credentials.secretAccessKey}`,
      `set AWS_SESSION_TOKEN=${this.credentials.sessionToken}`,
    ];
  }
}

const awsCliEnvCredentials = (
  credentials: AwsCliCredentials,
  defaultRegion?: string
) => [
  new EnvBash(credentials, defaultRegion),
  new EnvFish(credentials, defaultRegion),
  new EnvWindowsPowerShell(credentials, defaultRegion),
  new EnvWindowsCmd(credentials, defaultRegion),
];

export default awsCliEnvCredentials;
