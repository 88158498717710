import isEqual from "lodash.isequal";
import { useRef } from "react";

/**
 * usePrevious allows us to access the last value before the state
 * was updated.
 *
 * @param value current value
 * @param initialPrevValue previous value before an update
 */
const usePrevious = <T,>(value: T, initialPrevValue: T): T | undefined => {
  const { current } = useRef({ target: value, value: initialPrevValue });

  if (!isEqual(current.target, value)) {
    current.value = current.target;
    current.target = value;
  }

  return current.value;
};

export default usePrevious;
