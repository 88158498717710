import Cookies from "lib/cookies";
import LocalStorageProvider from "lib/LocalStorageProvider";
import Logger from "lib/Logger/Logger";

import { StoragePartitionKeys } from "./types";

const logger = Logger.getLogger("StoragePartition");
const cookies = Cookies.getInstance();
export const STORAGE_PARTITION_PREFIX: string = "11_22_2021";

/**
 * If a new field is added that requires a new behavior,
 * bust the cache by modifying STORAGE_PARTITION_PREFIX.
 *
 * (i.e. we want all users to see a new column by default on a table)
 */
const generatePartitionKey = (partitionKey: StoragePartitionKeys): string => {
  return `${STORAGE_PARTITION_PREFIX}_${partitionKey}`;
};

/**
 * Gets a deeply nested partitioned item in storage
 * @param {StoragePartitionKeys} partitionKey
 * @param {...string} keys
 * @returns {*}
 */
const getItem = (partitionKey: StoragePartitionKeys, ...keys: string[]) => {
  const key = generatePartitionKey(partitionKey);

  let ref = JSON.parse(LocalStorageProvider.getItem(key) as string);
  if (!keys.length) {
    return ref;
  }
  for (const key of keys) {
    if (!ref?.[key]) {
      return;
    }
    ref = ref[key];
  }
  return ref;
};

/**
 * Sets a deeply nested partitioned item in storage
 * @param {StoragePartitionKeys} partitionKey
 * @param {*} value
 * @param {...string} keys
 * @returns {boolean}
 */
const setItem = (
  partitionKey: StoragePartitionKeys,
  value: any,
  ...keys: string[]
) => {
  if (!cookies.hasConsent(partitionKey)) {
    logger.info(
      `user did not provide consent, "${partitionKey}" storage will not be set`
    );
    return;
  }

  const key = generatePartitionKey(partitionKey);

  // If no keys are specified, replace storage partition with value
  if (!keys.length) {
    LocalStorageProvider.setItem(key, JSON.stringify(value));
    return;
  }
  const partitionedData =
    JSON.parse(`${LocalStorageProvider.getItem(key)}`) || {};
  let ref = partitionedData;
  for (let i = 0, len = keys.length; i < len - 1; i++) {
    const key = keys[i];
    // Create object if referenced object does not exist
    if (!ref[key]) {
      ref[key] = {};
    }
    ref = ref[key];
  }
  ref[keys[keys.length - 1]] = value;
  return LocalStorageProvider.setItem(key, JSON.stringify(partitionedData));
};

/**
 * Clears a specified partition.
 * If no partition is specified, clear all storage
 * @param partitionKey
 * @returns {boolean}
 */
const clear = (partitionKey?: StoragePartitionKeys) => {
  if (!partitionKey) {
    return LocalStorageProvider.clear();
  }

  return LocalStorageProvider.removeItem(generatePartitionKey(partitionKey));
};

export default {
  getItem,
  setItem,
  clear,
};
