import Box from "@amzn/awsui-components-react/polaris/box";
import Grid, { GridProps } from "@amzn/awsui-components-react/polaris/grid";
import { useIsDarkMode } from "hooks";
import React from "react";

import styles from "./LandingPageOverview.module.scss";

interface ILandingPageOverviewConfig {
  darkIcon: string;
  lightIcon: string;
  iconAltText: string;
  emphasisText: string;
  text: JSX.Element;
}

export const getStyleByKey = (key: string): string => styles[key];

const [
  customBannerContainerText,
  darkBannerText,
  lightBannerText,
  customBannerContainerItem,
  imageStyle,
  emphasizedTextStyle,
  a,
] = [
  getStyleByKey("custom-banner-container__text"),
  getStyleByKey("darkBannerText"),
  getStyleByKey("lightBannerText"),
  getStyleByKey("custom-banner-container__item"),
  getStyleByKey("custom-banner-container__image"),
  getStyleByKey("custom-banner-container__emphasizedText"),
  getStyleByKey("a"),
];

interface IGridContainer {
  isWebConsoleLandingPageOverview?: boolean;
  gridDefinition?: readonly GridProps.ElementDefinition[];
}

export interface LandingPageOverviewProps {
  config: ILandingPageOverviewConfig[];
  gridContainer?: IGridContainer;
  gridItemContainer?: IGridContainer;
  target?: HTMLElement | undefined;
  enableNumbering?: boolean;
}

interface BannerItemProps {
  item: ILandingPageOverviewConfig;
  isDarkMode: boolean;
  bannerIndex?: number;
}

const BannerItem = ({ item, isDarkMode, bannerIndex }: BannerItemProps) => {
  const bannerTextStyles = [
    customBannerContainerText,
    isDarkMode ? darkBannerText : lightBannerText,
  ];

  return (
    <div className={customBannerContainerItem}>
      <img
        className={imageStyle}
        src={isDarkMode ? item.darkIcon : item.lightIcon}
        alt={item.iconAltText}
      />
      <Box variant="p" fontWeight="light" key={item.emphasisText}>
        {bannerIndex ? `${bannerIndex}. ` : ""}{" "}
        <b className={styles[emphasizedTextStyle]}>{item.emphasisText}</b>{" "}
        <span className={bannerTextStyles.join(" ")}>{item.text}</span>
      </Box>
    </div>
  );
};

const LandingPageOverview: React.FC<LandingPageOverviewProps> = ({
  target,
  config,
  gridContainer = {},
  gridItemContainer = {},
  enableNumbering = false,
}: LandingPageOverviewProps) => {
  const isDarkMode = useIsDarkMode(target);

  return (
    <Grid
      className={gridContainer.isWebConsoleLandingPageOverview ? a : ""}
      gridDefinition={gridContainer.gridDefinition}
    >
      <Grid gridDefinition={gridItemContainer.gridDefinition}>
        {config.map((c, index) => {
          return (
            <BannerItem
              key={c.emphasisText}
              item={c}
              isDarkMode={isDarkMode}
              bannerIndex={enableNumbering ? index + 1 : undefined}
            />
          );
        })}
      </Grid>
    </Grid>
  );
};

export default LandingPageOverview;
