import { useCallback, useContext } from "react";
import { visit } from "unist-util-visit";

import { MarkdownRendererContext } from "../../contexts/MarkdownRendererProvider";
import { DIRECTIVE_REGISTRY } from "./constants";
import ErrorDirective from "./directives/error-directive";
import { DirectiveName, DirectiveType } from "./types";

const errorDirective = new ErrorDirective();

const useDirective = () => {
  const { sourceContent, config } = useContext(MarkdownRendererContext);
  const plugin = useCallback(() => {
    return transform;
    function transform(tree: any) {
      visit(
        tree,
        [DirectiveType.TEXT, DirectiveType.LEAF, DirectiveType.CONTAINER],
        (node) => {
          const type: DirectiveType = node.type;
          const name: DirectiveName = node.name;
          let directive = DIRECTIVE_REGISTRY[type]?.[name];

          if (!directive) {
            directive = errorDirective;
          }

          directive.process(node, {
            sourceContent,
            config,
          });
        }
      );
    }
  }, []);

  return plugin;
};

export default useDirective;
