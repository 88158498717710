import TopNavigation, {
  TopNavigationProps,
} from "@amzn/awsui-components-react/polaris/top-navigation";
import { DarkModeContext } from "context";
import React, { useContext, useMemo } from "react";

import Logo from "./../../assets/logo.png";
import styles from "./AppTopNavigation.module.scss";
import PublicPreviewBadge from "./components/PublicPreviewBadge";
import { constants } from "./utils";

const { i18nStrings, ItemIds } = constants;

export interface AppTopNavigationProps {
  identity: Pick<TopNavigationProps.Identity, "href" | "onFollow">;
  id?: string;
  className?: string;
  sticky?: boolean;
  utilities?: TopNavigationProps.Utility[];
  publicPreview?: boolean;
}

const AppTopNavigation = ({
  identity,
  id,
  className,
  sticky,
  utilities = [],
  publicPreview,
}: AppTopNavigationProps): JSX.Element => {
  const classNames = useMemo(() => {
    const classes = [styles.topNavigation];

    if (className) {
      classes.push(className.trim());
    }

    if (sticky) {
      classes.push(styles.sticky);
    }

    return classes.join(" ");
  }, [className, sticky]);
  const { enabled: isDarkMode, setEnabled: setIsDarkMode } = useContext(
    DarkModeContext
  );
  const allUtilities = useMemo<TopNavigationProps.Utility[]>(() => {
    const systemUtilities: TopNavigationProps.Utility[] = [];

    if (setIsDarkMode) {
      systemUtilities.push({
        type: "menu-dropdown",
        iconName: "settings",
        ariaLabel: i18nStrings.utilities.settings,
        title: i18nStrings.utilities.settings,
        onItemClick: ({ detail: { id } }) => {
          switch (id) {
            case ItemIds.DarkMode:
              setIsDarkMode(!isDarkMode);
              break;
          }
        },
        items: [
          {
            id: ItemIds.DarkMode,
            text: isDarkMode ? i18nStrings.lightMode : i18nStrings.darkMode,
          },
        ],
      });
    }

    return [...systemUtilities, ...utilities];
  }, [utilities, isDarkMode, setIsDarkMode]);
  const identityProps = useMemo<TopNavigationProps.Identity>(() => {
    if (!publicPreview) {
      return {
        ...identity,
        logo: {
          src: Logo,
          alt: i18nStrings.logoAlt,
        },
      };
    }

    /**
     * If we want to include a secondary action to the header,
     * the logo anchor must be created manually in the title. The
     * identity href and onFollow are applied to the parent container
     * of the logo and text, which will cause event propagation issues
     * for the secondary action.
     */
    return {
      href: undefined!,
      title: ((
        <span className={styles.customLogoContainer}>
          <a
            href={identity.href}
            onClick={(e) => identity.onFollow?.(e as any)}
          >
            <img src={Logo} alt={i18nStrings.logoAlt} />
          </a>
          <span className={styles.badge}>
            <PublicPreviewBadge />
          </span>
        </span>
      ) as unknown) as string,
    };
  }, [publicPreview, identity]);

  return (
    <TopNavigation
      id={id}
      className={classNames}
      identity={identityProps}
      utilities={allUtilities}
      i18nStrings={i18nStrings.topNavigation}
    />
  );
};
export default AppTopNavigation;
