import Alert from "@amzn/awsui-components-react/polaris/alert";
import TextContent from "@amzn/awsui-components-react/polaris/text-content";
import React, { FC } from "react";

import css from "./ErrorMessage.module.scss";

const errorTypes = {
  generic: "Content error",
  unknownDirective: "Unknown directive",
  invalidDirectiveType: "Invalid directive type",
  childDirectiveDependency: "Missing child directive",
};

export type ErrorTypes = keyof typeof errorTypes;

export interface ErrorMessageProps {
  type?: ErrorTypes;
  message?: React.ReactNode;
}

const ErrorMessage: FC<ErrorMessageProps> = ({ type = "generic", message }) => (
  <div className={css.errorMessage}>
    <Alert header={errorTypes[type] || errorTypes.generic} type="error">
      <TextContent>
        <pre className={css.message} data-testid="strategy-error-message">
          {message}
        </pre>
      </TextContent>
    </Alert>
  </div>
);

export default ErrorMessage;
