import CopyButton from "components/CopyButton";
import React, { FC, useEffect, useState } from "react";

import { CodeBlockProps } from "./CodeBlock.interface";
import styles from "./CodeBlock.module.scss";

// singleton counter to dedupe chart IDs
let codeInstanceCount = 0;

const CodeBlock: FC<CodeBlockProps> = ({
  children,
  showCopyAction,
  wrapLines,
  ...testProps
}) => {
  const [codeInstanceId, setCodeInstanceId] = useState("");
  const classNames = [styles.codeBlock];

  showCopyAction && classNames.push(styles.hasCopyAction);
  wrapLines && classNames.push(styles.wrapLines);

  useEffect(() => {
    setCodeInstanceId(`code-block-${++codeInstanceCount}`);
  }, []);

  return (
    <pre {...testProps} className={styles.container} dir="ltr">
      <code className={classNames.join(" ")} id={codeInstanceId}>
        {children}
      </code>
      {showCopyAction && (
        <div className={styles.copyAction}>
          <CopyButton elementId={codeInstanceId} />
        </div>
      )}
    </pre>
  );
};

export { CodeBlockProps } from "./CodeBlock.interface";
export default CodeBlock;
