import { MetricsClient } from "clients";
import React, { PropsWithChildren, ReactNode } from "react";
import { Constants, isErrorInExclusionList } from "utils";

import { ILog, Logger, LogLevel } from "../../lib/Logger";

export interface Props {
  fallback: JSX.Element;
  logger: ILog;
  children: PropsWithChildren<ReactNode>;
  addCounterMetric?: MetricsClient["addCounterMetric"];
  onError?: (error: Error, componentStack: string) => void;
}

interface DefaultState {
  error: null;
  info: null;
  hasError: false;
}

interface ErrorState {
  error: Error;
  info: React.ErrorInfo;
  hasError: boolean;
}

type State = DefaultState | ErrorState;

class ErrorBoundary extends React.Component<Props, State> {
  static defaultProps = {
    logger: Logger.getLogger("ErrorBoundary", LogLevel.CRITICAL),
  };

  static getDerivedStateFromError(): { hasError: boolean } {
    return {
      hasError: true,
    };
  }
  state: State = {
    error: null,
    info: null,
    hasError: false,
  };

  public render(): React.ReactNode {
    const { children, fallback = null } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      const FallbackComponent = fallback;
      return FallbackComponent;
    }

    return children;
  }
  public componentDidMount = () => {
    const { logger } = this.props;

    window.addEventListener("error", function (event: ErrorEvent) {
      // benign error, we can safely ignore
      if (isErrorInExclusionList(event)) {
        return;
      }

      logger.critical("Uncaught global error", event);
    });
  };

  public componentDidUpdate = (): void => {
    const { error, info } = this.state;
    const { logger, addCounterMetric } = this.props;

    if (
      error &&
      info &&
      !isErrorInExclusionList(error) &&
      !isErrorInExclusionList(info)
    ) {
      logger.critical("ErrorBoundary Catastrophic error!", error, info);
      addCounterMetric &&
        addCounterMetric(Constants.CloudWatchMetric.ERROR_BOUNDARY_EXCEPTION);
    }
  };

  public componentDidCatch = (error: Error, info: React.ErrorInfo): void => {
    const { onError } = this.props;

    if (onError) {
      try {
        onError.call(this, error, info.componentStack);
      } catch (ignoredError) {
        // Ignore Error
      }
    }

    this.setState({
      error,
      info,
    });
  };
}

export default ErrorBoundary;
