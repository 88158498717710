import Box from "@amzn/awsui-components-react/polaris/box";
import Icon, { IconProps } from "@amzn/awsui-components-react/polaris/icon";
import React, { FC } from "react";

import css from "./SectionHeader.module.scss";

export interface SectionHeaderProps {
  iconName?: IconProps.Name;
}

const SectionHeader: FC<SectionHeaderProps> = ({ children, iconName }) => (
  <Box className={css.title} variant="h5" margin={{ bottom: "s" }}>
    {iconName && (
      <Box margin={{ right: "xs" }}>
        <Icon name={iconName} variant="disabled" />
      </Box>
    )}
    {children}
  </Box>
);

export default SectionHeader;
