import { Hub } from "aws-amplify";
import React, { FC, useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

export interface SignInCallbackProps {
  defaultRedirectPath?: string;
  onSignIn?: () => void;
  onSignInFailure?: () => void;
}

const SignInCallback: FC<SignInCallbackProps> = ({
  children,
  defaultRedirectPath = "/",
  onSignIn,
  onSignInFailure,
}) => {
  const history = useHistory();
  const location = useLocation();
  const authListener = useCallback((data) => {
    switch (data.payload.event) {
      case "customOAuthState":
        data.payload.data && history.replace(data.payload.data);
        break;
      case "signIn":
        onSignIn?.();
        break;
      case "signIn_failure":
        onSignInFailure?.();
        break;
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (!params.get("state")) {
      history.replace(defaultRedirectPath);
      return;
    }

    Hub.listen("auth", authListener);

    return () => {
      Hub.remove("auth", authListener);
    };
  }, []);

  return <>{children}</>;
};

export default SignInCallback;
