import { PropertyFilterProps } from "@amzn/awsui-components-react/polaris/property-filter";
import { pascalCase } from "change-case";
import useMetric from "hooks/use-metric";
import { useCallback } from "react";
import Constants from "utils/constants";

const { CloudWatchMetric } = Constants;

const usePropertyFilterMetric = (metricId: string) => {
  const { addCounterMetric } = useMetric();
  const sendFilterMetric = useCallback(
    (tokens: PropertyFilterProps.Query["tokens"]) => {
      tokens.forEach((token) => {
        const propertyKey = token.propertyKey
          ? pascalCase(token.propertyKey)
          : CloudWatchMetric.tablePropertyFilter.FREE_TEXT_QUERY_PROPERTY;
        addCounterMetric(
          propertyKey,
          [
            {
              Name: "Type",
              Value: CloudWatchMetric.tablePropertyFilter.TYPE,
            },
            {
              Name: "Component",
              Value: pascalCase(metricId),
            },
          ],
          1
        );
      });
    },
    [metricId]
  );

  return {
    sendFilterMetric,
  };
};

export default usePropertyFilterMetric;
