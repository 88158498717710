import { ILog, Logger, LogLevel, SinkType } from "lib/Logger";
import { MutableRefObject, useRef } from "react";

/**
 * useLogger allows us to create Loggers using the Logger.getLogger
 * factory method.
 */
const useLogger = (
  loggerName: string,
  logLevel?: LogLevel,
  requestedSinks?: SinkType[]
): [ILog] => {
  const logger: MutableRefObject<ILog> = useRef(
    Logger.getLogger(loggerName, logLevel, requestedSinks)
  );

  return [logger.current];
};

export default useLogger;
