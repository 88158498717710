import Button from "@amzn/awsui-components-react/polaris/button";
import Header from "@amzn/awsui-components-react/polaris/header";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import useNotification from "hooks/use-notification";
import { StoragePartition, StoragePartitionKeys } from "lib";
import React, { createContext, useContext, useEffect, useState } from "react";

import {
  Permissions,
  PermissionsProps,
  PermissionsProviderValue,
} from "./types";
import ViewPermissionsTable from "./ViewPermissionsTable";

export const usePermissionsContext = () => useContext(PermissionsContext);

export const testIds = {
  managePermissionsButton: "manage-permissions",
  refreshPermissionsButton: "refresh-permissions",
};

const PermissionsContext = createContext<PermissionsProviderValue>(
  {} as PermissionsProviderValue
);

const PermissionsTable = ({
  id,
  infoComponent,
  setNotifications,
  i18nStrings,
  onRefetch,
  refetchIsLoading,
  refetchError,
  permissions: permissionsData,
  variant,
  columnDefinitions,
  onFilter,
  EditComponent,
  allPermissionsData,
  disableEdit = false,
  permissionMapping,
}: PermissionsProps<any>): JSX.Element => {
  const [permissions, setPermissions] = useState<Permissions>(permissionsData);

  const [edit, setEdit] = useState(false);

  const { showNotification, clearNotification } = useNotification({
    setNotifications,
  });

  useEffect(() => {
    if (permissionsData) {
      setPermissions(permissionsData);
    }
  }, [permissionsData]);

  // Clear notifications on unmount
  useEffect(() => {
    return () => {
      clearNotification();
    };
  }, []);

  /**
   * Common header shared between permissions views
   */
  const header = (
    <Header
      variant="h2"
      actions={
        !edit && (
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              data-testid={testIds.refreshPermissionsButton}
              onClick={() => {
                setPermissions([]);
                onRefetch();
              }}
              loading={refetchIsLoading}
              iconName="refresh"
              ariaLabel="Refresh data"
            />
            <Button
              data-testid={testIds.managePermissionsButton}
              iconName="edit"
              disabled={disableEdit}
              onClick={() => {
                clearNotification();
                setEdit(true);
              }}
            >
              {i18nStrings.editCtaText}
            </Button>
          </SpaceBetween>
        )
      }
      counter={
        !edit && permissions ? `(${permissions?.length || 0})` : undefined
      }
      info={infoComponent}
    >
      {edit ? i18nStrings.tableTitle.edit : i18nStrings.tableTitle.view}
    </Header>
  );

  const storageKey =
    variant === "workshops"
      ? "contentPermissionsTable"
      : "eventPermissionsTable";

  // Not editing, display the static permissions table
  if (!edit) {
    return (
      <ViewPermissionsTable
        header={header}
        loading={refetchIsLoading}
        error={refetchError}
        permissions={permissions || []}
        storageKey={storageKey}
        columnDefinitions={columnDefinitions}
        onFilter={onFilter}
        i18nStrings={i18nStrings}
        columnSorting={
          StoragePartition.getItem(
            StoragePartitionKeys.tableSortColumns,
            storageKey
          ) ?? { sortingColumn: { sortingField: "roles" }, isDescending: false }
        }
        tablePreferences={StoragePartition.getItem(
          StoragePartitionKeys.tablePreferences,
          storageKey
        )}
        permissionMapping={permissionMapping}
      />
    );
  }

  return (
    <PermissionsContext.Provider
      value={{
        setEdit,
        showNotification,
        clearNotification,
      }}
    >
      <EditComponent
        onSuccess={onRefetch}
        id={id}
        header={header}
        allPermissionsData={allPermissionsData}
      />
    </PermissionsContext.Provider>
  );
};

export default PermissionsTable;
