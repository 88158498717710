export enum LogLevel {
  VERBOSE = 0,
  DEBUG = 1,
  INFO = 2,
  WARNING = 3,
  ERROR = 4,
  CRITICAL = 5,
}

/**
 * Methods available in each ILog interface
 */
export interface ILog {
  /**
   * Clears all sinks for a specified Logger
   */
  clearSinks(): void;
  /**
   * Adds an ILogSink into the Logger
   */
  addSink(sink: ILogSink): void;
  /**
   * Determines whether or not the logger should be able
   * to log
   *
   * @param level specified Log Level for logger
   */
  isLoggingEnabled(level: LogLevel): boolean;
  /**
   * Returns all log sinks added to the Logger
   */
  getSinks(): ILogSink[];
  /**
   * Returns the name of the logger
   */
  getLoggerName(): string;
  /**
   * Function that modifies logging level
   *
   * @param logLevel specified log level for logger
   */
  setLogLevel(logLevel: LogLevel): void;
  /**
   * Returns the current Loggers log level
   */
  getLogLevel(): LogLevel;
  /**
   * Disables a logger from logging to sinks
   */
  disableLogger(): void;
  /**
   * Verbose log(s)
   *
   * @param msg client side logs spread into an array
   */
  verbose(...msg: any[]): void;
  /**
   * Debug log(s)
   *
   * @param msg client side logs spread into an array
   */
  debug(...msg: any[]): void;
  /**
   * Info log(s)
   *
   * @param msg client side logs spread into an array
   */
  info(...msg: any[]): void;
  /**
   * Warn log(s)
   *
   * @param msg client side logs spread into an array
   */
  warn(...msg: any[]): void;
  /**
   * Error log(s)
   *
   * @param msg client side logs spread into an array
   */
  error(...msg: any[]): void;
  /**
   * Critical log(s)
   *
   * @param message client side log
   * @param msg client side logs spread into an array
   */
  critical(...msg: any[]): void;
}

/**
 * Additional metadata sent to each ILogSink
 *
 * @param {String} identifierRoleArn - Amazon Resource Names (ARNs) uniquely identify AWS resources
 * @param {String} sessionId - unique session UUID
 *
 * @param {Object} device
 * @param {String} device.userAgent - users current browser
 * @param {String} device.language - users default language
 * @param {Boolean} device.isMobileDevice - indicates if the user is on a mobile device
 *
 * @param {Object} timing
 * @param {Object} timing.domContentLoaded - fires when the initial HTML document has been completely loaded and parsed, without waiting for stylesheets, images, and subframes to finish loading.
 * @param {Object} timing.domComplete - when the document.readyState changes to 'complete'
 * @param {Object} timing.domInteractive - when the document.readyState changes to 'interactive'
 */
export interface LogMetadata {
  identifierRoleArn?: string;
  sessionId?: string;
  device?: {
    userAgent: string;
    language: string;
    isMobileDevice: boolean;
    browserType: string;
    osType: string;
  };
  timing?: {
    domContentLoadedMs: number;
    domCompleteMs: number;
    domInteractiveMs: number;
  };
}

/**
 * Message data that gets drilled into each ILogSink
 */
export interface ILogEntry {
  ts: number;
  loggerName: string;
  logLevel: LogLevel;
  msg: any[];
  loggerMetadata?: LogMetadata;
  providerMetadata?: any[];
}

/**
 * Mandatory methods that each Logging sink provides
 */
export interface ILogSink {
  /**
   * Returns the name of the Logging Sink
   */
  getSinkName(): string;
  /**
   * Given a message, output logs to specified stream
   */
  writeEntry(messageData: ILogEntry): void;
}

export interface ILogFactory {
  getLogger(loggerName: string, level?: LogLevel): ILog;
}

export interface ILogProvider<S = any, T = any> {
  getProviderName(): string;
  getData(): S;
  addAsyncData?(asyncData: T): void;
  cache?: S & T;
}
