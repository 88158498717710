import Badge from "@amzn/awsui-components-react/polaris/badge";
import Link from "@amzn/awsui-components-react/polaris/link";
import Popover from "@amzn/awsui-components-react/polaris/popover";
import React, { useRef } from "react";
import Constants from "utils/constants";

import { i18nStrings } from "../utils/constants";
import styles from "./PublicPreviewBadge.module.scss";

const PublicPreviewBadge = () => (
  <div className={styles.container}>
    <Popover
      content={
        <>
          {i18nStrings.popovers.publicPreview.content}{" "}
          <Link href={Constants.ExternalLinks.eventDeliveryLaunch} external>
            {i18nStrings.popovers.publicPreview.readMoreLink}
          </Link>
        </>
      }
      triggerType="custom"
      size="small"
      position="bottom"
    >
      <span className={styles.trigger}>
        <Badge color="blue">{i18nStrings.badges.publicPreview}</Badge>
      </span>
    </Popover>
  </div>
);

export default PublicPreviewBadge;
