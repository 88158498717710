import { Constants } from "utils";
import * as Yup from "yup";

const BaseStringValidator = Yup.string().trim();
const uuidV4RegExp = new RegExp(Constants.REGULAR_EXPRESSIONS.uuidV4);
export const MAX_TEXT_LENGTH = 250;

const COPY_TEXT = {
  REQUIRED: "Required",
  VALID_URL: "Enter a valid URL",
  VALID_UUID_V4: "Enter a valid workshop ID",
  VALID_CONTENT_URL: "This is an invalid URL for the current workshop",
};

type ImpactLevel = 1 | 2 | 3 | 4 | 5;

const FormSchema = Yup.object({
  workshopTitle: BaseStringValidator.required(COPY_TEXT.REQUIRED),
  workshopPage: BaseStringValidator.url(COPY_TEXT.VALID_URL)
    .test({
      message: "",
      test: (workshopPageUrl = "", context) => {
        const { contentId = "", quickLinkId = "" } = context?.parent || {};

        if (!workshopPageUrl) {
          return context.createError({ message: COPY_TEXT.REQUIRED });
        }

        if (workshopPageUrl.indexOf(contentId) === -1) {
          // If a QuickLink ID is provided, check if the URL contains that ID.
          // > 0 is intentional since the URL will never start with the ID.
          if (quickLinkId && workshopPageUrl.indexOf(quickLinkId) > 0) {
            return true;
          }
          return context.createError({
            message: COPY_TEXT.VALID_CONTENT_URL,
          });
        }

        return true;
      },
    })
    .required(),
  issue: BaseStringValidator.min(3)
    .max(MAX_TEXT_LENGTH)
    .required(COPY_TEXT.REQUIRED),
  proposedSolution: BaseStringValidator.max(MAX_TEXT_LENGTH),
  impactLevel: Yup.mixed<ImpactLevel>()
    .oneOf([1, 2, 3, 4, 5])
    .required(COPY_TEXT.REQUIRED),
  assignee: BaseStringValidator.required(COPY_TEXT.REQUIRED),
  contentId: BaseStringValidator.matches(
    uuidV4RegExp,
    COPY_TEXT.VALID_UUID_V4
  ).required(COPY_TEXT.REQUIRED),
  quickLinkId: BaseStringValidator,
});

export type FormSchemaType = Yup.InferType<typeof FormSchema>;
export interface FormSchemaInterface extends Yup.InferType<typeof FormSchema> {}

export default FormSchema;
