const i18nStrings = {
  appName: "Workshop Studio",
  emptyValue: "-",
  errors: {
    notFound: {
      title: "Page not found",
      message:
        "The page you are looking for does not exist. You might have typed the address incorrectly or you might have used an outdated link. Please check the link and try again.",
    },
  },
};

export default i18nStrings;
