import {
  borderRadiusContainer,
  colorBackgroundLayoutMain,
  fontFamilyMonospace,
  spaceScaledXl,
} from "@amzn/awsui-design-tokens/polaris";
import { CSSProperties } from "react";
import { okaidia, prism } from "react-syntax-highlighter/dist/cjs/styles/prism";

export interface SyntaxHighlighterStylesOptions {
  lineHighlightColor: string;
  themeOverrides: Record<string, CSSProperties>;
}

export class SyntaxHighlighterStyles {
  private readonly options?: SyntaxHighlighterStylesOptions;
  private readonly _theme: any;

  constructor(_theme: any, options?: SyntaxHighlighterStylesOptions) {
    this._theme = _theme;
    this.options = options;
  }

  get theme() {
    if (!this.options?.themeOverrides) {
      return this._theme;
    }

    const theme = {
      ...this._theme,
    };

    Object.keys(this.options.themeOverrides).forEach((key) => {
      theme[key] = {
        ...this._theme[key],
        ...this.options?.themeOverrides[key],
      };
    });

    return theme;
  }

  get lineHighlight() {
    if (!this.options?.lineHighlightColor) {
      return {};
    }

    return {
      background: this.options.lineHighlightColor,
      borderColor: this.options.lineHighlightColor,
      borderStyle: "solid",
      borderRadius: "0 2px 2px 0",
      borderWidth: "2.5px 4px 2.5px 0",
    };
  }
}

export const darkModeStyles = new SyntaxHighlighterStyles(okaidia, {
  lineHighlightColor: "rgb(58, 59, 64)",
  themeOverrides: {
    'code[class*="language-"]': {
      fontFamily: "inherit",
      fontSize: "inherit",
      lineHeight: "inherit",
    },
  },
});

export const lightModeStyles = new SyntaxHighlighterStyles(prism, {
  lineHighlightColor: "rgb(200, 227, 252)",
  themeOverrides: {
    'code[class*="language-"]': {
      fontFamily: "inherit",
      fontSize: "inherit",
      lineHeight: "inherit",
      textShadow: undefined,
    },
    operator: {
      background: undefined,
    },
  },
});

export const customStyle: CSSProperties = {
  background: colorBackgroundLayoutMain,
  borderRadius: borderRadiusContainer,
  boxSizing: "border-box",
  fontFamily: fontFamilyMonospace,
  fontSize: 13,
  textShadow: undefined,
};

export const lineNumberContainerStyle: CSSProperties = {
  float: "left",
  fontFamily: "inherit",
  fontSize: "inherit",
  paddingRight: spaceScaledXl,
};

export const lineNumberStyle: CSSProperties = {
  opacity: 0.38,
};
