import Box from "@amzn/awsui-components-react/polaris/box";
import Popover from "@amzn/awsui-components-react/polaris/popover";
import React, { createRef, FC, useLayoutEffect } from "react";

import StepIcon from "../StepIcon";
import {
  ACTIVE_CLASS,
  STATUS_COLOR_MAP,
  TEST_IDS,
} from "./TimelineStep.constants";
import { TimelineStepProps } from "./TimelineStep.interface";
import css from "./TimelineStep.module.scss";

const TimelineStep: FC<TimelineStepProps> = ({
  title,
  active,
  info,
  description,
  minWidth,
  status,
  index,
  wrapTitle,
  id,
}) => {
  const elementRef = createRef<HTMLLIElement>();
  const color = status ? STATUS_COLOR_MAP[status] : STATUS_COLOR_MAP.stopped;

  // TODO (parkyh@): This is difficult and may not even be feasible to test in jest, but will take a look
  //  later to see if there us a way to effectively test functionality related to physical element sizes
  //  and scrolling.
  /* istanbul ignore next */
  useLayoutEffect(() => {
    const element = elementRef.current;
    if (active && element) {
      const parent = element.parentElement;

      if (!parent) {
        return;
      }
      // No scrollable content yet
      if (
        parent.scrollWidth <= parent.clientWidth &&
        parent.scrollHeight <= parent.clientHeight
      ) {
        return;
      }

      let left: number | undefined = undefined;
      let top: number | undefined = undefined;

      // Center scroll if step width is less than the parent width, otherwise position scroll to the left
      if (parent.scrollWidth > parent.clientWidth) {
        left =
          element.offsetLeft -
          (element.clientWidth < parent.clientWidth
            ? (parent.clientWidth - element.clientWidth) / 2
            : 0);
      }
      if (parent.scrollHeight > parent.clientHeight) {
        top =
          element.offsetTop -
          (element.clientHeight < parent.clientHeight
            ? (parent.clientHeight - element.clientHeight) / 2
            : 0);
      }

      (left || top) &&
        parent.scrollTo({
          behavior: "smooth",
          left,
          top,
        });
    }
  }, [active]);

  return (
    <li
      className={`${css.step}${active ? ` ${ACTIVE_CLASS}` : ""}`}
      style={{ minWidth }}
      data-testid={TEST_IDS.STEP}
      ref={elementRef}
    >
      <div data-testid={id} className={css.label}>
        <div className={css.iconColumn}>
          <StepIcon
            status={status}
            index={index}
            variant={active ? "info" : undefined}
          />
        </div>
        <div className={css.contentColumn}>
          <Box
            className={`step-title ${css.stepTitle} ${
              wrapTitle ? css.wrapTitle : ""
            }`}
            color={
              active
                ? color === "text-status-inactive"
                  ? undefined
                  : color
                : color
            }
            data-testid={TEST_IDS.STEP_TITLE}
          >
            {info ? (
              <Popover
                triggerType="text"
                header={info.header}
                content={info.content}
                size={info.size}
              >
                {title}
              </Popover>
            ) : (
              title
            )}
          </Box>
          {description && (
            <Box
              color={active ? "text-body-secondary" : "text-status-inactive"}
              variant="small"
            >
              {description}
            </Box>
          )}
        </div>
      </div>
    </li>
  );
};

export { TimelineStepProps } from "./TimelineStep.interface";
export default TimelineStep;
