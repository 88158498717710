import { CollectionPreferencesProps } from "@amzn/awsui-components-react/polaris/collection-preferences";
import { PaginationProps } from "@amzn/awsui-components-react/polaris/pagination";
import { TableProps } from "@amzn/awsui-components-react/polaris/table";

/**
 * @callback sortingComparator
 * @param {*} a
 * @param {*} b
 * @returns {number}
 */

/**
 * A sort utility for Polaris Table
 * @param {Array} items
 * @param {sortingComparator} comparator
 * @param {boolean} isDescending
 */
export const sortTableItems = (
  items: any[],
  comparator: TableProps.ColumnDefinition<any>["sortingComparator"],
  isDescending?: boolean
) => {
  items.sort((a, b) => comparator!(a, b) * (isDescending ? -1 : 1));
};

export const defaultTablePreferences: CollectionPreferencesProps.Preferences = {
  pageSize: 30,
  wrapLines: false,
};

export const paginationAriaLabels: PaginationProps.Labels = {
  nextPageLabel: "Next page",
  previousPageLabel: "Previous page",
  pageLabel: (pageNumber: number) => `Page ${pageNumber} of all pages`,
};

const pageSelectorOptions: CollectionPreferencesProps.PageSizeOption[] = [
  { value: 10, label: "10 items" },
  { value: 30, label: "30 items" },
  { value: 50, label: "50 items" },
];

export const pageSizePreference: CollectionPreferencesProps.PageSizePreference = {
  title: "Page size",
  options: pageSelectorOptions,
};

export const wrapLinesPreference: CollectionPreferencesProps.WrapLinesPreference = {
  label: "Wrap lines",
  description: "Check to see all the text and wrap the lines",
};

export const collectionPreferencesI18nConstants: Pick<
  CollectionPreferencesProps,
  "title" | "confirmLabel" | "cancelLabel"
> = {
  title: "Preferences",
  confirmLabel: "Confirm",
  cancelLabel: "Cancel",
};

export const textFilterCountTextI18nConstants = {
  singular: "match",
  plural: "matches",
};

export const propertyFilteringI18nConstants = {
  placeholder: "Search by keyword",
  operationAndText: "and",
  operationNotAndText: "and not",
  operationOrText: "or",
  operationNotOrText: "or not",
  clearFiltersText: "Clear filters",
  groupPropertiesText: "Properties",
  groupValuesText: "Values",
  filteringEmpty: "No items found",
  filteringLabel: "Filter items",
  filteringStatusText: "Loading",
};

export const loadingText = "Loading...";
