import path from "path-browserify";

import { LinkAction } from "../components/Link/types";
import { MarkdownRendererConfig } from "../types";
import isURLExternal from "./is-url-external";

interface ResourcePathOptions {
  baseUrls: MarkdownRendererConfig["baseUrls"];
  queryParamString?: string;
  linkAction?: LinkAction;
}

/**
 * Builds a resource path URL based on the provided configuration.
 * This will be leveraged by directives and renders that link or
 * reference assets (e.g. Button, Link, Image, etc.)
 * @param {string} href
 * @param {ResourcePathOptions}
 * @returns {string}
 */
const buildResourcePath = (
  href: string,
  {
    baseUrls,
    queryParamString,
    linkAction = LinkAction.navigate,
  }: ResourcePathOptions
) => {
  if (!href) {
    return "";
  }

  // Return URL fragment links as is. Most browser URL implementations
  // will consider a URL fragment as a valid URL (e.g. #section-1), but
  // some implementations may not (e.g. Node.js)... so we will need this
  // check here first along with the URL constructor check.
  if (href.startsWith("#")) {
    return href;
  }

  // Auto-link behavior prepends protocol to URLs that start with "www.""
  if (href.startsWith("www.")) {
    href = `${location.protocol}//${href}`;
  }

  if (isURLExternal(href)) {
    return href;
  }

  // We need to prepend the origin to the path so that we can use the URL constructor
  const basePath = path.join(
    linkAction !== LinkAction.download || !baseUrls.static
      ? location.origin
      : baseUrls.static,
    // This is needed only for navigation type links
    // since content could be loaded under a sub-route.
    // For download links, we just need the static
    // content origin.
    linkAction === LinkAction.download || !baseUrls.root ? "" : baseUrls.root
  );
  try {
    const linkUrl = new URL(
      path.join(
        basePath,
        // A URL that does not start with a slash is relative to the current page,
        // and we'll need to append the relative path segment if provided
        !href.startsWith("/") && baseUrls.relative ? baseUrls.relative : "",
        href
      )
    );

    if (linkAction === LinkAction.download && queryParamString) {
      const queryParameters = new URLSearchParams(queryParamString);
      queryParameters.forEach((value, key) => {
        linkUrl.searchParams.append(key, value);
      });
    }

    // We need the full URL for download links
    const fullQueryParamString = linkUrl.searchParams.toString();
    return linkAction === LinkAction.download
      ? linkUrl.href
      : `${linkUrl.pathname}${
          fullQueryParamString ? `?${fullQueryParamString}` : ""
        }${linkUrl.hash}`;
  } catch {
    // Return undefined in the case where the URL is invalid
    return;
  }
};

export default buildResourcePath;
