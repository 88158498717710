import { ILog } from "lib";

import CoreJSPolyfill from "./core-js-polyfill/core-js-polyfill";
import ReactAppPolyfills from "./react-app-polyfill/react-app-polyfill";
import SmoothscrollPolyfill from "./smooth-scroll-polyfill/smooth-scroll-polyfill";

/**
 * Load all polyfills. Each polyfill file should have a conditional statement
 * that determines whether or not the polyfill should exist or not
 */
const polyfills = [
  ...ReactAppPolyfills,
  ...SmoothscrollPolyfill,
  ...CoreJSPolyfill,
];

const loadPolyfills = (logger: ILog): Promise<void> => {
  return Promise.all(polyfills)
    .then((polyfills = []) => {
      if (polyfills.length > 0) {
        logger.verbose(`Loading ${polyfills.length} polyfill(s)...`);
      }

      for (const poly of polyfills) {
        const polyfillFn = poly?.default?.polyfill;

        // some polyfills expose a function that needs to be instantiated
        if (typeof polyfillFn === "function") {
          polyfillFn();
        }
      }
    })
    .catch((err) => {
      logger.error("Unable to load polyfills", err);
    });
};

export default loadPolyfills;
