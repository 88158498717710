import { CliEnv, I18nStrings } from "./aws-cli-env-credentials.interface";

export const i18nStrings: I18nStrings = {
  cliNames: {
    [CliEnv.Bash]: "Linux or macOS (bash)",
    [CliEnv.Fish]: "Linux or macOS (fish)",
    [CliEnv.WindowsPowerShell]: "Windows (PowerShell)",
    [CliEnv.WindowsCmd]: "Windows (cmd)",
  },
};
