import { CollectionPreferencesProps } from "@amzn/awsui-components-react/polaris/collection-preferences";
import { PaginationProps } from "@amzn/awsui-components-react/polaris/pagination";
import { PropertyFilterProps } from "@amzn/awsui-components-react/polaris/property-filter";
import { SelectProps } from "@amzn/awsui-components-react/polaris/select";
import { TableProps } from "@amzn/awsui-components-react/polaris/table";
import { ReactNode } from "react";
import { ExtendedFilteringProperty } from "utils/polaris/property-filter/types";

interface ColumnDefinition<ItemType>
  extends TableProps.ColumnDefinition<ItemType>,
    Partial<CollectionPreferencesProps.VisibleContentOption> {}

interface TableI18nStrings {
  title: string;
  description?: string;
  pagination?: PaginationProps.Labels;
  loadingText: string;
  preferences?: {
    title: string;
    cancelLabel: string;
    confirmLabel: string;
    pageSize: {
      optionSuffix: string;
      title: string;
    };
    visibleContent: {
      title: string;
      optionsLabel: string;
    };
    wrapLines: {
      label: string;
      description: string;
    };
    contentDensity?: {
      label: string;
      description: string;
    };
    stickyColumns?: {
      title: string;
      description: string;
      noneOptionLabel: string;
      firstOptionLabel: string;
    };
  };
  filter: PropertyFilterProps.I18nStrings;
  filteredItemsCountSuffix: {
    singular: string;
    plural: string;
  };
  errorMessage?: {
    title: string;
    description?: string;
  };
  emptyMessage: {
    title: string;
    description?: string;
  };
  noMatchMessage: {
    title: string;
    description?: string;
    clearAllLabel: string;
  };
}

export interface CollectionSelectFilter<ItemType> {
  /**
   * Filter callback function.
   */
  filter: (item: ItemType, value: string) => boolean;
  /**
   * Identifier used to track the filter state.
   */
  id: string;
  /**
   * Display label and placeholder.
   */
  label: string;
  /**
   * List of filter options.
   */
  options: SelectProps.Option[];
  /**
   * Custom default option. By default, the option with an empty string value is used.
   */
  defaultOption?: SelectProps.Option;
}

type Nullable<T> = T | null | undefined;

export interface UseTableOptions<ItemType> {
  columnDefinitions: ColumnDefinition<ItemType>[];
  /**
   * Unique table identifier. This is used as the primary storage key.
   */
  id: string;
  i18nStrings: TableI18nStrings;
  /**
   * List of actions to display in the table header actions slot.
   */
  actions?: ReactNode[];
  collectionSelectFilters?: Nullable<CollectionSelectFilter<ItemType>>[];
  contentDensity?: TableProps["contentDensity"];
  /**
   * Disables and hides all filtering controls
   */
  disableFiltering?: boolean;
  /**
   * Hides pagination controls and renders all rows at once
   */
  disablePagination?: boolean;
  /**
   * Action to display in the empty message. This is usually a create action.
   */
  emptyMessageAction?: ReactNode;
  error?: boolean | Error | Object;
  filteringProperties?: ExtendedFilteringProperty[];
  info?: ReactNode;
  isItemDisabled?: TableProps.IsItemDisabled<ItemType>;
  /**
   * The loading state of the data. This is used to display table loading text based on the value of the data:
   * - `false`: Loading text not displayed
   * - `true` and data is `null` or `undefined`: Loading text is displayed
   * - `true` and data is truthy: Loading text is not displayed. This allows data to display while pagination or polling is active.
   */
  loading?: boolean;
  /**
   * Handler to attach to refresh action. Refresh action will not be displayed if this value is not provided.
   */
  onRefresh?: () => void;
  /**
   * Selection change callback. This will usually be a set state action.
   */
  onSelectionChange?: (items: ItemType[]) => void;
  pageSize?: number;
  resizableColumns?: boolean;
  selectedItems?: ItemType[];
  selectionType?: TableProps.SelectionType;
  stickyColumns?: TableProps.StickyColumns;
  stickyHeader?: boolean;
  /**
   * Custom key to store table state under. When this value changes, previous stored values will be cleared.
   */
  storageDedupeKey?: string;
  trackBy?: TableProps.TrackBy<ItemType>;
  variant?: TableProps.Variant;
  visibleContent?: ReadonlyArray<string>;
  wrapLines?: boolean;
}

export interface UseTableResult {
  component: JSX.Element;
}

export enum TestID {
  ACTION_REFRESH = "action_refresh",
  COLLECTION_SELECT_FILTERS = "collection_select_filters",
  ACTION_CLEAR_ALL_FILTERS = "action_clear_all_filters",
}
