import { AWSError, Credentials } from "aws-sdk";
import ApiClient from "lib/ApiClient";
import EventEmitter from "lib/EventEmitter";
import Logger from "lib/Logger/Logger";
import Constants from "utils/constants";

import {
  AWSCredentialsOptions,
  CredentialRoles,
  CredentialsList,
  ErrorCode,
  ErrorName,
  GetCredentialsResponse,
  GetCredentialsResponseV2,
} from "./types";
import createAWSError from "./utils/create-aws-error";
import getMessageFromError from "./utils/get-message-from-error";
import refreshSession from "./utils/refresh-session";

class AWSCredentials extends Credentials {
  /**
   * Minimum session duration in seconds. This constraint is
   * set by STS.AssumeRole. The JWT should be considered expired
   * if the remaining session falls below this threshold.
   */
  static MIN_EXPIRE_WINDOW = 15 * 60;
  static REFRESH_BUFFER = 1 * 60;
  static expiryWindow =
    AWSCredentials.MIN_EXPIRE_WINDOW + AWSCredentials.REFRESH_BUFFER;

  authEndpoint?: string;

  private readonly logger = Logger.getLogger("AWSCredentials");
  private readonly eventEmitter = EventEmitter.getInstance();
  private readonly apiClient = ApiClient.createGenericClient({
    clientName: "AWSCredentials",
    baseUrl: "",
  });

  private role?: CredentialRoles;
  private credentialsList?: CredentialsList;

  constructor({
    authEndpoint,
    credentials = {
      accessKeyId: "",
      secretAccessKey: "",
    },
    role,
  }: AWSCredentialsOptions = {}) {
    super(credentials);

    this.authEndpoint = authEndpoint;
    this.role = role;
  }

  get sessionDuration() {
    if (!this.expireTime) {
      return 0;
    }

    return (
      (this.expireTime.getTime() - Date.now()) / 1000 -
      AWSCredentials.expiryWindow
    );
  }

  get currentRoleArn() {
    return this.roleCredentials?.roleUserArn || "";
  }

  get currentRole() {
    return this.role;
  }

  set currentRole(role: CredentialRoles | undefined) {
    if (role === this.role) {
      return;
    }

    if (role && this.role) {
      this.logger.info(
        `Switching current role from "${this.role}" to "${role}"`
      );
    } else if (role) {
      this.logger.info(`Setting current role to "${role}"`);
    } else {
      this.logger.info("Unsetting role");
    }

    this.role = role;
    this.setCredentials();
  }

  refresh(callback: (err?: AWSError | undefined) => void): void {
    refreshSession({
      expiryWindow: AWSCredentials.expiryWindow,
      logger: this.logger,
    })
      .then((session) => {
        let idToken = session.getIdToken();
        let jwtToken = idToken.getJwtToken();

        this.auth(jwtToken)
          .then((credentialsList) => {
            this.credentialsList = credentialsList;

            if (this.setCredentials()) {
              this.expireTime = new Date(idToken.getExpiration() * 1000);
              callback();
            } else {
              const message = `No credentials${
                this.role ? ` for role "${this.role}" ` : " "
              }found`;

              this.logger.error(message);

              callback(
                createAWSError({
                  name: ErrorName.CREDENTIALS_REFRESH_ERROR,
                  code: ErrorCode.NO_CREDENTIALS_FOR_ROLE,
                  message,
                })
              );
            }

            this.logger.info(
              `Credentials hydrated, next refresh in ${this.sessionDuration} second(s)`
            );
          })
          .catch((error) => {
            const message = getMessageFromError(error);

            this.logger.error("Failed to get credentials", message);

            callback(
              createAWSError({
                name: ErrorName.CREDENTIALS_REFRESH_ERROR,
                code: ErrorCode.NO_CREDENTIALS_FOR_ROLE,
                message,
              })
            );
          });
      })
      .catch((error) => {
        const message = getMessageFromError(error);

        this.logger.error("Failed to get credentials", message);

        if (message === "Network error") {
          this.eventEmitter.emit(Constants.EVENTS.NETWORK_ERROR);
        } else {
          this.eventEmitter.emit(Constants.EVENTS.NOT_AUTHENTICATED);
        }

        callback(
          createAWSError({
            name: ErrorName.CREDENTIALS_REFRESH_ERROR,
            code: ErrorCode.REFRESH_SESSION_ERROR,
            message,
          })
        );
      });
  }

  /**
   * Credentials for the current role
   */
  private get roleCredentials() {
    let credentials = this.credentialsList?.find(({ id }) => id === this.role);

    // Backwards compatibility
    if (
      !credentials &&
      this.credentialsList?.length === 1 &&
      !this.credentialsList[0].id
    ) {
      credentials = this.credentialsList[0];
    }

    return credentials;
  }

  /**
   * Sets credential values from the stored credentials list
   * for the current role
   *
   * @returns {Credentials}
   */
  private setCredentials() {
    const credentials = this.roleCredentials;

    if (!credentials) {
      return;
    }

    this.accessKeyId = credentials.accessKeyId;
    this.secretAccessKey = credentials.secretAccessKey;

    if (credentials.sessionToken) {
      this.sessionToken = credentials.sessionToken;
    }

    return credentials;
  }

  private isV2Response(
    response: GetCredentialsResponse
  ): response is GetCredentialsResponseV2 {
    return "credentials" in response.data;
  }

  /**
   * Exchanges JWT for temporary credentials for use in API calls
   *
   * @param {string} jwtToken
   * @returns {GetCredentialsResponse}
   */
  private auth = async (jwtToken: string) => {
    if (!this.authEndpoint) {
      throw new Error("Missing required auth endpoint for credential exchange");
    }

    const response = await this.apiClient.post<
      GetCredentialsResponse,
      GetCredentialsResponse
    >(
      this.authEndpoint,
      {},
      {
        headers: {
          authorization: `Bearer ${jwtToken}`,
        },
      }
    );

    if (this.isV2Response(response)) {
      return response.data.credentials;
    }

    // Backwards compatibility
    return [response.data];
  };
}

export default AWSCredentials;
