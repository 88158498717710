import { Browser } from "utils";

import { ILogProvider } from "../Logger.interface";

interface ISessionProvider {
  sessionId: string;
  device: {
    userAgent: ReturnType<typeof Browser["getUserAgent"]>;
    language: ReturnType<typeof Browser["getUserLanguage"]>;
    isMobileDevice: ReturnType<typeof Browser["isMobileDevice"]>;
    browserType: ReturnType<typeof Browser["getBrowserType"]>;
    osType: ReturnType<typeof Browser["getOSType"]>;
  };
}

interface IAsyncSessionProviderData {
  identifierRoleArn?: string;
  timing?: ReturnType<typeof Browser["getTimingData"]>;
}

/**
 * The SessionProvider contains data about the current session, such as
 * the browser the user is using, unique session UUID, and identifierRoleArn
 * once the user is authenticated.
 */
const SessionProvider: ILogProvider<
  ISessionProvider,
  IAsyncSessionProviderData
> = {
  cache: undefined,
  getProviderName() {
    return "SessionProvider";
  },
  getData() {
    /**
     * Return from local cache if it has been requested before. This data
     * does not change within the same session.
     */
    if (SessionProvider.cache) {
      return SessionProvider.cache;
    }

    SessionProvider.cache = {
      // sessionId may be redundant since this data is only initialized once per session and is not persisted across different sessions
      sessionId: `t_${Date.now()}`,
      device: {
        userAgent: Browser.getUserAgent(),
        language: Browser.getUserLanguage(),
        isMobileDevice: Browser.isMobileDevice(),
        browserType: Browser.getBrowserType(),
        osType: Browser.getOSType(),
      },
    };

    return SessionProvider.cache;
  },
  /**
   * If there is data that cannot be set on first paint,
   * add it using addAsyncData. A scenario of when this happens
   * is data that can only be accessed after an authentication flow
   * has occurred.
   */
  addAsyncData(asyncData: IAsyncSessionProviderData) {
    if (SessionProvider.cache) {
      SessionProvider.cache = {
        ...SessionProvider.cache,
        ...asyncData,
      };
    }
  },
};

export default SessionProvider;
