import Button from "@amzn/awsui-components-react/polaris/button";
import CollectionPreferences, {
  CollectionPreferencesProps,
} from "@amzn/awsui-components-react/polaris/collection-preferences";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import Table, { TableProps } from "@amzn/awsui-components-react/polaris/table";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import { EmptyMessage } from "components";
import { StoragePartition, StoragePartitionKeys } from "lib";
import React, { useEffect, useMemo, useState } from "react";
import { countText } from "utils";
import { sortTableItems, tableConfig } from "utils";

import { PermissionTableItems } from "./types";
import { ContentPermissionsTableProps } from "./types";
import processData from "./utils/process-data";

export const TEST_IDS = {
  CLEAR_FILTER: "clear_permissions_filter",
};

const ContentPermissionsTable = ({
  header,
  loading,
  error,
  permissions,
  onFilter,
  columnSorting,
  tablePreferences,
  storageKey,
  columnDefinitions,
  i18nStrings,
  permissionMapping,
}: ContentPermissionsTableProps) => {
  const userPermissions = useMemo(
    () => processData(permissions, permissionMapping),
    [permissions]
  );
  const [pageIndex, setPageIndex] = useState(1);
  const [pagesCount, setPagesCount] = useState(0);
  const [pageStartIndex, setPageStartIndex] = useState(0);
  const [filteringText, setFilteringText] = useState("");
  const [filteredData, setFilteredData] = useState<PermissionTableItems>([]);
  const [sortingState, setSortingState] = useState<
    TableProps.SortingState<PermissionTableItems>
  >(
    columnSorting || {
      sortingColumn: {
        sortingField: "",
      },
    }
  );
  const [
    preferences,
    setPreferences,
  ] = useState<CollectionPreferencesProps.Preferences>(
    tablePreferences || tableConfig.defaultTablePreferences
  );

  // Save preferences
  useEffect(() => {
    StoragePartition.setItem(
      StoragePartitionKeys.tablePreferences,
      preferences,
      storageKey
    );
  }, [preferences]);

  // Reset page index and clear selection when filtering
  useEffect(() => {
    setPageIndex(1);
  }, [filteringText]);

  // Make sure page index is in range after page size preference change
  useEffect(() => {
    if (!userPermissions) {
      return;
    }

    if (
      (pageIndex - 1) * (preferences.pageSize as number) >
      userPermissions.length
    ) {
      setPageIndex(
        Math.ceil(filteredData.length / (preferences.pageSize as number))
      );
    }
  }, [preferences.pageSize]);

  // Set filtered data when filter text changes or table data changes
  useEffect(() => {
    if (!userPermissions) {
      setFilteredData([]);
      return;
    }

    setFilteredData(onFilter(filteringText, sortingState, userPermissions!));
  }, [filteringText, userPermissions]);

  // Update page count when filtered data or page size updates
  useEffect(() => {
    setPagesCount(
      Math.ceil(filteredData.length / (preferences.pageSize as number))
    );
  }, [filteredData, preferences.pageSize]);

  // Update page start index when page index or page size update
  useEffect(() => {
    setPageStartIndex((pageIndex - 1) * (preferences.pageSize as number));
  }, [preferences.pageSize, pageIndex]);

  // Update filtered data when sorting state updates
  useEffect(() => {
    if (!sortingState.sortingColumn.sortingComparator || !userPermissions) {
      return;
    }

    const sortedData = [...filteredData];
    sortTableItems(
      sortedData,
      sortingState.sortingColumn.sortingComparator,
      sortingState.isDescending
    );
    setFilteredData(sortedData);

    StoragePartition.setItem(
      StoragePartitionKeys.tableSortColumns,
      {
        isDescending: sortingState.isDescending,
        sortingColumn: {
          sortingField: sortingState.sortingColumn.sortingField,
        },
      },
      storageKey
    );
  }, [sortingState]);

  return (
    <Table
      header={header}
      sortingDescending={sortingState.isDescending}
      // @ts-ignore
      sortingColumn={sortingState.sortingColumn}
      // @ts-ignore
      onSortingChange={({ detail }) => setSortingState(detail)}
      columnDefinitions={columnDefinitions}
      items={
        filteredData.slice(
          pageStartIndex,
          pageStartIndex + (preferences.pageSize as number)
        ) as any
      }
      loading={loading}
      loadingText={tableConfig.loadingText}
      filter={
        <TextFilter
          disabled={!userPermissions || loading}
          onChange={({ detail: { filteringText } }) =>
            setFilteringText(filteringText)
          }
          filteringText={filteringText as string}
          filteringPlaceholder={
            tableConfig.propertyFilteringI18nConstants.placeholder
          }
          countText={
            filteringText?.trim() &&
            countText(
              filteredData.length,
              tableConfig.textFilterCountTextI18nConstants
            )
          }
          filteringAriaLabel="Filter permissions"
        />
      }
      empty={
        error ? (
          <EmptyMessage
            title={i18nStrings.permissionRetrievalErrorMessage.title}
            description={
              i18nStrings.permissionRetrievalErrorMessage.description
            }
          />
        ) : filteringText ? (
          <EmptyMessage
            title="No matches"
            description="We can not find a match."
            actions={
              <Button
                data-testid={TEST_IDS.CLEAR_FILTER}
                onClick={() => setFilteringText("")}
              >
                Clear filter
              </Button>
            }
          />
        ) : (
          <EmptyMessage
            title={i18nStrings.emptyPermissions.title}
            description={i18nStrings.emptyPermissions.description}
          />
        )
      }
      pagination={
        <Pagination
          onChange={({ detail: { currentPageIndex } }) =>
            setPageIndex(currentPageIndex)
          }
          pagesCount={pagesCount}
          currentPageIndex={pageIndex}
          ariaLabels={tableConfig.paginationAriaLabels}
          disabled={!userPermissions || loading}
        />
      }
      preferences={
        <CollectionPreferences
          {...tableConfig.collectionPreferencesI18nConstants}
          preferences={preferences}
          pageSizePreference={tableConfig.pageSizePreference}
          wrapLinesPreference={tableConfig.wrapLinesPreference}
          onConfirm={({ detail }) => setPreferences(detail)}
        />
      }
      wrapLines={preferences.wrapLines}
    />
  );
};

export default ContentPermissionsTable;
