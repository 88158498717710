import { CredentialsOptions } from "aws-sdk/lib/credentials";
import { ILog } from "lib/Logger/Logger.interface";

/**
 * Portal: Event portal user with access to internal APIs
 * Participant: External user with access to event APIs
 * Tenant: Web console user with access to internal APIs
 */
export type CredentialRoles = "Portal" | "Participant" | "Tenant";

export interface Credentials extends CredentialsOptions {
  id?: CredentialRoles;
  sessionDurationSeconds: number;
  roleUserArn: string;
}

export type CredentialsList = Credentials[];
export type CredentialsMap = Partial<Record<CredentialRoles, Credentials>>;

export type GetCredentialsResponse =
  | GetCredentialsResponseV1
  | GetCredentialsResponseV2;

export interface GetCredentialsResponseV1 {
  data: Credentials;
  status: number;
}

export interface GetCredentialsResponseV2 {
  data: GetCredentialsData;
  status: number;
}

export interface GetCredentialsData {
  credentials: CredentialsList;
}

export interface AWSCredentialsOptions {
  authEndpoint?: string;
  role?: CredentialRoles;
  credentials?: CredentialsOptions;
}

export interface RefreshSessionOptions {
  // expiration window in seconds
  expiryWindow?: number;
  logger?: ILog;
}

export enum ErrorName {
  CREDENTIALS_REFRESH_ERROR = "CREDENTIALS_REFRESH_ERROR",
}

export enum ErrorCode {
  NO_CREDENTIALS_FOR_ROLE = "NO_CREDENTIALS_FOR_ROLE",
  REFRESH_SESSION_ERROR = "REFRESH_SESSION_ERROR",
}
