import { NonCancelableCustomEvent } from "@amzn/awsui-components-react/polaris/internal/events/index";
import Textarea, {
  TextareaProps,
} from "@amzn/awsui-components-react/polaris/textarea";
import { useField } from "formik";
import isEmpty from "lodash.isempty";
import React, { useCallback } from "react";

import { FormikFieldProps } from "./../FormControls.interface";
import memoizedFormikPropCheck from "./../FormControls.util";

export interface TextAreaFieldProps<S> {
  name: keyof S;
  textFieldProps: Omit<TextareaProps, "value" | "onBlur" | "onChange">;
  placeholder?: string;
}

const TextAreaField = <S, T extends string>({
  placeholder,
  textFieldProps,
  field,
  meta,
  helpers,
}: TextAreaFieldProps<S> & FormikFieldProps<T>) => {
  const handleBlur = useCallback(() => {
    /**
     * @jcortezj 1/14/2022
     * Setting a timeout of 0 allows the next tab to focus before
     * helpers.setTouched gets called.
     *
     * Without the setTimeout, if you press tab in some context (e.g. in a modal) it does not
     * focus to the next form element correctly.
     *
     * Validated by @parkyh
     */
    setTimeout(() => {
      helpers.setTouched(true);
    }, 0);
  }, [helpers]);

  const onChange = useCallback(
    (e: NonCancelableCustomEvent<TextareaProps.ChangeDetail>) => {
      helpers.setValue(e.detail.value as T);
    },
    [helpers]
  );

  return (
    <Textarea
      onBlur={handleBlur}
      onChange={onChange}
      value={field.value}
      invalid={meta.touched && !isEmpty(meta.error)}
      placeholder={placeholder}
      {...textFieldProps}
    />
  );
};

const MemoizedTextAreaField = React.memo(
  TextAreaField,
  (prevProps, nextProps) => {
    if (
      !memoizedFormikPropCheck(prevProps, nextProps) ||
      prevProps.textFieldProps.disabled !== nextProps.textFieldProps.disabled
    ) {
      return false;
    }

    if (
      prevProps.textFieldProps.disabled !== nextProps.textFieldProps.disabled
    ) {
      return false;
    }

    return true;
  }
);

const TextAreaFieldContainer = <S,>(props: TextAreaFieldProps<S>) => {
  const [field, meta, helpers] = useField<string>(props.name as string);

  const optProps = {
    ...props,
    name: props.name as never,
  };

  return <MemoizedTextAreaField {...{ field, meta, helpers }} {...optProps} />;
};

export default TextAreaFieldContainer;
