import { FlashbarProps } from "@amzn/awsui-components-react/polaris/flashbar";
import { ReactNode, useCallback, useRef } from "react";

import {
  ExtendedFlashbarMessageDefinition,
  ExtendedFlashbarProps,
  SetterOrUpdater,
} from "./use-notification.interface";

interface UseNotificationProps {
  setNotifications: SetterOrUpdater<ExtendedFlashbarProps[]>;
}

interface ShowNotificationOptions {
  onDismiss: (id: string) => void;
  header: string;
  type: FlashbarProps.Type;
  clearStack: boolean;
  loading: boolean;
  id: string;
  action: FlashbarProps.MessageDefinition["action"];
  dismissible: boolean;
}

interface UseNotificationOutput {
  clearNotification: (notificationId?: string) => void;
  showNotification: (
    content: ReactNode,
    options?: Partial<ShowNotificationOptions>
  ) => string;
}

export const clearNotifications = (
  notificationId: string | undefined,
  currentNotifications: ExtendedFlashbarProps[],
  messageIds: string[]
) => {
  if (!notificationId) {
    return currentNotifications.filter(
      (notification) => messageIds.indexOf(notification.id as string) < 0
    );
  }

  const foundIndex = currentNotifications.findIndex(
    (notification) => notification.id === notificationId
  );

  const notifications = [...currentNotifications];

  if (foundIndex > -1) {
    notifications.splice(foundIndex, 1);
  }
  return notifications;
};

const useNotification = ({
  setNotifications,
}: UseNotificationProps): UseNotificationOutput => {
  const messageIds = useRef<string[]>([]);

  /**
   * Clears specified notification. If not specified, clear all notifications created in this component
   * @param {string} notificationId
   */
  const clearNotification = useCallback(
    (notificationId?: string) =>
      setNotifications((currentNotifications) =>
        clearNotifications(
          notificationId,
          currentNotifications,
          messageIds.current
        )
      ),
    [setNotifications]
  );

  /**
   * Pushes a notification onto the global app layout notifications stack
   * @param {ReactNode} content
   * @param {Partial<ShowNotificationOptions>} [options]
   * @param {Function} [options.onDismiss]
   * @param {string} [options.header]
   * @param {boolean} [options.clearStack=true]
   * @param {boolean} [options.loading=false]
   * @param {FlashbarProps.Type} [options.type="info"]
   * @param {string} [options.id=String(Date.now())]
   * @param {boolean} [options.dismissible=true]
   * @param {FlashbarProps.MessageDefinition['action']} [action] A React node
   * @returns {string} Message ID
   */
  const showNotification = useCallback(
    (
      content: ReactNode,
      {
        onDismiss,
        header,
        clearStack = true,
        loading = false,
        type = "info",
        id = String(Date.now()),
        dismissible = true,
        action,
      }: Partial<ShowNotificationOptions> = {}
    ) => {
      // Only clear notification with same ID if not clearing entire stack
      clearNotification(!clearStack ? id : undefined);

      messageIds.current.push(id);
      setNotifications((currentNotifications) => [
        new ExtendedFlashbarMessageDefinition({
          header,
          id,
          type,
          content,
          dismissible,
          loading,
          action,
          onDismiss: () => {
            clearNotification(id);
            onDismiss && onDismiss(id);
          },
        }),
        ...currentNotifications,
      ]);

      return id;
    },
    [clearNotification, setNotifications]
  );

  return {
    clearNotification,
    showNotification,
  };
};

export default useNotification;
