import stripIndent from "strip-indent";

import { newLinePattern } from "../constants";
import { DirectiveNode, DirectiveType } from "../hooks/use-directive/types";

/**
 * Gets the raw string content of the parsed directive. This is needed since react
 * strips empty spaces from text nodes and we need to preserve indentation when rendering
 * content into blocks.
 *
 * @param {ReactElement} node
 * @param {string} sourceContent
 * @returns {string} Raw string content
 */
const getSourceContent = (
  node?: DirectiveNode,
  sourceContent?: string
): string => {
  if (!node || !sourceContent || !node.position) {
    return "";
  }

  const directiveSourceContent = sourceContent.substring(
    node.position.start.offset,
    node.position.end.offset
  );

  // normalize indentation for container directive content
  if (node.type === DirectiveType.CONTAINER) {
    // no content in directive

    if (
      (
        directiveSourceContent.match(
          new RegExp(`${newLinePattern.source}`, "g")
        ) || ""
      ).length <= 1
    ) {
      return "";
    }

    return stripIndent(
      // remove first line of raw directive source since that does not include
      // any leading indentation
      directiveSourceContent.replace(
        new RegExp(`^.*${newLinePattern.source}`),
        ""
      )
      // remove last line (closing directive fence)
    ).replace(new RegExp(`${newLinePattern.source}.*$`), "");
  }

  return directiveSourceContent.substring(
    directiveSourceContent.indexOf("[") + 1,
    directiveSourceContent.lastIndexOf("]")
  );
};

export default getSourceContent;
