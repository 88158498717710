/**
 * Filters a property object with a specified list of keys
 * @param {string[]} allowList
 * @param {Object} props
 * @returns {Object}
 */
const getAllowedProps = <P>(allowList: (keyof P)[], props: P) => {
  const filteredProps: Partial<P> = {};

  if (Array.isArray(allowList)) {
    allowList.forEach((key) => {
      filteredProps[key] = props?.[key];
    });
  }

  return filteredProps;
};

export default getAllowedProps;
