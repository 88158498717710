import { NonCancelableCustomEvent } from "@amzn/awsui-components-react/polaris/internal/events/index";
import Multiselect, {
  MultiselectProps,
} from "@amzn/awsui-components-react/polaris/multiselect";
import { SelectProps } from "@amzn/awsui-components-react/polaris/select";
import { useField, useFormikContext } from "formik";
import isEqual from "lodash.isequal";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { FormikFieldProps } from "./../FormControls.interface";
import memoizedFormikPropCheck, {
  isFieldInvalid,
} from "./../FormControls.util";

export interface MultiSelectFieldProps<S = any> {
  name: keyof S;
  multiSelectProps: Omit<
    MultiselectProps,
    "selectedOptions" | "onBlur" | "onChange"
  >;
  placeholder?: string;
  didSubmit?: boolean;
}

const flatDeep = (
  arr: SelectProps.Options | undefined,
  d = 1
): MultiselectProps.Options => {
  if (!Array.isArray(arr)) {
    return [] as MultiselectProps.Options;
  }

  return d > 0
    ? arr.reduce(
        (acc, val) =>
          acc.concat(
            Array.isArray(val.options) ? flatDeep(val.options, d - 1) : val
          ),
        []
      )
    : arr.slice();
};

function MultiSelectField<S, T extends MultiselectProps.Option[]>({
  placeholder,
  multiSelectProps,
  field,
  meta,
  helpers,
  didSubmit,
}: MultiSelectFieldProps<S> & FormikFieldProps<T>) {
  const allOptions: MultiselectProps.Options = useMemo(
    () => flatDeep(multiSelectProps?.options),
    [multiSelectProps?.options]
  );
  const [selectedOptions, setSelectedOptions] = useState<
    MultiselectProps.Options | []
  >([]);

  useEffect(() => {
    const options = allOptions?.filter((option: MultiselectProps.Option) => {
      return field.value?.some((val: MultiselectProps.Option) => {
        return val.value === option.value;
      });
    });

    options && setSelectedOptions(options);
  }, [field.value, multiSelectProps.options]);

  const handleBlur = useCallback(() => {
    /**
     * @jcortezj 1/14/2022
     * Setting a timeout of 0 allows the next tab to focus before
     * helpers.setTouched gets called.
     *
     * Without the setTimeout, if you press tab in some context (e.g. in a modal) it does not
     * focus to the next form element correctly.
     *
     * Validated by @parkyh
     */
    setTimeout(() => {
      helpers.setTouched(true);
    }, 0);
  }, [helpers]);

  const handleChange = useCallback(
    (e: NonCancelableCustomEvent<MultiselectProps.MultiselectChangeDetail>) => {
      helpers.setValue(e.detail.selectedOptions as T);
    },
    [helpers]
  );

  return (
    <Multiselect
      onBlur={handleBlur}
      onChange={handleChange}
      placeholder={placeholder}
      selectedOptions={selectedOptions}
      invalid={isFieldInvalid<T>({ meta }, didSubmit)}
      {...multiSelectProps}
    />
  );
}

const MemoizedMultiSelectField = React.memo(
  MultiSelectField,
  (prevProps, nextProps) => {
    if (
      !memoizedFormikPropCheck(prevProps, nextProps) ||
      !isEqual(
        prevProps.multiSelectProps.options,
        nextProps.multiSelectProps.options
      )
    ) {
      return false;
    }

    return true;
  }
);

const MultiSelectFieldContainer = <S,>(props: MultiSelectFieldProps<S>) => {
  const [field, meta, helpers] = useField<MultiselectProps.Option[]>(
    props.name as string
  );
  const { submitCount } = useFormikContext();

  const optProps = {
    ...props,
    name: props.name as never,
    didSubmit: submitCount >= 0,
  };

  return (
    <MemoizedMultiSelectField {...{ field, meta, helpers }} {...optProps} />
  );
};

export default MultiSelectFieldContainer;
