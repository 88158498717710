export const AWS_LOGOUT_URL =
  "https://signin.aws.amazon.com/oauth?Action=logout";

export const i18nStrings = {
  header: "AWS console login",
  valueLabelStatus: "Status",
  valueLabelAccountNumber: "Account number",
  valueLabelIAMRole: "IAM role",
  valueLabelDestination: "Destination",
  loggingIn: "Logging in...",
};
