import SIMTemplateBuilder from "./SIMTemplateBuilder";

interface DescriptionInput {
  pageOfWorkshop: string;
  issue: string;
  proposedSolution?: string;
}

export const DEFAULT_ISSUE_FOLDER = "8e4cc4c1-d395-48af-978f-57b569fc246b";
export const WORKSHOP_SIM_BUILDER_TEXT = {
  contentIssueTitle: "Content issue for {0}",
  linkPromptText: "Provide a link to where the issue exists:",
  issuePromptText:
    "Describe the issue in detail, including how to reproduce it.",
  proposedSolutionPromptText:
    "If applicable, do you have a proposed solution to mitigate this issue?",
};

/**
 * Unique class dedicated to building out SIM tickets for workshop content
 * issues.
 *
 * Example usage:
 * const issue = workshopContentSimTemplateBuilder
 *  .addTitle("Workshop Studio Content Issue Reporting")
 *  .addDescription(
 *    workshopContentSimTemplateBuilder.formatDescription({
 *      pageOfWorkshop:
 *        "https://studio.us-east-1.prod.workshops.aws/preview/580d5100-7d5d-4475-a08a-869479cdb428/builds/f1f6aff0-57fd-4fad-9c3d-9fdabac30e61/en-US/detailed-documentation",
 *      issue: "This content should be in spanish",
 *      proposedSolution: "Tell Young to fix it ASAP",
 *    })
 *  )
 *  .addAssignedUser("jcortezj")
 *  .addImpactLevel(4)
 *  .addCustomField({
 *    id: "contentid",
 *    type: "string",
 *    value: "580d5100-7d5d-4475-a08a-869479cdb428",
 *  })
 *  .createIssueTemplate();
 */
class WorkshopContentSIMTemplateBuilder extends SIMTemplateBuilder {
  private readonly ticketDescriptionTitleText: string =
    "The AWS Workshop Studio team does not maintain Workshops and other Content authored on the Workshop Studio service. Every Workshop and Content resource is maintained by one or more authors associated with the resource. Please provide as much information so that the content owner(s) can resolve the issue.";

  constructor(issueFolder: string = DEFAULT_ISSUE_FOLDER) {
    super(issueFolder);

    this.setMarkdownType("text/amz-markdown-sim");
    return this;
  }

  getMaxUrlLength() {
    return this.MAX_URL_LENGTH;
  }

  formatDescription({
    pageOfWorkshop,
    issue,
    proposedSolution,
  }: DescriptionInput): string {
    const blocks = [
      this.createTextBlock({
        title: WORKSHOP_SIM_BUILDER_TEXT.linkPromptText,
        text: pageOfWorkshop,
      }),
      this.createTextBlock({
        title: WORKSHOP_SIM_BUILDER_TEXT.issuePromptText,
        text: issue,
      }),
      proposedSolution
        ? this.createTextBlock({
            title: WORKSHOP_SIM_BUILDER_TEXT.proposedSolutionPromptText,
            text: proposedSolution,
          })
        : "",
      this.createTextBlock({
        title: "Additional Details",
        text: this.italicizeText("[[Optional: Provide additional details]]"),
      }),
    ].filter(Boolean);

    return `${this.italicizeText(
      this.ticketDescriptionTitleText
    )}\n\n${blocks.join("\n\n")}`;
  }

  createIssueTemplate = (): string => {
    return `${this.createSimIssueUrl()}?assignedFolder=${
      this.issueFolder
    }&title=${this.title}&description=${
      this.description
    }&descriptionContentType=${this.markdownType}&assigneeIdentity=${
      this.assignedUser
    }&${this.impactLevel}&${this.getCustomFields()}`;
  };
}

export default WorkshopContentSIMTemplateBuilder;
