import LocalStorageProvider from "lib/LocalStorageProvider";
import { Constants } from "utils";

import SinkType from "../SinkType";
import { ILogEntry, ILogSink, LogLevel } from "./../../Logger.interface";
export default class ConsoleLogSink implements ILogSink {
  private static isEnabled: boolean =
    process.env.NODE_ENV !== "production" ||
    !!LocalStorageProvider.getItem(Constants.ConsoleLogLocalStorageId);
  private static VerboseColor =
    window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)")
      ? "color:white"
      : "color:black";
  private static InfoColor = "color:green";
  private static WarningColor = "color:Orange";
  private static ErrorColor = "color:red";
  private static CriticalColor = "color:DarkRed";
  private static EventColor = "color:blue";

  public getSinkName(): string {
    return SinkType.ConsoleLogSink;
  }

  public writeEntry(logEntry: ILogEntry): void {
    if (!ConsoleLogSink.isEnabled) {
      return;
    }

    const { logLevel, msg, loggerName } = logEntry;

    const logLevelColor = this.getMessageColor(logLevel);
    console.log("%c%s", logLevelColor, loggerName, ...msg);
  }

  private getMessageColor(logLevel: LogLevel): string {
    switch (logLevel) {
      case LogLevel.VERBOSE:
        return ConsoleLogSink.VerboseColor;
      case LogLevel.DEBUG:
        return ConsoleLogSink.EventColor;
      case LogLevel.INFO:
        return ConsoleLogSink.InfoColor;
      case LogLevel.WARNING:
        return ConsoleLogSink.WarningColor;
      case LogLevel.ERROR:
        return ConsoleLogSink.ErrorColor;
      case LogLevel.CRITICAL:
        return ConsoleLogSink.CriticalColor;
      default:
        return ConsoleLogSink.VerboseColor;
    }
  }
}
