import { FormikContextType } from "formik";
import { LogLevel } from "lib";
import { BaseAttributeType } from "lib/FormikFormUtils/FormikFormUtils";
import React, { useEffect } from "react";

import useLogger from "../use-logger";
import FormikFactory, { OptionalFactoryProps } from "./Factory/FormikFactory";

const useFormikFormBuilder = <S extends BaseAttributeType, T = any>(
  attributes: readonly S[],
  formik: Pick<
    FormikContextType<any>,
    "submitCount" | "setFieldTouched" | "touched"
  >
) => {
  const [logger] = useLogger("FormBuilder", LogLevel.ERROR);
  const { submitCount, setFieldTouched, touched } = formik;

  useEffect(() => {
    if (submitCount >= 1) {
      attributes.forEach((attribute) => {
        const { dynamicFormName, name } = attribute;
        const formName = dynamicFormName || (name as string);

        if (formName) {
          if (!touched[formName]) {
            setFieldTouched(formName, true);
          }
        }
      });
    }
  }, [submitCount, setFieldTouched]);

  return {
    buildFormElement(attribute: S, optProps?: OptionalFactoryProps) {
      const formElement = FormikFactory.buildFormElement<S>(
        attribute,
        optProps
      );

      if (!formElement) {
        logger.error(
          `Unable to find ${attribute.type} field for ${attribute.name} dynamic form component.`
        );
      }

      return formElement;
    },
  };
};

export default useFormikFormBuilder;
