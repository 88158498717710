export interface IPromiseReflectResponse<T> {
  readonly result: T;
  readonly success: boolean;
}

const PromiseReflect = <T>(
  promises: Promise<T>[]
): Promise<IPromiseReflectResponse<T>[]> => {
  return Promise.all(promises.map(_reflectPromise));
};

const _reflectPromise = <T>(
  promise: Promise<T>
): Promise<IPromiseReflectResponse<T>> => {
  return promise.then(
    (result) => {
      return {
        result,
        success: true,
      };
    },
    (result) => {
      return {
        result,
        success: false,
      };
    }
  );
};

export default PromiseReflect;
