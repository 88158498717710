import { useUrlParse } from "hooks";
import path from "path-browserify";
import React, { useEffect } from "react";
import { Helmet, HelmetTags } from "react-helmet-async";

import { IMetadata } from "../PreviewComponent/strategy/ContentStrategy";

interface SearchEngineOptimizationProps {
  /**
   * Locale for the specified workshop
   */
  locale?: string;
  /**
   * Metadata for the workshop. This includes the workshop and description
   * of the workshop
   */
  metadata?: IMetadata;
  onChangeClientState?: (
    newState: any,
    addedTags: HelmetTags,
    removedTags: HelmetTags
  ) => void;
  /**
   * Title to default to when the component unmounts
   */
  defaultTitle?: string;
}

const SearchEngineOptimization: React.FC<SearchEngineOptimizationProps> = ({
  locale = "en-US",
  metadata = { title: "", description: "" },
  onChangeClientState,
  defaultTitle = "",
}: SearchEngineOptimizationProps) => {
  const { origin, href, hostname } = useUrlParse(window.location.href);
  const { title, description } = metadata;

  useEffect(() => {
    return () => {
      if (defaultTitle) {
        /**
         * Thankfully, React Helmet removes all meta tags that it inserted on unmount.
         * Unfortunately, it does not revert the title.
         */
        document.title = defaultTitle;
      }
    };
  }, []);

  return (
    <Helmet
      htmlAttributes={{ lang: locale || "en-US" }}
      onChangeClientState={onChangeClientState}
    >
      <title>{metadata?.title}</title>

      {/* facebook open graph tags */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={href} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={path.join(origin, "logo192.png")} />

      {/* twitter card tags */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:domain" content={hostname} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:site" content="@WorkshopStudio" />
      <meta name="twitter:image" content={path.join(origin, "logo192.png")} />
      <meta name="twitter:url" content={href} />

      <meta charSet="utf-8" />
    </Helmet>
  );
};

export default SearchEngineOptimization;
