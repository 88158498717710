/**
 * Returns error message string
 *
 * @param {string | Error} error
 * @returns {string}
 */
const getMessageFromError = (error: string | Error) => {
  if (typeof error === "string") {
    return error;
  }

  return error.message;
};

export default getMessageFromError;
