/**
 * Copy of all of the CloudWatch Metrics sent from EEJavaScriptUtils
 */
const CloudWatchMetric = {
  // Metric that is sent when a directive is rendered using MarkdownRenderer
  DIRECTIVE_RENDERED: "DIRECTIVE_RENDERED_{0}",
  // Metric that is sent when a new route is fetched from Embedded content
  EMBEDDED_CONTENT_ROUTE_NAVIGATION: "EMBEDDED_CONTENT_ROUTE_NAVIGATION",
  // Metric that is sent when a route is being prefetched
  PREFETCH_CONTENT_ROUTE: "PREFETCH_CONTENT_ROUTE",
  // Metric that is sent when there is a Catastrophic error that renders the UI unusable
  ERROR_BOUNDARY_EXCEPTION: "ERROR_BOUNDARY_EXCEPTION",
  // Metric that is sent every time a user reports a content related issue with the SIM template
  CONTENT_ISSUE_TEMPLATE_CREATED: "CONTENT_ISSUE_TEMPLATE_CREATED",
  // Metric sent when a user reports a workshop studio related bug
  REPORT_WORKSHOP_STUDIO_BUG: "REPORT_WORKSHOP_STUDIO_BUG",
  tablePropertyFilter: {
    TYPE: "TablePropertyFilter",
    FREE_TEXT_QUERY_PROPERTY: "*",
  },
};

export interface IRetryBackoff {
  interval: number;
  maxRetries: number;
  exponential: number;
}

// Base interval time in milliseconds
const defaultRetryBackoff: IRetryBackoff = {
  interval: 1000,
  maxRetries: 5,
  exponential: 3,
};

/**
 * Events that can be sent from EventEmitter
 */
enum EVENTS {
  NETWORK_ERROR = "NETWORK_ERROR",
  NO_CURRENT_USER = "NO_CURRENT_USER",
  AMAZON_CORPORATE_SSO_TOKEN = "AMAZON_CORPORATE_SSO_TOKEN",
  NOT_AUTHENTICATED = "NOT_AUTHENTICATED",
}

/**
 * Gets time in milliseconds to wait until next retry
 * @param {number} retryCount
 * @return {number}
 */
const exponentialBackoff = (
  retryCount: number,
  retryBackoff: IRetryBackoff = defaultRetryBackoff
) => retryBackoff.interval * Math.pow(retryBackoff.exponential, retryCount);

const exponentialAwsBackoff = (retryCount: number) =>
  defaultRetryBackoff.interval *
  Math.pow(defaultRetryBackoff.exponential, retryCount);

const ExternalLinks = {
  wiki: "https://w.amazon.com/bin/view/AWS_Workshop_Studio",
  featureRequest:
    "https://issues.amazon.com/issues/create?template=2ab2906e-ab0a-43be-8b30-950dc5108a43",
  reportBug:
    "https://t.corp.amazon.com/create/templates/3a903712-d081-42b4-a522-8176a42de95e",
  privacy: "http://aws.amazon.com/privacy",
  terms: "http://aws.amazon.com/terms",
  submitFeedback:
    "https://survey.fieldsense.whs.amazon.dev/survey/dcd75fb1-e87e-4afd-a952-ba7df764548c",
  submitSupportRequest:
    "https://t.corp.amazon.com/create/templates/3a903712-d081-42b4-a522-8176a42de95e",
  eventDeliveryLaunch:
    "https://w.amazon.com/bin/view/AWS_Workshop_Studio/#HQ:HowwilltheEventDeliverylaunchwork3F",
};

type ApplicationName = "studio" | "catalog";
type ApplicationRegion = "us-east-1" | "us-west-2";
type ApplicationStage = "dev" | "gamma" | "prod";

const applicationBaseUrl = (
  name: ApplicationName = "studio",
  region: ApplicationRegion = "us-east-1",
  stage: ApplicationStage = "prod"
) => `https://${name}.${region}.${stage}.workshops.aws`;

const LATEST_BUILD = "$LATEST";
const CURRENT_BUILD = "$CURRENT";

const DefaultLocale = "en-US";

const REGULAR_EXPRESSIONS = {
  uuidV4:
    "[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-4[A-Za-z0-9]{3}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}",
};

const ConsoleLogLocalStorageId = "__console_log_enable__";

const Constants = {
  CloudWatchMetric,
  ConsoleLogLocalStorageId,
  ExternalLinks,
  DefaultLocale,
  retryBackoff: defaultRetryBackoff,
  exponentialBackoff,
  exponentialAwsBackoff,
  applicationBaseUrl,
  EVENTS,
  LATEST_BUILD,
  CURRENT_BUILD,
  REGULAR_EXPRESSIONS,
};

export default Constants;
