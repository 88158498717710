import getDatabase from "lib/Database";

import { RequestConfig } from "./BaseApiClient";
import GenericClient from "./GenericClient";
import { CacheInterceptor } from "./Interceptors";

interface ApiClientOptions {
  clientName: string;
  baseUrl: string;
  cache?: boolean;
  cacheStoragedType?: "in-memory";
}

class ApiClient {
  static createGenericClient(
    {
      clientName,
      baseUrl,
      cache,
      cacheStoragedType = "in-memory",
    }: ApiClientOptions,
    options?: RequestConfig
  ): GenericClient {
    const genericClient: GenericClient = new GenericClient(
      clientName,
      baseUrl,
      options
    );

    if (cache) {
      new CacheInterceptor(getDatabase(cacheStoragedType)).addInterceptor(
        genericClient
      );
    }

    return genericClient;
  }
}

export default ApiClient;
