import { PutRecordCommandOutput } from "@aws-sdk/client-firehose";
import { FirehoseClient } from "clients";
import { UnauthenticatedClientProviderContext } from "context";
import { useContext } from "react";

import convertStreamToJson from "./../../utils/convert-stream-to-json";
import convertStreamToText from "./../../utils/convert-stream-to-text";

interface UseFirehoseClientOutput {
  putRecord: FirehoseClient["putRecord"];
  convertStreamToJson: <S extends unknown>(
    body: BodyInit
  ) => Promise<S | undefined>;
  convertStreamToText: (body: BodyInit) => Promise<string>;
}

/**
 * useFirehoseClient is a re-usable hook that utilizes the FirehoseClient
 * at its core to interface with Firehose.
 */
const useFirehoseClient = (): UseFirehoseClientOutput => {
  const context = useContext(UnauthenticatedClientProviderContext);

  const putRecord = async ({
    deliveryStreamName,
    record,
  }: {
    deliveryStreamName: string;
    record: string;
    transform?: "text";
  }): Promise<PutRecordCommandOutput | undefined> => {
    return context.firehoseClient?.putRecord({ deliveryStreamName, record });
  };

  return {
    putRecord,
    convertStreamToText,
    convertStreamToJson,
  };
};

export default useFirehoseClient;
