import {
  FirehoseClient as Client,
  PutRecordCommand,
  PutRecordCommandOutput,
} from "@aws-sdk/client-firehose";
import { Credentials } from "aws-sdk";
import { ILog } from "lib";

class FirehoseClient {
  private static instance: FirehoseClient | undefined;
  public static getInstance(
    credentials: Credentials,
    region: string,
    logger: ILog
  ): FirehoseClient {
    if (!FirehoseClient.instance) {
      FirehoseClient.instance = new FirehoseClient(credentials, region, logger);
    }

    return FirehoseClient.instance;
  }
  private firehoseClient: Client;

  constructor(
    credentials: Credentials,
    region: string,
    private readonly logger: ILog
  ) {
    this.firehoseClient = new Client({
      region,
      credentials,
      logger: this.logger,
      apiVersion: "2006-03-01",
    });
  }

  public putRecord = async ({
    deliveryStreamName,
    record,
  }: {
    deliveryStreamName: string;
    record: string;
  }): Promise<PutRecordCommandOutput | undefined> => {
    const putRecordCommand: PutRecordCommand = new PutRecordCommand({
      DeliveryStreamName: deliveryStreamName,
      Record: {
        Data: Buffer.from(`${record}\n`), // a newline must be appended in order for Athena queries to work properly
      },
    });

    try {
      return await this.firehoseClient.send(putRecordCommand);
    } catch (e) {
      this.logger.error(
        `Unable to put record on to Firehose delivery stream ${deliveryStreamName}...`
      );
      return Promise.reject(e);
    }
  };

  public static __removeInstance__() {
    if (process.env.NODE_ENV !== "test") {
      return;
    }
    FirehoseClient.instance = undefined;
  }
}

export default FirehoseClient;
