import {
  GetObjectCommand,
  GetObjectCommandOutput,
  S3Client as Client,
} from "@aws-sdk/client-s3";
import { Credentials } from "aws-sdk";
import { ILog } from "lib";

class S3Client {
  private static instance: S3Client | undefined;
  public static getInstance(
    credentials: Credentials,
    region: string,
    logger: ILog
  ): S3Client {
    if (!S3Client.instance) {
      S3Client.instance = new S3Client(credentials, region, logger);
    }

    return S3Client.instance;
  }
  private s3Client: Client;
  constructor(
    credentials: Credentials,
    region: string,
    private readonly logger: ILog
  ) {
    this.s3Client = new Client({
      region,
      credentials,
      logger: this.logger,
      apiVersion: "2006-03-01",
    });
  }
  public getObject = async ({
    bucket,
    key,
  }: {
    bucket: string;
    key: string;
  }): Promise<GetObjectCommandOutput | undefined> => {
    const getObjectCommand: GetObjectCommand = new GetObjectCommand({
      Bucket: bucket,
      Key: key,
    });

    try {
      const response: GetObjectCommandOutput = await this.s3Client.send(
        getObjectCommand
      );
      return response;
    } catch (e) {
      this.logger.error(
        `Unable to retrieve S3 Object with bucket ${bucket} and key ${key}...`
      );
      return Promise.reject(e);
    }
  };

  public static __removeInstance__() {
    if (process.env.NODE_ENV !== "test") {
      return;
    }
    S3Client.instance = undefined;
  }
}

export default S3Client;
