import Box from "@amzn/awsui-components-react/polaris/box";
import Icon from "@amzn/awsui-components-react/polaris/icon";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import React, { FC } from "react";

import { STATUS_ICON_MAP } from "./StepIcon.constants";
import { StepIconProps } from "./StepIcon.interface";
import css from "./StepIcon.module.scss";

const StepIcon: FC<StepIconProps> = ({
  status,
  index = 1,
  variant = "subtle",
}) => {
  if (!status) {
    return (
      <div
        className={`${css.iconContainer} ${css[`variant-${variant}`]} ${
          index > 9 ? css.digits2 : ""
        }`}
      >
        <i className={css.numberIcon}>
          <Box variant="small">{index}</Box>
        </i>
      </div>
    );
  }

  if (status === "loading") {
    return (
      <Box color="text-status-inactive">
        <Spinner size="normal" />
      </Box>
    );
  }

  return <Icon {...(STATUS_ICON_MAP[status] || STATUS_ICON_MAP.stopped)} />;
};

export { StepIconProps } from "./StepIcon.interface";
export default StepIcon;
