import { isStorageAvailable, StorageType } from "./LocalStorage.util";

class LocalStorageProvider {
  public static getItem = (
    key: string,
    type: StorageType = "localStorage"
  ): string | null => {
    if (LocalStorageProvider.isStorageAvailable(type)) {
      return window[type].getItem(key);
    }
    return null;
  };

  public static setItem = (
    key: string,
    value: string,
    type: StorageType = "localStorage"
  ): boolean => {
    if (LocalStorageProvider.isStorageAvailable(type)) {
      window[type].setItem(key, value);
      return true;
    }
    return false;
  };

  public static removeItem = (
    key: string,
    type: StorageType = "localStorage"
  ): boolean => {
    if (LocalStorageProvider.isStorageAvailable(type)) {
      window[type].removeItem(key);
      return true;
    }
    return false;
  };

  public static clear = (type: StorageType = "localStorage"): boolean => {
    if (LocalStorageProvider.isStorageAvailable(type)) {
      window[type].clear();
      return true;
    }
    return false;
  };

  /**
   * isStorageAvailable is imported, and re-exported (redundant) so that we can
   * mock isStorageAvailable for jest testing.
   */
  public static isStorageAvailable = (type: StorageType): boolean => {
    return isStorageAvailable(type);
  };
}

export default LocalStorageProvider;
