import { LinkProps as PolarisLinkProps } from "@amzn/awsui-components-react/polaris/link";

export enum LinkAction {
  navigate = "navigate",
  download = "download",
}

export interface LinkProps
  extends Pick<PolarisLinkProps, "href" | "target" | "external"> {
  action?: LinkAction;
  title?: string;
  isImageLink?: boolean;
}
