export interface CounterLabel {
  singular: string;
  plural: string;
}

/**
 * Builds a human readable count string with the specified count and labels
 * @param {number} count
 * @param {string|CounterLabel} label A counter label
 * @returns {string} A human readable count
 */
const countText = (count: number, label: string | CounterLabel) => {
  if (typeof label === "object") {
    // 0 uses the plural label
    label = !count || count > 1 ? label.plural : label.singular;
  }
  return `${count} ${label}`;
};

export default countText;
