export enum Conjunctions {
  And = "and",
  Or = "or",
}

/**
 * Returns a human readable string of items (with Oxford comma)
 * @param {string[]} items
 * @param {Conjunctions | string} conjunction
 * @returns {string}
 */
const getStringList = (
  items: string[],
  conjunction: Conjunctions | string = Conjunctions.Or
) => {
  let res = "";
  if (items.length === 1) {
    res = items[0];
  } else if (items.length === 2) {
    res = items.join(` ${conjunction} `);
  } else if (items.length > 2) {
    res = `${items.slice(0, -1).join(", ")}, ${conjunction} ${items.slice(-1)}`;
  }
  return res;
};

export default getStringList;
