import AlertDirective from "./directives/alert-directive";
import AssetUrlDirective from "./directives/asset-url-directive";
import ButtonDirective from "./directives/button-directive";
import ChildrenDirective from "./directives/children-directive";
import CodeBlockDirective from "./directives/code-block-directive";
import CodeDirective from "./directives/code-directive";
import ExpandDirective from "./directives/expand-directive";
import ImageDirective from "./directives/image-directive";
import LinkDirective from "./directives/link-directive";
import ParamDirective from "./directives/param-directive";
import TabDirective from "./directives/tab-directive";
import TabsDirective from "./directives/tabs-directive";
import VideoDirective from "./directives/video-directive";
import { DirectiveName, DirectiveNodeProcessor, DirectiveType } from "./types";

export const DIRECTIVE_REGISTRY: Record<
  DirectiveType,
  Partial<Record<DirectiveName, DirectiveNodeProcessor>>
> = {
  [DirectiveType.TEXT]: {
    [DirectiveName.ASSET_URL]: new AssetUrlDirective(),
    [DirectiveName.BUTTON]: new ButtonDirective(),
    [DirectiveName.CODE]: new CodeDirective(),
    [DirectiveName.LINK]: new LinkDirective(),
    [DirectiveName.PARAM]: new ParamDirective(),
    [DirectiveName.IMAGE]: new ImageDirective(),
  },
  [DirectiveType.LEAF]: {
    [DirectiveName.ALERT]: new AlertDirective(),
    [DirectiveName.CHILDREN]: new ChildrenDirective(),
    [DirectiveName.CODE]: new CodeBlockDirective(),
    [DirectiveName.EXPAND]: new ExpandDirective(),
    [DirectiveName.VIDEO]: new VideoDirective(),
  },
  [DirectiveType.CONTAINER]: {
    [DirectiveName.ALERT]: new AlertDirective(),
    [DirectiveName.CODE]: new CodeBlockDirective(),
    [DirectiveName.EXPAND]: new ExpandDirective(),
    [DirectiveName.TABS]: new TabsDirective(),
    [DirectiveName.TAB]: new TabDirective(),
  },
};
