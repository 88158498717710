import { ApiError, BaseApiClient, Client } from "lib/ApiClient";
import { useRef } from "react";

import useLazyRequest from "../use-request/use-lazy-request";

const useAxiosClient = <
  Get extends any = any,
  Put extends any = any,
  Post extends any = any,
  Delete extends any = any
>(
  apiClient: BaseApiClient
) => {
  const client = useRef(apiClient).current;

  const [getRequest, { isError, data, isLoading, reset }] = useLazyRequest<
    Client["get"],
    ApiError
  >(client.get, {
    context: client,
    apiRequestName: client.getClientName(),
  });

  const [
    putRequest,
    {
      isError: putIsError,
      data: putData,
      isLoading: putIsLoading,
      reset: putReset,
    },
  ] = useLazyRequest<Client["put"], ApiError>(client.put, {
    context: client,
    apiRequestName: client.getClientName(),
  });

  const [
    postRequest,
    {
      isError: postIsError,
      data: postData,
      isLoading: postIsLoading,
      reset: postReset,
    },
  ] = useLazyRequest<Client["post"], ApiError>(client.post, {
    context: client,
    apiRequestName: client.getClientName(),
  });

  const [
    deleteRequest,
    {
      isError: deleteIsError,
      data: deleteData,
      isLoading: deleteIsLoading,
      reset: deleteReset,
    },
  ] = useLazyRequest<Client["delete"], ApiError>(client.delete, {
    context: client,
    apiRequestName: client.getClientName(),
  });

  return {
    get: {
      request: getRequest,
      reset,
      response: {
        isError,
        data: data as { data?: Get } | undefined,
        isLoading,
      },
    },
    put: {
      request: putRequest,
      reset: putReset,
      response: {
        isError: putIsError,
        data: putData as { data?: Put } | undefined,
        isLoading: putIsLoading,
      },
    },
    post: {
      request: postRequest,
      reset: postReset,
      response: {
        isError: postIsError,
        data: postData as { data?: Post } | undefined,
        isLoading: postIsLoading,
      },
    },
    delete: {
      request: deleteRequest,
      reset: deleteReset,
      response: {
        isError: deleteIsError,
        data: deleteData as { data?: Delete } | undefined,
        isLoading: deleteIsLoading,
      },
    },
  };
};

export default useAxiosClient;
