import {
  collectionPreferencesI18nConstants,
  defaultTablePreferences,
  loadingText,
  pageSizePreference,
  paginationAriaLabels,
  propertyFilteringI18nConstants,
  textFilterCountTextI18nConstants,
  wrapLinesPreference,
} from "./table";

const tableConfig = {
  defaultTablePreferences,
  paginationAriaLabels,
  pageSizePreference,
  wrapLinesPreference,
  collectionPreferencesI18nConstants,
  textFilterCountTextI18nConstants,
  propertyFilteringI18nConstants,
  loadingText,
};

export { sortTableItems } from "./table";
export default tableConfig;
