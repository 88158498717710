import { Dimension, MetricDatum } from "@aws-sdk/client-cloudwatch";
import { MetadataBearer } from "@aws-sdk/types";
import { ILog } from "lib/Logger";

import AbstractClientAggregator from "../AbstractClientAggregator/AbstractClientAggregator";
import CloudWatchClient from "./../CloudWatchClient";

export interface CloudWatchMetricSettings {
  stage: string;
}

/**
 * MetricsClient is used to queue up events, and forward them
 * to the CloudWatchClient
 *
 * Since each PutMetricData request is limited to 40 KB in size for
 * HTTP POST requests, utilize DataChunk to sends chunks of data at
 * at time
 *
 * For reference, a very simple request with headers, & Form Data such
 * as below comes in at roughly ~500 bytes.
 *
 * Namespace: EEEventEngine/EEWebConsole
 * MetricData.member.1.MetricName: AUTHENTICATED_APP_INITIALIZED
 * MetricData.member.1.Dimensions.member.1.Name: region
 * MetricData.member.1.Dimensions.member.1.Value: us-east-1
 * MetricData.member.1.Timestamp: 2021-03-05T14:46:15Z
 * MetricData.member.1.Value: 1
 * MetricData.member.1.Unit: Count
 * Action: PutMetricData
 * Version: 2010-08-01
 */
export class MetricsClient extends AbstractClientAggregator<
  MetricDatum,
  (void | MetadataBearer)[] | undefined
> {
  private static instance: MetricsClient | undefined;
  public static getInstance(
    cloudWatch: CloudWatchClient,
    region: string,
    logger: ILog,
    stage: CloudWatchMetricSettings
  ): MetricsClient {
    if (!MetricsClient.instance) {
      MetricsClient.instance = new MetricsClient(
        cloudWatch,
        region,
        logger,
        stage
      );
    }

    return MetricsClient.instance;
  }
  private defaultDimension: Dimension[] = [];
  constructor(
    private readonly cloudWatch: CloudWatchClient,
    region: string,
    private readonly logger: ILog,
    readonly settings: CloudWatchMetricSettings
  ) {
    super({
      pollMs: 2500,
      pollEngineName: "CloudWatchMetricClientPoll",
      chunkSize: 20,
    });

    // Register the function and context that the PollEngine will use to execute
    this.pollEngine.registerContext(this);
    this.pollEngine.registerPollFn(this.sendQueuedCommands);
    this.pollEngine.startPolling();

    this.defaultDimension.push({
      Name: "region",
      Value: region,
    });
    this.defaultDimension.push({
      Name: "stage",
      Value: settings.stage,
    });
  }
  /**
   * Adds a count unit metric to Cloudwatch
   *
   * @param {string} metricName the name of the metric
   * @param {Dimension[]} dimensions accompanying data for the metric
   * @param {number} metricCount count associated with metric
   * @memberof MetricsClient
   */
  public addCounterMetric = (
    metricName: string,
    dimensions: Dimension[] = [],
    metricCount: number = 1
  ): void => {
    const metric: MetricDatum = {
      MetricName: metricName,
      Dimensions: [...this.defaultDimension, ...dimensions],
      Timestamp: new Date(),
      Unit: "Count",
      Value: metricCount,
    };

    this.logger.verbose("CloudWatchMetric", metric);

    this.dataChunk.addData(metric);
  };
  public flush = () => {
    this.sendQueuedCommands();
  };
  protected sendQueuedCommands = async (): Promise<
    (void | MetadataBearer)[] | undefined
  > => {
    const { data, length } = this.dataChunk.getDataChunks();

    const putMetricPromises: Promise<void | MetadataBearer>[] = [];

    for (const chunk of data) {
      putMetricPromises.push(
        this.cloudWatch.executePutMetricDataCommand(chunk)
      );
    }

    try {
      const putMetricResponse: (void | MetadataBearer)[] = await Promise.all(
        putMetricPromises
      );
      // if the promises resolve, remove length number of data elements
      this.dataChunk.clear(length);
      return putMetricResponse;
    } catch (e) {
      const didStopPolling = this.trackError();

      if (didStopPolling) {
        return;
      }

      // if we get an unauthorized error, do not re-attempt the API call
      if (e.$metadata?.httpStatusCode === 403) {
        this.pollEngine.stopPolling();
        return;
      }

      this.logger.error(
        "Unable to send CloudWatchMetrics. Will try again later.",
        e
      );
    }
  };

  public static __removeInstance__() {
    if (process.env.NODE_ENV !== "test") {
      return;
    }
    MetricsClient.instance = undefined;
  }
}

export default MetricsClient;
