/**
 * Checks if provided URL is external
 * @param {string} url
 * @returns {boolean}
 */
const isURLExternal = (url: string) => {
  if (url.indexOf("://") > 0 || url.indexOf("//") === 0) {
    return true;
  }

  try {
    return (
      new URL(document.baseURI).origin !== new URL(url, document.baseURI).origin
    );
  } catch {
    return false;
  }
};

export default isURLExternal;
