import { BaseSettings } from "../types";
import getRedirectURL from "./get-redirect-url";

const processSettings = <S extends BaseSettings = BaseSettings>(
  settings: S
) => {
  if (!settings.amplify.oauth) {
    return settings;
  }

  // Configure redirect URLs to current origin
  const redirectSignInPath = settings.amplify.oauth.redirectSignInPath || "";
  const redirectSignOutPath = settings.amplify.oauth.redirectSignOutPath || "";

  settings.amplify.oauth.redirectSignIn = getRedirectURL(redirectSignInPath);
  settings.amplify.oauth.redirectSignOut = getRedirectURL(redirectSignOutPath);

  delete settings.amplify.oauth.redirectSignInPath;
  delete settings.amplify.oauth.redirectSignOutPath;

  return settings;
};

export default processSettings;
