import {
  AbstractDatabase,
  DatabaseExternalInterface,
} from "../Database.interface";

/**
 * Generic in-memory database that contains basic CRUD
 * operations.
 *
 * import Database from "lib/InMemoryDatabase";
 * const db: InMemoryDatabase<number> = new InMemoryDatabase<number>();
 *
 * db.create('example', 100);
 * const allRecords: Record<string, number> = db.getAll();
 * const foundElement: Record<string, number> | null = db.get('example');
 * db.update('example', 101);
 * const wasDeleted: boolean = db.delete('example');
 */
class InMemoryDatabase<T> extends AbstractDatabase<T> {
  private _db: Record<string, T>;
  constructor(args?: DatabaseExternalInterface<T>) {
    super();

    this._db = args?.initialDb || {};
  }
  getAll = (): Record<string, T> => {
    return this._db;
  };
  create = (key: string, data: T): T => {
    this._db[key] = data;
    return data;
  };
  get = (key: string): T | null => {
    return this._db[key] || null;
  };
  update = (key: string, updatedValue: T): T => {
    this._db = {
      ...this._db,
      [key]: updatedValue,
    };

    return updatedValue;
  };
  delete = (key: string): boolean => {
    const deleted: boolean = delete this._db[key];
    return deleted;
  };
}

export default InMemoryDatabase;
