export interface WithRetryOptions<S> {
  context?: any;
  retries?: number;
  interval?: number;
  retryFn?: (retryCount: number) => number;
  retryCount?: number;
  shouldRetryFn?: (err: S) => boolean;
}

/**
 * Customizable utility function that will retry a promise if it fails.
 *
 * T generic parameter is the (optional) successful result of the request
 * S generic parameter is the (optional) error rejected from the given promise
 *
 * @param {Promise} promiseFn the promise that will be invoked and re-tried
 * @param {Object} options a series of optional options for retry logic
 * @param {Any} [options.context=null] the context used to execute the promise
 * @param {Number} [options.retries=3] the number of times the promise should retry
 * @param {Number} [options.interval=1000] the interval at which the promise should execute
 * @param {Function} [options.retryFn=undefined] a function that determines how long each retry should take
 * @param {Function} [options.shouldRetryFn=undefined] a function that determines if the API should be retried
 * @returns {Promise} the result of the given promise after n number of retries if failed
 */
const withRetry = <T = any, S = any>(
  promiseFn: () => Promise<T>,
  {
    context = null,
    retries = 3,
    interval = 1000,
    retryFn,
    retryCount = 1,
    shouldRetryFn,
  }: WithRetryOptions<S> = {}
): Promise<T> => {
  return new Promise((resolve, reject) => {
    promiseFn
      .call(context)
      .then(resolve)
      .catch((err) => {
        // before performing the retry logic, determine whether or not we should retry the
        // given function
        const shouldRetry = shouldRetryFn ? shouldRetryFn(err) : true;

        if (!shouldRetry) {
          return reject(err);
        }

        // the consumer will be able to define how often the retry logic
        // should occur. For example, the consumer may want to use exponential
        // back-off retry logic instead of retrying every 1 second.
        const timerInterval: number = retryFn ? retryFn(retryCount) : interval;

        setTimeout(() => {
          if (retries === 1) {
            return reject(err);
          }
          withRetry(promiseFn, {
            context,
            retryCount: retryCount + 1,
            retries: retries - 1,
            retryFn,
            interval: timerInterval,
          }).then(resolve, reject);
        }, timerInterval);
      });
  });
};

export default withRetry;
