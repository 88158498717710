/**
 * Wrapper utility for HTMLElement.scrollIntoView
 * @param {string} selector
 */
export const scrollIntoView = (
  selector: string,
  scrollBehavior: ScrollBehavior = "smooth"
) => {
  try {
    const id = selector.replace(/^#/, "");
    // Can't use querySelector here since selectors that start with numbers don't work
    document.getElementById(id)?.scrollIntoView({
      behavior: scrollBehavior,
    });
  } catch (e) {
    // Ok to swallow error here
  }
};

/**
 * Set URL hash of specified ID and scroll element into view
 * @param {string} selector ID selector of element
 * @param {boolean} scrollElementIntoView Determines if element should be scrolled into view
 */
const setPageHash = (selector: string, scrollElementIntoView = true) => {
  if (!selector.startsWith("#")) {
    return;
  }
  window.history.replaceState(null, "", selector);
  scrollElementIntoView && scrollIntoView(selector);
};

export default setPageHash;
