export enum CliEnv {
  Bash = "bash",
  Fish = "fish",
  WindowsPowerShell = "windowsPowerShell",
  WindowsCmd = "windowsCmd",
}

export interface I18nStrings {
  cliNames: Record<CliEnv, string>;
}

export interface CliConfig {
  type: CliEnv;
  name: string;
  commands: string[];
}

export interface AwsCliCredentials {
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken: string;
}
