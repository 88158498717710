import PerformanceTimer from "lib/PerformanceTimer";
import { useRef } from "react";

interface UsePerformanceTimerOutput {
  startTimer: PerformanceTimer["startTimer"];
  endTimer: PerformanceTimer["endTimer"];
}

/**
 * usePerformanceTimer outputs 2 methods that can be used to calculate the total
 * amount of time a particular event occurred.
 */
const usePerformanceTimer = (): UsePerformanceTimerOutput => {
  const performanceTimer = useRef(new PerformanceTimer());

  return {
    startTimer: performanceTimer.current.startTimer,
    endTimer: performanceTimer.current.endTimer,
  };
};

export default usePerformanceTimer;
