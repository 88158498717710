import ApiClient from "lib/ApiClient";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import useAxiosClient from "./../use-axios-client";

interface UseContentDirectiveHookProps {
  baseUrl: string;
  ignoreRequest?: (location: string) => boolean;
  transformRequest?: (location: string) => string;
  prefetchRequest?: () => string;
  cache?: boolean;
  locale?: string;
}

/**
 * useContentDirectiveHook is a hook that will be used alongside with the DirectiveRenderer to render
 * new content on navigation changes.
 *
 * @param {string} baseUrl BaseURL set in the axios client for all requests
 * @param {Function} [ignoreRequest] Optional function that can be used to transform request URL before it gets called
 * @param {Function} [transformRequest] Optional function that can be used to transform request URL before it gets called
 * @param {Function} [prefetchRequest] Optional function that can be used to prefetch content
 * @param {Boolean} [cache=false] If true, will use axios client interceptors to cache data on new route
 * @returns {UseLazyRequestResponse<T>} response from the invocation of an API
 */
const useContentDirectiveHook = <S>({
  baseUrl,
  transformRequest,
  prefetchRequest,
  ignoreRequest,
  cache = false,
  locale = "",
}: UseContentDirectiveHookProps) => {
  const eventClient = useRef(
    ApiClient.createGenericClient({
      clientName: "EventClient",
      baseUrl,
      cache,
    })
  );
  const location = useLocation();

  const {
    get: { request, reset, response },
  } = useAxiosClient<S>(eventClient.current);

  const {
    get: { request: prefetchRequestCall },
  } = useAxiosClient<S>(eventClient.current);

  useEffect(() => {
    const performRequest = async () => {
      reset();
      const urlPath = transformRequest
        ? transformRequest(location.pathname)
        : location.pathname;
      await request(urlPath);
    };

    const performPrefetchRequest = async () => {
      const prefetchedRequestUrl: string | undefined = prefetchRequest?.();
      if (prefetchedRequestUrl) {
        await prefetchRequestCall(prefetchedRequestUrl);
      }
    };

    if (ignoreRequest?.(location.pathname)) {
      return;
    }

    performRequest();
    performPrefetchRequest();
  }, [location, locale]);

  return response;
};

export default useContentDirectiveHook;
