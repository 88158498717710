import FormField, {
  FormFieldProps,
} from "@amzn/awsui-components-react/polaris/form-field";
import { useField, useFormikContext } from "formik";
import React from "react";

export interface IFormikFieldProps<S = any> {
  name: keyof S;
  formFieldProps: FormFieldProps;
  dataTestId?: string;
}

const FormikField = <S,>({
  name,
  formFieldProps,
  dataTestId,
}: IFormikFieldProps<S>) => {
  const [, meta] = useField<string>(name as string);
  const { submitCount } = useFormikContext();
  const doesContainErrorText: boolean =
    (meta.touched || submitCount >= 0) && typeof meta.error === "string";

  return (
    <FormField
      {...(dataTestId ? { ["data-testid"]: dataTestId } : {})}
      {...(doesContainErrorText ? { ["data-testid"]: `error-${name}` } : {})}
      errorText={doesContainErrorText ? meta.error : undefined}
      {...formFieldProps}
    />
  );
};

export default FormikField;
