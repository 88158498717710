import Alert from "@amzn/awsui-components-react/polaris/alert";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { useMetric, useModal } from "hooks";
import React, { FC, useCallback } from "react";
import { Constants } from "utils";

const COPY_TEXT = {
  modalTitle: "Report a bug",
  modalAlert:
    "This is for Workshop Studio bugs only. For content related issues, report the issue directly from the workshop detail view.",
  modalBodyMainDescription:
    'AWS Workshop Studio does not maintain the workshops that are published, as these are maintained by the workshop owner and the associated contributors. If you are attempting to report an issue with this workshop, please use the "Report a content issue" button on the workshop detail view. You can find the workshop detail view by finding the workshop in the Workshop Catalog and clicking "View Details."',
  modalBodyMainSubtext:
    'If you are encountering an issue with Workshop Studio, please click "Report bug" below to report the issue and our team will investigate.',
  primaryActionText: "Report bug",
  secondaryActionText: "Cancel",
};

export const FORM_ID = {
  WORKSHOP_PAGE: "workshopPage",
  ISSUE: "issue",
  PROPOSED_SOLUTION: "proposedSolution",
  OPEN_IN_SIM_BUTTON: "openInSimButton",
};

export interface ReportBugModalProps {
  isVisible: boolean;
  onModalClosed: () => void;
  onComplete?: () => void;
}

const ReportBugModal: FC<ReportBugModalProps> = ({
  isVisible,
  onModalClosed,
  onComplete,
}: ReportBugModalProps) => {
  const { addCounterMetric } = useMetric();

  const closeModal = () => {
    hideModal();
    onModalClosed();
  };

  const onPrimaryActionClick = useCallback(() => {
    window.open(
      Constants.ExternalLinks.reportBug,
      "_blank",
      "noopener,noreferrer"
    );
    addCounterMetric(Constants.CloudWatchMetric.REPORT_WORKSHOP_STUDIO_BUG);
    onComplete?.();
  }, []);

  const onSecondaryActionClick = useCallback(() => {
    closeModal();
  }, []);

  const { modalComponent, hideModal } = useModal({
    isVisible,
    onDismiss: onModalClosed,
    modalHeader: COPY_TEXT.modalTitle,
    actions: {
      primary: {
        text: COPY_TEXT.primaryActionText,
        onClick: onPrimaryActionClick,
        iconAlign: "right",
        iconName: "external",
      },
      tertiary: {
        text: COPY_TEXT.secondaryActionText,
        onClick: onSecondaryActionClick,
      },
    },
    content: (
      <section>
        <SpaceBetween size="l">
          <Alert type="warning">{COPY_TEXT.modalAlert}</Alert>
          <Box variant="p">{COPY_TEXT.modalBodyMainDescription}</Box>
          <Box variant="p">{COPY_TEXT.modalBodyMainSubtext}</Box>
        </SpaceBetween>
      </section>
    ),
  });

  return modalComponent;
};

export default ReportBugModal;
