import React, { createContext, PropsWithChildren, useState } from "react";

interface TabsGroupActiveTabsState {
  [key: string]: string;
}

export interface TabsContextProps {
  getTabsGroupActiveTab: (groupId: string) => string | undefined;
  setTabsGroupActiveTab: (groupId: string, activeTabId: string) => void;
  tabsGroupActiveTabs: TabsGroupActiveTabsState;
}

export const TabsContext = createContext<TabsContextProps>(
  {} as TabsContextProps
);

const TabsProvider = (props: PropsWithChildren<React.ReactNode>) => {
  const [
    tabsGroupActiveTabs,
    setTabsGroupActiveTabs,
  ] = useState<TabsGroupActiveTabsState>({});
  const getTabsGroupActiveTab = (groupId: string) =>
    tabsGroupActiveTabs[groupId];
  const setTabsGroupActiveTab = (groupId: string, activeTabId: string) =>
    setTabsGroupActiveTabs({
      ...tabsGroupActiveTabs,
      [groupId]: activeTabId,
    });

  return (
    <TabsContext.Provider
      value={{
        getTabsGroupActiveTab,
        setTabsGroupActiveTab,
        tabsGroupActiveTabs,
      }}
    >
      {props.children}
    </TabsContext.Provider>
  );
};

export default TabsProvider;
