import React, { createContext } from "react";

import { MarkdownRendererProps } from "../types";

export interface MarkdownRendererContextValue {
  sourceContent: string;
  config: MarkdownRendererProps["config"];
}

export const MarkdownRendererContext = createContext(
  {} as MarkdownRendererContextValue
);
