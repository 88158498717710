import Paginator, {
  ClientArguments,
  PaginationClient,
} from "lib/Paginator/Paginator";
import { useRef } from "react";

/**
 * usePaginator is a re-usable hook that allows us to paginate data either
 * through the use of pages, or through start and end cursors.
 *
 * S denotes the request parameters
 * T denotes the server response from the request

 * @param {request} request - function that performs the request (i.e. EEContentCatalog.getContent)
 * @param {number} limit - the number of pieces of data to request at a time
 * @param {keyof S} dataKey - the server response key of the data
 *
 * @returns {PaginationMethods<S, T>} methods - Methods to execute from the Paginator
 * @param {() => Promise<T>} methods.getPage - the page to retrieve from the server based off of the limit
 * @param {() => Promise<T>} methods.getPaginatedData - cursor based pagination with start and end cursor
 * @param {Function} methods.updateLimit - function used to update the limit of data points to retrieve
 */
const usePaginator = <S extends ClientArguments, T>(
  request: PaginationClient<S, T>["request"],
  limit: number,
  dataKey: keyof T
) => {
  const paginator = useRef(
    new Paginator<S, T>(
      {
        request,
      },
      limit || 10,
      dataKey
    )
  );

  return {
    getPage: paginator.current.getPage,
    getPaginatedData: paginator.current.getPaginatedData,
    updateLimit: paginator.current.updateLimit,
  };
};

export default usePaginator;
