import { AWSError } from "aws-sdk/lib/error";

import { ErrorCode, ErrorName } from "../types";

interface CreateAWSErrorOptions {
  name: ErrorName;
  code: ErrorCode;
  message: string;
}

/**
 * Creates AWSError object
 *
 * @param {CreateAWSErrorOptions} options
 * @returns {AWSError}
 */
const createAWSError = ({
  name,
  code,
  message,
}: CreateAWSErrorOptions): AWSError => ({
  name,
  code,
  message,
  time: new Date(),
});

export default createAWSError;
